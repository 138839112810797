import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { Button, Image, Link, Section, Specifications } from '@boss/ui';

import { LoginSectionProps } from '..';
import { useLogin } from '../../../hooks';

const LoginSectionSecondary = ({ bannerImage }: LoginSectionProps) => {
  const { t } = useTranslation('login');
  const { handleLogin, handleRegister } = useLogin();
  const { asPath } = useRouter();

  return (
    <Section
      className="flex flex-col gap-4 lg:gap-6"
      content={
        <>
          <span className="h2 lg:max-w-1/2">{t('title')}</span>
          <div className="grid gap-6 lg:grid-cols-3">
            <div className="relative h-fit lg:col-span-2 lg:min-h-80">
              {bannerImage?.fields?.file?.url && (
                <Image alt="login-banner-BOSS-paints" className="object-left" fill src={bannerImage.fields.file.url} />
              )}
            </div>

            <div className="bg-blue-night flex flex-col justify-center gap-3 px-6 py-8 lg:gap-6 lg:p-8">
              <Specifications
                border={false}
                className="gap-4"
                iconClassName="text-white pt-0"
                items={[1, 2, 3, 4].map(item => (
                  <span className="text-white" key={`client-benefit-${item}`}>
                    {t(`newClientCard.benefits.${item}`)}
                  </span>
                ))}
                liClassName="gap-3.5"
              />
              <div className="flex flex-col gap-2">
                <Button
                  className="text-blue-dark h-12 w-full justify-center bg-white text-center [&>*]:w-auto"
                  icon={faArrowRight}
                  iconPosition="right"
                  label={t('newClientCard.continueButtonLabel')}
                  onClick={() => handleRegister(asPath)}
                  type="primary"
                />
                <Link
                  anchorClassName="self-center underline"
                  className="mt-2 w-fit text-white lg:mt-0"
                  onClick={() => handleLogin(asPath)}
                >
                  {t('newClientCard.footerLink')}
                </Link>
              </div>
            </div>
          </div>
        </>
      }
    />
  );
};

export default LoginSectionSecondary;
