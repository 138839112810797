import { ParsedUrlQuery } from 'querystring';

export const getQueryKeyValue = (queryObject: ParsedUrlQuery, key: string) => {
  const queryValue = queryObject[key];

  // FIXME: Right operand of ?? is unreachable because the left operand is never nullish.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore-next-line Right operand of ?? is unreachable because the left operand is never nullish.
  return Array.isArray(queryValue) ? `${key}=${queryValue[0]}` : `${key}=${queryValue}` ?? '';
};
