import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Marker, OverlayView } from '@react-google-maps/api';

import { IStore } from '@boss/services';
import { getPixelPositionOffset, slugify } from '@boss/utils';

import StoreCard, { StoreCardTranslations } from '../../StoreCard';

type Props = {
  store: IStore;
  position: google.maps.LatLngLiteral;
  activeMarkerId: string | undefined;
  onSetActiveMarker: () => void;
  onClose: () => void;
  storeBasePath: string;
  storeCardVariant: 'primary' | 'secondary';
  translations: StoreCardTranslations;
};

const StoreMarker = ({
  store,
  position,
  activeMarkerId,
  onSetActiveMarker: setActiveMarker,
  onClose: handleClose,
  storeBasePath,
  storeCardVariant,
  translations,
}: Props) => {
  return (
    <OverlayView
      getPixelPositionOffset={(width, height) =>
        getPixelPositionOffset({
          width,
          height,
          offsetY: 110,
        })
      }
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}
    >
      <div>
        <Marker
          icon={{
            path: faLocationDot.icon[4] as string,
            fillColor: '#000',
            fillOpacity: 1,
            strokeWeight: 1,
            scale: 0.07,
            anchor: new google.maps.Point(200, 550),
          }}
          onClick={setActiveMarker}
          position={position}
        />

        {activeMarkerId && activeMarkerId === store?.id && (
          <div className="text-md relative z-40 max-w-[40em]">
            <div className="z-50 -mb-2 ml-2 text-center">
              <div className="z-50 inline-block w-16  overflow-hidden">
                <div className=" h-11 w-11 origin-bottom-left rotate-45 transform bg-white" />
              </div>
            </div>
            <button className="absolute right-0 top-10 z-40 py-4 pr-6" onClick={handleClose}>
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </button>
            <StoreCard
              href={`${storeBasePath}/${slugify(store.name)}`}
              message={store.customMessage}
              store={store}
              translations={translations}
              variant={storeCardVariant}
            />
          </div>
        )}
      </div>
    </OverlayView>
  );
};

export default StoreMarker;
