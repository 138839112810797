import { faChevronRight, faCircleInfo, faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';

import { IStore } from '@boss/services';
import type { Nullable } from '@boss/types/b2b-b2c';
import { formatDistance, getCurrentOpeningHours, shopIsOpen, toHoursAndMinutes } from '@boss/utils';

import ContactDetailLinks from '../ContactDetailLinks/contact-detail-links';
import Link from '../Link';
import { StatusLabel } from '../StatusLabel';

export type StoreCardTranslations = {
  closedLabel: string;
  moreInfoLabel: string;
  openingHoursLabel: string;
  openLabel: string;
  routeDescriptionLabel: string;
};

interface Props {
  href?: string;
  store: IStore;
  translations: StoreCardTranslations;
  message?: Nullable<string>;
  hideOpeningHours?: boolean;
  variant: 'primary' | 'secondary';
}

const cardLayoutStyle = cva('shadow-m text-gray-dark w-full bg-white', {
  variants: {
    variant: {
      primary: 'rounded-md md:rounded-lg',
      secondary: '',
    },
  },
});

const messageLayoutStyle = cva(
  'min-h-12 bottom-0 bg-[#FFB802] small md:body w-full py-3 px-5 md:px-10 text-brown-dark',
  {
    variants: {
      variant: {
        primary: 'rounded-b-md md:rounded-b-lg',
        secondary: '',
      },
    },
  },
);

const addressLayout = cva('mt-1 mb-2 md:mt-3 md:mb-5 text-gray-dark/80', {
  variants: {
    variant: {
      primary: '',
      secondary: 'font-bold text-generic-brown',
    },
  },
});

const LinkComp = ({ href, translations }: { href: string; translations?: StoreCardTranslations }) => (
  <Link
    aria-label="storeCard-more-info-link"
    className="bg-gray-light hover:bg-gray-light/[0.6] rounded-lg px-4 py-2"
    href={href ?? ''}
  >
    {translations?.moreInfoLabel ?? 'Meer info'}
    <FontAwesomeIcon className="ml-3" icon={faChevronRight} size="sm" />
  </Link>
);

const OpeningHours = ({ store }: { store: IStore }): ReactElement => {
  const currentOpeninghours = getCurrentOpeningHours(store);

  return (
    <div className="flex flex-wrap gap-3">
      {currentOpeninghours?.map(({ from, till }, index) => (
        <span className="small" key={`${from}-${till}-${index}`}>
          {toHoursAndMinutes(from)}-{toHoursAndMinutes(till)}
        </span>
      ))}
    </div>
  );
};

const StoreCard = ({ href, store, message, translations, variant, hideOpeningHours }: Props) => {
  const { street, streetnumber, zipcode, city } = store.address ?? {};

  return (
    <div className={twMerge(cardLayoutStyle({ variant }))}>
      <div className="relative grid grid-cols-1 px-5 py-5 md:grid-cols-7 md:px-10 md:py-10">
        <div className="col-span-4 md:pr-4">
          <h4 className="text-generic-brown">{store.name}</h4>
          {!hideOpeningHours && (
            <div className="mt-2 md:hidden">
              <StatusLabel isOpen={shopIsOpen(store)} translations={translations} />
              {shopIsOpen(store) && (
                <span className="pl-3">
                  <OpeningHours store={store} />
                </span>
              )}
            </div>
          )}
          <div>
            <div className={twMerge(addressLayout({ variant }))}>
              <div>
                {street} {streetnumber}
              </div>
              <div>
                {zipcode} {city}
              </div>
            </div>
          </div>
          {href && (
            <span className="hidden md:block">
              <LinkComp href={href} translations={translations} />
            </span>
          )}
        </div>
        <div className={`text-gray-dark/80 col-span-3 md:pt-4 ${message ? 'mb-1 md:pb-0' : ''}`}>
          <ContactDetailLinks phone={store.phone} />
          <a
            className="text-gray-dark/70 small mt-2 block underline"
            href={`https://maps.google.com/maps/dir//${store.name}+${street}+${streetnumber}+${zipcode}+${city}`}
            rel="noreferrer"
            target="_blank"
          >
            {translations?.routeDescriptionLabel ?? 'Routebeschrijving'}
          </a>
          {href && (
            <span className="mt-3 block text-right md:hidden">
              <LinkComp href={href} translations={translations} />
            </span>
          )}
          {!!store.distance && (
            <div className="mt-3 hidden md:block">
              <FontAwesomeIcon className="mr-2" icon={faLocationDot} size="sm" />
              {formatDistance(store.distance)}
            </div>
          )}
          {!hideOpeningHours && (
            <>
              <div className="mt-2 hidden md:block">
                <StatusLabel isOpen={shopIsOpen(store)} translations={translations} />
              </div>
              <div className="mt-1 hidden md:block">
                <OpeningHours store={store} />
              </div>
            </>
          )}
        </div>
      </div>

      {message && (
        <div className={twMerge(messageLayoutStyle({ variant }))}>
          <FontAwesomeIcon className="mr-2" icon={faCircleInfo} size="sm" />
          {message}
        </div>
      )}
    </div>
  );
};

export default StoreCard;
