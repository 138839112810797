import { Inline } from '@contentful/rich-text-types';
import React from 'react';

import { CommonProps } from '@boss/types/b2b-b2c';
import { TextLink } from '@boss/ui';

type propsType = CommonProps & {
  hyperlink: Inline;
};

const EntryHyperlink = ({ hyperlink, className }: propsType) => {
  let value;

  if ('value' in hyperlink.content[0]) {
    value = hyperlink.content[0].value;
  }
  const { data } = hyperlink;
  const slugField = data.target.slug;

  if (!slugField || !value) {
    return null;
  }

  return (
    <TextLink className={className} href={slugField}>
      {value}
    </TextLink>
  );
};

export default EntryHyperlink;
