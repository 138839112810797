import * as bossClient from '@boss/boss-client';

/**
 * Formats URL to ensure proper https:// prefix
 */
const formatUrl = (url: string): string => {
  return url?.replace(/^\/\//, '').replace(/^(?!https?:\/\/)/, 'https://');
};

/**
 * Sends form data
 */
export const send = async (formData: Record<string, string>, locale: string) => {
  if (formData.url) {
    formData.url = formatUrl(formData.url);
  }

  return await bossClient.form.submit(formData, locale);
};

export const sendClient = async (formData: Record<string, string>, locale: string) => {
  if (formData.url) {
    formData.url = formatUrl(formData.url);
  }

  return await bossClient.submitForm.submitForm(formData, locale);
};
