import { ColorGroup, SimpleColor } from '@boss/types/b2b-b2c';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import DesktopColorPickerModal from './Desktop';
import MobileColorPickerModal from './Mobile';

type Translations = {
  title: string;
  searchLabel: string;
  subtitle: string;
  showMore: string;
  trending: string;
  showLabel: string;
  hideLabel: string;
  results: string;
  showLess: string;
  noResults: string;
  recentColors: string;
  premixedColors: string;
  byColorGroup: string;
  premixedColorsSubtitle?: string;
  favorites: string;
};

type CallToAction = {
  label: string;
  onClick: () => void;
  highlight?: boolean;
};

type Props = {
  activeColorGroup?: ColorGroup;
  activeColors?: SimpleColor[];
  premixedColors?: SimpleColor[];
  recentViewedColors?: SimpleColor[];
  callToActions?: CallToAction[];
  className?: string;
  colorGroups?: ColorGroup[];
  colorsLoading?: boolean;
  hasSearched: boolean;
  onChangeColorClick: (color: SimpleColor, colorBase?: string) => void;
  onClose: () => void;
  onColorGroupClick: (colorGroup: ColorGroup) => void;
  onFavorite?: (color: SimpleColor) => void;
  onSearch: () => void;
  searchLoading?: boolean;
  searchResults?: SimpleColor[];
  searchValue?: string;
  selectedColor?: SimpleColor;
  setSearchValue: (val: string) => void;
  showMoreAmount?: number;
  translations: Translations;
  favoriteColorIds?: string[];
  colorGroupsLoading?: boolean;
  disclaimer?: string;
  colorType?: 'default' | 'transparant';
  defaultColorBase?: string;
  premixedColorBase?: string;
  hideRecentColors?: boolean;
  hideFavoriteColors?: boolean;
  hideColorGroups?: boolean;
  variant?: 'primary' | 'secondary';
  favoriteColors?: SimpleColor[];
};

export type ColorPickerModalProps = Omit<Props, 'showMoreAmount'> & {
  handleShowMore: () => void;
  handleShowMoreSearch: () => void;
  handleShowMorePremixed: () => void;
  hasShowMore: boolean;
  hasShowMoreSearch: boolean;
  hasShowMorePremixed: boolean;
  favoriteColorIds?: string[];
  favoriteColors?: SimpleColor[];
};

const useShowMore = <T,>(initialItems: T[] = [], showMoreAmount: number) => {
  const [items, setItems] = useState(initialItems.slice(0, showMoreAmount));

  const hasShowMore = (initialItems?.length ?? 0) > items.length;

  const handleShowMore = () =>
    setItems(current => [...current, ...initialItems.slice(current.length, current.length + showMoreAmount)]);

  useEffect(() => {
    setItems(initialItems.slice(0, showMoreAmount));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialItems.length, showMoreAmount]);

  return { items, hasShowMore, handleShowMore };
};

const ColorPickerModal = ({
  showMoreAmount = 15,
  activeColors: initialColors = [],
  searchResults: initialSearchResults = [],
  premixedColors: initialPremixedColors = [],
  ...props
}: Props) => {
  const { items: activeColors, hasShowMore, handleShowMore } = useShowMore(initialColors, showMoreAmount);

  const {
    items: searchResults,
    hasShowMore: hasShowMoreSearch,
    handleShowMore: handleShowMoreSearch,
  } = useShowMore(initialSearchResults, showMoreAmount);

  const {
    items: premixedColors,
    hasShowMore: hasShowMorePremixed,
    handleShowMore: handleShowMorePremixed,
  } = useShowMore(initialPremixedColors, showMoreAmount);

  const baseProps = {
    activeColors,
    premixedColors,
    handleShowMore,
    handleShowMoreSearch,
    handleShowMorePremixed,
    hasShowMore,
    hasShowMoreSearch,
    hasShowMorePremixed,
    searchResults,
  };

  return (
    <>
      <MobileColorPickerModal {...props} {...baseProps} className={twMerge('md:hidden', props.className)} />
      <DesktopColorPickerModal {...props} {...baseProps} className={twMerge('hidden md:flex', props.className)} />
    </>
  );
};

export default ColorPickerModal;
