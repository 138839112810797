import { OverlayView } from '@react-google-maps/api';

import { getPixelPositionOffset } from '@boss/utils';

type Props = {
  position: google.maps.LatLngLiteral;
  count: number;
  onClick: () => void;
};

const ClusterMarker = ({ position, count, onClick: handleClick }: Props) => {
  return (
    <OverlayView
      getPixelPositionOffset={(width, height) =>
        getPixelPositionOffset({
          width,
          height,
        })
      }
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={position}
    >
      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-white/80" onClick={handleClick}>
        <span className="text-lg font-bold">{count}</span>
      </div>
    </OverlayView>
  );
};

export default ClusterMarker;
