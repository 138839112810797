import { cva } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { ReactNode } from 'react';

import { useRouter } from '@boss/hooks';
import { RichText } from '@boss/rich-text';
import { IEvent, IStore } from '@boss/services';
import { ContactCard, ImageTextSection, Section } from '@boss/ui';
import { formatLocalDate, formatPrice, getTimeFromDatestring } from '@boss/utils';

import { EducationForm, EventCardWrapper, WorkshopForm } from '../../components';
import FormWrapper from '../../components/Form/FormWrapper';
import { eventConfig, isB2b } from '../../utils';

const FormStyles = cva('', {
  variants: {
    variant: {
      primary: 'bg-beige-light',
      secondary: 'bg-blue-light',
    },
  },
});

const ImageSectionStyles = cva('break-before break-after relative', {
  variants: {
    variant: {
      primary: 'bg-gray-dark before:bg-gray-dark after:bg-gray-dark',
      secondary: 'bg-gray-100 before:bg-gray-100 after:bg-gray-100',
    },
  },
});

const ImageSectionTextStyles = cva('col-span-1 flex items-center', {
  variants: {
    variant: {
      primary: 'text-white',
      secondary: '',
    },
  },
});

const WorkshopPage = ({ event, store }: { event: IEvent; store?: IStore | null }) => {
  const { locale } = useRouter();

  const formTypeMap: Record<(typeof eventConfig)['formType'], ReactNode> = {
    workshop: (
      <FormWrapper behavior={isB2b ? 'showNothingWhenLoggedOut' : 'showAlways'}>
        <WorkshopForm
          eventId={event.id}
          locationId={store?.id ?? event.locationId}
          skuId={event.skuId}
          storeServiceId={store?.paymentproviderserviceid ?? ''}
        />
      </FormWrapper>
    ),
    education: (
      <FormWrapper behavior={'showAlways'}>
        <EducationForm eventId={event.id} />
      </FormWrapper>
    ),
  };

  const { t } = useTranslation(['workshop', 'event']);

  const { variant, formType } = eventConfig;

  const renderEventStatus = () => {
    if (event.complete) {
      return <p className="text-red text-right font-bold">{t('full', { ns: 'workshop' })}</p>;
    } else {
      return <p className="text-green text-right font-bold">{t('freePlaces', { ns: 'workshop' })}!</p>;
    }
  };

  return (
    <>
      <div className="lg-grid-cols-2 lg:mb-30 mb-10 grid grid-cols-1 gap-4 md:grid-cols-2">
        <h1 className="col-span-1 md:col-span-2">{event.title}</h1>
        <div className="col-span-1 grid gap-4 text-gray-500 md:grid-cols-3">
          <p className="font-bold capitalize">{formatLocalDate(event.date, locale)}</p>
          <p className="font-bold">{`${getTimeFromDatestring(event.startTime)} -  ${getTimeFromDatestring(
            event.endTime,
          )}`}</p>
          {!isB2b && (
            <p className="font-bold">
              {t('price', { ns: 'workshop' })}: {formatPrice(event.price, locale)}
            </p>
          )}
        </div>
        <div className="col-span-1">{renderEventStatus()}</div>
      </div>
      <ImageTextSection
        className={ImageSectionStyles({ variant })}
        image={event.image ?? null}
        imageClassName="aspect-[16/9]"
        imageFirst={true}
        text={
          <div className="grid gap-4 md:grid-cols-2">
            <div className={ImageSectionTextStyles({ variant })}>
              <div className="flex content-center ">
                {event.description && event.description !== null ? (
                  <RichText content={event.description} mapperOptions={{ locale }} />
                ) : null}
              </div>
            </div>
            {store && (
              <div className="col-span-1">
                <ContactCard
                  address={{
                    street: store?.address?.street ?? '',
                    streetnumber: Number(store?.address?.streetnumber ?? 0),
                    zipcode: store?.address?.zipcode ?? '',
                    city: store?.address?.city ?? '',
                    country: store?.address?.country ?? '',
                  }}
                  className="rounded-modal bg-white"
                  email={store?.email}
                  firstname={store?.name}
                  hideButtons={true}
                  phone={store?.phone}
                  translations={{ call: 'bel me', mail: 'schrijf me' }}
                />
              </div>
            )}
          </div>
        }
        variant={variant}
      />
      {!event.complete && (
        <Section
          backgroundColor={FormStyles({ variant })}
          className="md:mt-30 md:py-15 mt-10"
          content={
            <div className="flex flex-col gap-5">
              <span className="h2">{t('form.title')}</span>
              {formTypeMap[formType]}
            </div>
          }
        />
      )}
      <Section
        content={
          <div className="mt-0 md:mt-20">
            <h2>{t('related.title')}</h2>
            <div className="mt-10 grid gap-4 md:mt-20 md:grid-cols-3">
              {event.relatedEvents?.map(related => {
                return <EventCardWrapper event={related} key={related.id} locale={locale} />;
              })}
            </div>
          </div>
        }
      />
    </>
  );
};

export default WorkshopPage;
