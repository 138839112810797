import React from 'react';

import { CommonProps } from '@boss/types/b2b-b2c';

import Link from '../../Link';

type propsType = CommonProps & {
  link?: string | null;
  children: React.ReactNode;
};

const FooterNavItem = ({ link, className, children }: propsType): JSX.Element => {
  if (!link) {
    return <span className={className}>{children}</span>;
  }

  return (
    <Link className={className} href={link}>
      {children}
    </Link>
  );
};

export default FooterNavItem;
