type FetchResponse = globalThis.Response;

/**
 * This function extracts an error message from a Response object.
 * If the response contains a body, it tries to parse it as text.
 * If there's no body or parsing as text fails, it falls back to the statusText.
 * If all else fails, it returns a default error message.
 *
 * @param {Response} response - The Response object from which to extract the error message.
 * @returns {Promise<string>} A promise that resolves to the error message.
 */
async function getErrorMessageFromResponse(response: FetchResponse) {
  // Define a default error message.
  const DEFAULT_MSG = 'An error occurred while fetching the data.';

  try {
    // Try to get the error message from the response body as text.
    const errorMessage = (await response.text()) || response.statusText;

    if (errorMessage) {
      return errorMessage;
    } else {
      // If there's no error message in the response body or statusText, return the default message.
      return DEFAULT_MSG;
    }
  } catch {
    // If an error occurs while trying to fetch the error message, return the default message.
    return DEFAULT_MSG;
  }
}

export default getErrorMessageFromResponse;
