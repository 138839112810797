import { ReactNode } from 'react';

import Price from '../../Price';
import Skeleton from '../../Skeleton';

export interface Props {
  isLoading?: boolean;
  netPrice: number;
  salesPrice?: number;
  discount?: number;
  translations: {
    grossPrice: string;
    discount: string;
    netPrice: string;
  };
}

const PricingRow = ({ children }: { children: ReactNode }) => (
  <div className="flex w-full items-center justify-between gap-8 self-end">{children}</div>
);

const Loader = ({ loading, children }: { children: ReactNode; loading: boolean }) =>
  loading ? <Skeleton className="rounded-brand h-8 min-w-20" skeletonClassName="bg-gray-200" /> : <>{children}</>;

const ArticleCardPricing = ({ isLoading = false, translations, salesPrice, netPrice, discount }: Props) => (
  <div className="flex w-full flex-col gap-4 md:self-end">
    {!!discount && (
      <>
        <PricingRow>
          <span>{translations.grossPrice}</span>
          <Loader loading={isLoading}>
            <Price
              className="md:min-w-25 text-end line-through [&>*]:font-normal"
              font="sans"
              value={salesPrice}
              variant="info"
            />
          </Loader>
        </PricingRow>
        <PricingRow>
          <span className="text-red-light font-bold">{translations.discount}</span>
          <span className="text-red-light md:min-w-25 text-end font-bold">{discount}%</span>
        </PricingRow>
      </>
    )}
    <PricingRow>
      <span className="font-bold">{translations.netPrice}</span>
      <Loader loading={isLoading}>
        <Price className="md:min-w-25 text-end" font="sans" value={netPrice} variant="info" />
      </Loader>
    </PricingRow>
  </div>
);

export default ArticleCardPricing;
