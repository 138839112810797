import { faLocationDot } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import React from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonProps } from '@boss/types/b2b-b2c';
import { SimpleImage } from '@boss/types/corporate-jobs';

import Button from '../Button';
import Image from '../Image';
import Link from '../Link';

export type JobBlockProps = {
  cta: ButtonProps;
  title: string;
  location?: string;
  className?: string;
  image?: SimpleImage;
  variant: 'b2c' | 'b2b';
};

const BannerStyles = cva('grid gap-4 border-b-1', {
  variants: {
    variant: {
      b2b: 'py-4 grid-cols-5 md:grid-cols-6',
      b2c: 'grid-cols-6 py-6',
    },
  },
});

const ContentStyles = cva('flex flex-col flex-col-reverse md:flex-row md:items-center md:justify-normal', {
  variants: {
    variant: {
      b2b: 'col-span-4 justify-end',
      b2c: 'flex-row-reverse col-span-4',
    },
  },
});

const IconStyles = cva('', {
  variants: {
    variant: {
      b2b: 'text-green',
      b2c: 'text-blue',
    },
  },
});

const TitleStyles = cva('w-full md:w-[50%]', {
  variants: {
    variant: {
      b2b: 'font-bold text-lg',
      b2c: '',
    },
  },
});

const LocationStyles = cva('mb-2 md: mb-0 flex w-full md:w-[50%] items-center gap-4 grow-0 shrink-0', {
  variants: {
    variant: {
      b2b: '',
      b2c: 'font-bold text-lg',
    },
  },
});

const ImageStyles = cva('aspect-square max-w-[120px] md:aspect-[3/2]', {
  variants: {
    variant: {
      b2b: '',
      b2c: 'md:col-span-1',
    },
  },
});

const JobBlock: React.FC<JobBlockProps> = Props => {
  const { cta, variant, title, location, className } = Props;

  return (
    <div className={twMerge(BannerStyles({ variant }), className)}>
      <div className={ImageStyles({ variant })}>
        <Image alt={Props.image?.imageAlt ?? ''} className="object-contain" src={Props.image?.imageUrl} />
      </div>
      <div className={ContentStyles({ variant })}>
        <div className={LocationStyles({ variant })}>
          {location && <FontAwesomeIcon className={IconStyles({ variant })} icon={faLocationDot} width={16} />}
          {location}
        </div>
        <Link className={TitleStyles({ variant })} href={cta.href}>
          {title}
        </Link>
      </div>
      <div className="col-span-5 flex w-full items-center justify-end md:col-span-1">
        <Button {...cta} />
      </div>
    </div>
  );
};

export default JobBlock;
