import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { cva } from 'class-variance-authority';
import { ChangeEvent, useEffect, useState } from 'react';

import { useIsResponsive } from '@boss/hooks';
import type { BasicVariant, IAddressSuggestion, IAddressSuggestions, Nullable } from '@boss/types/b2b-b2c';

import Button from '../Button';
import SearchableSelect from '../SearchableSelect';

type Translations = {
  title: string;
  inputPlaceholder: string;
  actionLabel: string;
};

interface Props {
  translations: Translations;
  overviewUrl?: Nullable<string>;
  suggestions: IAddressSuggestions | undefined;
  getSuggestions: (query: string) => void;
  onSearch?: (locationId: IAddressSuggestion) => void;
  redirect?: boolean;
  variant?: BasicVariant;
}

const mapSuggestionToOptions = (suggestions: IAddressSuggestions = []) =>
  suggestions.map(suggestion => ({
    label: suggestion.description,
    value: suggestion.placeid,
  }));

/**
 * TwMerge can't handle custom classes. Although the docs say you can extend this functionality. This did not work with the rounded class group.
 * https://github.com/dcastil/tailwind-merge/issues/302
 *
 * Currently the easiest solution is writing an arbitrary value, this will always overwrite the previous declared class
 * */
const SearchStyles = cva(
  'outline-gray-dark w-full border-0 px-5 py-4 text-gray-900 placeholder:text-gray-400 focus:ring-1 sm:text-sm sm:leading-6 ',
  {
    variants: {
      variant: { primary: 'rounded-[2rem]', secondary: '' },
    },
  },
);

const StoreFinderSearch = ({
  translations,
  overviewUrl,
  suggestions,
  getSuggestions,
  onSearch,
  redirect = false,
  variant = 'primary',
}: Props) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState<IAddressSuggestion | null>(null);
  const isMobile = useIsResponsive('sm');
  const fallbackSuggestion = suggestions?.[0];

  const navigateToSearchResults = (sugg: IAddressSuggestion) => {
    window.location.href = `${overviewUrl}?q=${sugg.placeid}&d=${sugg.description}`;
  };

  const handleSearch = () => {
    const suggestion = selectedSuggestion ?? fallbackSuggestion;

    if (!suggestion) {
      return;
    }

    if (redirect) {
      return navigateToSearchResults(suggestion);
    }

    if (onSearch) {
      onSearch(suggestion);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement | HTMLAnchorElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleSuggestions = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedSuggestion(null);
    getSuggestions(e.target.value);
  };

  useEffect(() => {
    handleSearch();
    // Would prefer to not disable the exhaustive deps but the required fixes cause the search to be triggered on every possible change which is not what we want
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSuggestion]);

  return (
    <div className="relative">
      <SearchableSelect
        inputClassName={SearchStyles({ variant })}
        name="search-store"
        onChange={({ label, value }) => setSelectedSuggestion({ description: label, placeid: value })}
        onQueryChange={handleSuggestions}
        options={mapSuggestionToOptions(suggestions)}
        placeholder={translations.inputPlaceholder}
        variant="transparent"
      />
      <Button
        className="rounded-brand bg-gray-dark hover:bg-gray-storm absolute right-0 top-0 mt-0 h-full w-auto pl-7 pr-7 text-left text-white hover:text-white"
        hideLabel={isMobile}
        href={`${overviewUrl}`}
        icon={faMagnifyingGlass}
        iconPosition="right"
        label={translations.actionLabel}
        onClick={() => handleSearch()}
        onKeyDown={handleKeyDown}
        type="tertiary"
      />
    </div>
  );
};

export default StoreFinderSearch;
