import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import Orb from '../Orb';

type Props = {
  className?: string;
  active?: boolean;
  onClick: () => void;
};

const ToggleStyling = cva('flex w-10 h-5 p-0.5 rounded-lg transition-all cursor-pointer', {
  variants: {
    active: {
      true: 'bg-blue',
      false: 'bg-gray-dark',
    },
  },
});

const Toggle = ({ className, active, onClick }: Props) => (
  <div className={twMerge(ToggleStyling({ active }), className)} onClick={onClick}>
    <Orb className={twMerge('h-4 w-4 bg-white', active ? 'ml-[calc(100%-1rem)]' : '')} />
  </div>
);

export default Toggle;
