import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { NavigationItem } from '@boss/types/corporate-jobs';

import { Link } from '../../..';

type Props = {
  className?: string;
  links: NavigationItem[];
};

const activeClassName = 'border-green-sheen [&>*]:font-bold';

const SubBar = ({ links, className }: Props) => {
  const { asPath } = useRouter();

  return (
    <div
      className={twMerge(
        'h-15 bg-gray-medium absolute top-full flex w-full items-center gap-6 px-4 [&>a]:h-full',
        className,
      )}
    >
      {links.map(link => {
        return (
          <Link
            className={twMerge(
              'hover:border-green-sheen relative flex h-full items-center border-b-4 border-solid border-transparent pt-1 [&>*]:hover:font-bold',
              `/${link.href}` === asPath || link.href === asPath ? activeClassName : '',
            )}
            href={link.href}
            key={link.id || `${link.id}-${link.label}`}
          >
            <span className="absolute left-0 top-1/2 -translate-y-[50%]">{link.label}</span>
            <span className="invisible font-bold">{link.label}</span>
          </Link>
        );
      })}
    </div>
  );
};

export default SubBar;
