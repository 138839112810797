import { ReactNode } from 'react';

import Portal from '../Portal';
export interface OverlayProps {
  isVisible: boolean;
  onClose: () => void;
  children?: ReactNode | string;
}

export const Overlay = ({ isVisible, onClose, children }: OverlayProps) => {
  return (
    // FIXME
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Portal>
      <div
        aria-labelledby="slide-over-panel"
        aria-modal="true"
        className={`z-modal relative ${isVisible ? 'visible' : 'hidden'}`}
        role="dialog"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
        <div className="fixed bottom-0 top-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-5 md:pl-10">
              <div className="lg:max-w-overlay pointer-events-auto relative w-screen max-w-full">
                <div className="z-100 absolute right-0 top-0 -ml-8 flex pr-2 pt-6 sm:-ml-10 sm:pr-10">
                  <button
                    className="relative rounded-full bg-white text-black shadow-lg hover:text-black focus:outline-none focus:ring-2 focus:ring-black"
                    onClick={onClose}
                    type="button"
                  >
                    <span className="absolute -inset-2.5"></span>
                    <span className="sr-only">Close panel</span>
                    <svg
                      aria-hidden="true"
                      className="h-10 w-10"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M6 18L18 6M6 6l12 12" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </button>
                </div>
                <div className="flex h-full flex-col overflow-y-scroll bg-white px-8 py-6 shadow-xl">{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Portal>
  );
};

export default Overlay;
