import * as Sentry from '@sentry/nextjs';

import { withSession } from '@boss/auth';
import {
  IAccount,
  IAccountCreate,
  IContact,
  IInvoiceFilterOptions,
  TAccountDiscountInfoType,
  account as accountService,
} from '@boss/services/client';

/**
 * Client query to fetch an Account, needs a locale
 *
 * @async
 * @param {string} locale
 * @param {ReturnType<typeof useProfile>} The profile loaded in for the current session
 * @returns {Promise<IAccount>}
 */
export const fetchAccount = withSession(
  async (locale: string, accountId: string) => {
    if (accountId === '') {
      return null;
    }
    try {
      return await accountService.byId({
        locale,
        accountId,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch account',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  // When the session is nil, do not even bother pretending we could fetch this
  // successfully
  { alwaysRun: true },
);

/**
 * Client query to fetch appointments for an account, needs a locale
 *
 * @async
 * @param {string} locale
 * @param {ReturnType<typeof useProfile>} The profile loaded in for the current session
 * @returns {Promise<IAccount>}
 */
export const fetchAppointments = withSession(
  async (locale: string, accountId: string) => {
    try {
      return await accountService.appointments({
        locale,
        accountId,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch account',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  // When the session is nil, do not even bother pretending we could fetch this
  // successfully
  { alwaysRun: false },
);

/**
 * Client query to fetch worksites for an account, needs a locale
 *
 * @async
 * @param {string} locale
 * @param {ReturnType<typeof IWorksite[]}
 * @returns {Promise<IWorksite[]>}
 */
export const fetchWorksites = withSession(async (locale: string, accountId: string) => {
  try {
    return await accountService.worksites({
      locale,
      accountId,
    });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Fetch worksites',
      },
    });

    throw error;
  }
});

/**
 * Client query to fetch worksites for an account, needs a locale
 *
 * @async
 * @param {string} locale
 * @param {string} accountId
 */
export const fetchWorksitesDetail = withSession(async (locale: string, accountId: string) => {
  try {
    return await accountService.worksitesDetails({
      locale,
      accountId,
    });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Fetch worksites',
      },
    });

    throw error;
  }
});

/**
 *
 * Client query to fetch paintguide results for an account, needs a locale
 *
 * @async
 * @param {string} locale
 * @param {ReturnType<typeof useProfile>} The profile loaded in for the current session
 * @returns {Promise<IPaintguideResults>}
 */
export const fetchPaintguideResults = withSession(
  async (accountId: string, locale: string) => {
    try {
      return await accountService.paintguideResults({
        locale,
        accountId,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch paintguide results',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  // When the session is nil, do not even bother pretending we could fetch this
  // successfully
  { alwaysRun: false },
);

/**
 * Client mutation to update a accountt
 *
 * @async
 * @param {IAccount} accountData
 * @param {string} accountId The id of the account to update
 * @returns {Promise<IAccount>}
 */
export const updateAccount = withSession(
  async (accountId: string, account: IAccount, locale: string) => {
    try {
      return await accountService.update({
        accountId,
        account,
        locale,
      });
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Update account',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to update/save the paintguide results url
 */
export const updatePaintguideResults = withSession(
  async (body: { accountnumber: string; url: string; description: string }, locale: string) => {
    try {
      return await accountService.savePaintguideResults(body, locale);
    } catch (error) {
      console.error(error);
      Sentry.captureException(error, {
        tags: {
          type: 'Update paintguide results into account',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client query to fetch contacts for an account
 * @async
 * @param {string} accountId
 * @param {locale} locale
 * @returns {Promise<IContact[]>}
 *
 */
export const fetchContacts = withSession(
  async (accountId: string, locale: string) => {
    try {
      return await accountService.contacts({
        accountId,
        locale,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch contacts',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to update a contact
 * @async
 * @param {IContact} contact
 * @returns {Promise<IContact>}
 */
export const updateContact = withSession(
  async (contact: IContact, locale: string) => {
    try {
      return await accountService.updateContact({
        contact,
        locale,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Update contact',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client query to fetch invoices for an account
 * @async
 * @param {string} accountId
 * @param {locale} locale
 * @returns {Promise<IInvoice[]>}
 *
 */
export const fetchInvoices = withSession(
  async (accountId: string, locale: string, filterOptions: IInvoiceFilterOptions) => {
    try {
      return await accountService.invoices({
        accountId,
        locale,
        filterOptions,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch invoices',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client query to fetch orders for an account
 * @async
 * @param {string} accountId
 * @param {locale} locale
 * @returns {Promise<IAccountOrder[]>}
 *
 */
export const fetchOrders = withSession(
  async (
    accountId: string,
    locale: string,
    filterOptions: { fromDate?: string; toDate?: string; limit?: number; offset?: number; orderId?: string },
  ) => {
    try {
      return await accountService.orders({
        accountId,
        locale,
        filterOptions,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch orders',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client query to fetch inProcess orders for an account
 * @async
 * @param {string} accountId
 * @param {locale} locale
 * @returns {Promise<IAccountOrder[]>}
 * @param {number} type
 * @param {number} limit
 * @param {number} offset
 * @param {number} type
 *
 */
export const fetchInProcessOrders = withSession(
  // type is either 1 (ToDeliverLines) or 2 (OpenOrderLines)
  async (accountId: string, locale: string, filterOptions: { type: 1 | 2; limit?: number; offset?: number }) => {
    try {
      return await accountService.inProcessOrders({
        accountId,
        locale,
        filterOptions,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch inProcess orders',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client query to fetch advices for an account
 * @async
 * @param {string} accountId
 * @param {locale} locale
 * @returns {Promise<IAccountAdvice[]>}
 *
 */
export const fetchAdvices = withSession(
  async (accountId: string, locale: string) => {
    try {
      return await accountService.advices({
        accountId,
        locale,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch advices',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client query to fetch account discount info
 * @async
 * @param {string} accountId
 * @param {locale} locale
 * @returns {Promise<IAccountDiscountInfo[]>}
 *
 */
export const fetchAccountDiscountInfo = withSession(
  async (accountId: string, locale: string, type: TAccountDiscountInfoType) => {
    try {
      return await accountService.accountDiscountInfo({
        accountId,
        locale,
        type,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Fetch account discount info',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to save a subscription for an account
 */
export const saveSubscription = withSession(
  async ({
    email,
    firstName,
    lastName,
    subscribeToNewsletter,
    subscribeToMagazine,
    gatedContentUrl,
    isLoggedIn,
    locale,
  }: {
    email: string;
    firstName?: string;
    lastName?: string;
    subscribeToNewsletter?: boolean;
    subscribeToMagazine?: boolean;
    gatedContentUrl?: string;
    isLoggedIn?: boolean;
    locale: string;
  }) => {
    try {
      return await accountService.saveSubscription({
        email,
        firstName,
        lastName,
        subscribeToNewsletter,
        subscribeToMagazine,
        gatedContentUrl,
        isLoggedIn,
        locale,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Save subscription',
        },
      });
      throw error;
    }
  },
  { alwaysRun: true },
);

/**
 * Client mutation to create a new account
 *
 * @async
 * @param {IAccount} account The account data to create
 * @param {string} locale The locale to use
 * @returns {Promise<IAccount>}
 */
export const createAccount = withSession(
  async (account: IAccountCreate, locale: string) => {
    try {
      return await accountService.createAccount({
        account,
        locale,
      });
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          type: 'Create account',
        },
      });

      // Needs a re-throw
      throw error;
    }
  },
  { alwaysRun: true },
);
