import Head from 'next/head';
import { Fragment, ReactNode } from 'react';
import { BreadcrumbList, Organization, SoftwareApplication, Thing, WithContext } from 'schema-dts';

import { CHANNEL } from '@boss/constants/shared';
import { useRouter } from '@boss/hooks';
import { Crumble, LocalizedSlugs, Navigation, Nullable } from '@boss/types/b2b-b2c';

import { getBaseUrl } from '../../constants';
import { isB2b, seoConfig } from '../../utils';

type Props<T extends Thing> = {
  title?: string;
  description?: string;
  noIndex?: boolean;
  noFollow?: boolean;
  children: ReactNode;
  imageSrc?: string | null;
  navigation: Nullable<Navigation>;
  localizedSlugs: LocalizedSlugs;
  structuredData?: WithContext<T>[];
  breadcrumbs?: Crumble[];
};

const noIndexing = process.env.NEXT_PUBLIC_NO_INDEXING === 'true';

const getRobots = (noFollow: boolean, noIndex: boolean) => {
  const followValue = noFollow || noIndexing ? 'nofollow' : 'follow';
  const indexValue = noIndex || noIndexing ? 'noindex' : 'index';

  return `${followValue}, ${indexValue}`;
};

const SEO = <T extends Thing>({
  title: initialTitle,
  localizedSlugs,
  description,
  imageSrc,
  noIndex = false,
  noFollow = false,
  breadcrumbs = [],
  children,
  navigation,
  structuredData,
}: Props<T>) => {
  const { titleSuffixContent, titleSuffixContentNl } = seoConfig;
  const { locale, locales } = useRouter();

  const buildUrl = ({ path, locale }: { path: string; locale: string }) => {
    const _path = path?.startsWith('/') ? path.slice(1) : path;
    const url = `${getBaseUrl(locale)}/${_path}`;

    return url?.replace(/(?<!:)\/\//g, '/').replace(/\/+$/, '');
  };

  const robots = getRobots(noFollow, noIndex);
  const title = `${initialTitle} - ${
    locale === 'nl-NL' && titleSuffixContentNl ? titleSuffixContentNl : titleSuffixContent
  }`;

  const filteredLocalizedSlugs = Object.entries(localizedSlugs).reduce<Record<string, string>>((acc, [key, value]) => {
    if (locales.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {});
  const currentUrl = buildUrl({ path: filteredLocalizedSlugs[locale], locale });
  const schemaString = 'https://schema.org';

  const breadCrumbJsonLd: WithContext<BreadcrumbList> = {
    '@context': schemaString,
    '@type': 'BreadcrumbList',
    itemListElement: breadcrumbs.map(({ href, label }, i) => ({
      '@type': 'ListItem',
      position: i,
      item: {
        '@id': `${getBaseUrl(locale)}${href}`,
        name: label,
      },
    })),
  };

  const organisationJsonLd: WithContext<Organization> = {
    '@context': schemaString,
    '@type': 'Organization',
    name: CHANNEL,
    url: getBaseUrl(locale),
    logo: navigation?.headerLogo?.imageUrl,
  };

  const pwaStructData: WithContext<SoftwareApplication> = {
    '@context': schemaString,
    '@type': 'WebApplication',
    name: 'B2B - PWA',
    applicationCategory: 'BusinessApplication',
    operatingSystem: 'All',
  };

  return (
    <Fragment>
      <Head>
        {title && (
          <>
            <title key="title">{title}</title>
            <meta content={title} key="og:title" property="og:title" />
            <meta content={title} key="twitter:title" name="twitter:title" />
          </>
        )}

        {description && (
          <>
            <meta content={description} key="description" name="description" />
            <meta content={description} key="twitter:description" name="twitter:description" />
          </>
        )}

        {currentUrl && (
          <>
            <link href={currentUrl} key="canonical" rel="canonical" />
            <meta content={currentUrl} key="og:url" property="og:url" />
          </>
        )}

        {Object.entries(filteredLocalizedSlugs)?.map(([locale, path]) => (
          <link href={buildUrl({ path, locale })} hrefLang={locale} key={`${locale}-${path}`} rel="alternate" />
        ))}

        {robots && <meta content={robots} name="robots" />}
        {imageSrc && <meta content={imageSrc} property="og:image" />}
        <script dangerouslySetInnerHTML={{ __html: JSON.stringify(organisationJsonLd) }} type="application/ld+json" />

        {!!breadcrumbs.length && (
          <script dangerouslySetInnerHTML={{ __html: JSON.stringify(breadCrumbJsonLd) }} type="application/ld+json" />
        )}
        {structuredData && (
          <script dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }} type="application/ld+json" />
        )}
        {isB2b && (
          <script dangerouslySetInnerHTML={{ __html: JSON.stringify(pwaStructData) }} type="application/ld+json" />
        )}
      </Head>
      {children}
    </Fragment>
  );
};

export default SEO;
