import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useRef, useState } from 'react';
import Supercluster, { AnyProps, ClusterFeature, PointFeature } from 'supercluster';

import { CHANNEL } from '@boss/constants/b2b-b2c';
import { IStore } from '@boss/services';

import { StoreMarker, StoreMarkerCluster } from './markers';
import { StoreCardTranslations } from '../StoreCard';

type Map = google.maps.Map & { zoom: number };

const getContainerStyle = (isColora: boolean) => {
  if (isColora) {
    return { width: '100%', height: '100%', borderRadius: '1.5rem' };
  }
  return { width: '100%', height: '100%' };
};

const options = {
  streetViewControl: false,
  mapTypeControl: false,
  fullscreenControl: false,
  maxZoom: 20,
  minZoom: 6,
};

const sc = new Supercluster({ radius: 70, maxZoom: options.maxZoom });

const formatDataToGeoJsonPoints = (stores: IStore[]): GeoJSON.Feature<GeoJSON.Point>[] => {
  return stores.map(store => ({
    type: 'Feature',
    geometry: { type: 'Point', coordinates: [store.longitude, store.latitude] },
    properties: { cluster: false, ...store },
  }));
};

const StoreMap = ({
  stores,
  apiKey,
  centerPosition,
  storeBasePath,
  storeCardVariant,
  translations,
}: {
  stores: IStore[];
  apiKey: string;
  centerPosition: google.maps.LatLngLiteral;
  storeBasePath: string;
  storeCardVariant: 'primary' | 'secondary';
  translations: StoreCardTranslations;
}) => {
  const [activeStoreId, setActiveStoreId] = useState<string | undefined>();
  const { isLoaded: isMapLoaded } = useJsApiLoader({ googleMapsApiKey: apiKey });
  const [zoom, setZoom] = useState<number>(8);
  const [bounds, setBounds] = useState<GeoJSON.BBox>([0, 0, 0, 0]);
  const [clusters, setClusters] = useState<(PointFeature<AnyProps> | ClusterFeature<AnyProps>)[]>();
  const mapRef = useRef<Map>();
  const isColora = CHANNEL === 'colora';

  useEffect(() => {
    if (stores?.length && mapRef.current) {
      sc.load(formatDataToGeoJsonPoints(stores) as PointFeature<GeoJSON.Feature<GeoJSON.Point>>[]);
      setClusters(sc.getClusters(bounds, zoom));
    }
  }, [stores, bounds, zoom]);

  if (!isMapLoaded) {
    return null;
  }

  const handleClusterClick = ({ id, lat, lng }: { id: number; lat: number; lng: number }) => {
    const expansionZoom = Math.min(sc.getClusterExpansionZoom(id), 20);

    mapRef.current?.setZoom(expansionZoom);
    panTo({ lat, lng });
  };

  const handleMarkerClick = async ({ id, lat, lng }: { id: string; lat: number; lng: number }) => {
    mapRef.current?.setZoom(10);
    await panTo({ lat: lat + 0.02, lng });
    setActiveStoreId(id);
  };

  const panTo = async ({ lat, lng }: google.maps.LatLngLiteral) => {
    await mapRef.current?.panTo({ lat, lng });
  };

  const handleBoundsChanged = () => {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds()?.toJSON();

      setBounds([bounds?.west || 0, bounds?.south || 0, bounds?.east || 0, bounds?.north || 0]);
    }
  };

  const handleZoomChanged = () => {
    if (mapRef.current) {
      setZoom(mapRef.current?.zoom);
    }
  };

  const handleMapLoad = (map: google.maps.Map) => {
    mapRef.current = map as Map;
  };

  return (
    <GoogleMap
      center={centerPosition}
      mapContainerStyle={getContainerStyle(isColora)}
      onBoundsChanged={handleBoundsChanged}
      onLoad={handleMapLoad}
      onZoomChanged={handleZoomChanged}
      options={options}
      zoom={zoom}
    >
      {clusters?.map(({ id, geometry, properties }) => {
        const [lng, lat] = geometry.coordinates;
        const { cluster, point_count } = properties;

        return cluster ? (
          <StoreMarkerCluster
            count={point_count}
            key={`cluster-${id}`}
            onClick={() => handleClusterClick({ id: id as number, lat, lng })}
            position={{ lat, lng }}
          />
        ) : (
          <StoreMarker
            activeMarkerId={activeStoreId}
            key={`store-${properties.id}`}
            onClose={() => setActiveStoreId(undefined)}
            onSetActiveMarker={() => handleMarkerClick({ id: properties.id, lat, lng })}
            position={{ lat, lng }}
            store={properties as IStore}
            storeBasePath={storeBasePath}
            storeCardVariant={storeCardVariant}
            translations={translations}
          />
        );
      })}
    </GoogleMap>
  );
};

export default StoreMap;
