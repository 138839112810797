import { useTranslation } from 'next-i18next';

import { ProductCard } from '@boss/ui';

import { useArticleAccountPrice, useFavorites, usePrice, useProductAccountPrice, useProductMap } from '../../hooks';
import { isB2b } from '../../utils';

const useProductCardTranslations = (props: {
  translations?: React.ComponentProps<typeof ProductCard>['translations'];
  isArticle?: boolean;
  fromPriceUnit?: string;
}) => {
  const { t } = useTranslation('common');
  const { isArticle, fromPriceUnit } = props;

  return {
    quantityPrefix: !isArticle ? ((props.translations?.quantityPrefix ?? t('quantityPrefix')) as string) : '',
    disclaimer: !isArticle ? ((props.translations?.disclaimer ?? t('fromPriceDisclaimer')) as string) : '',
    unitPrice: fromPriceUnit ? t(`fromPriceUnits.${fromPriceUnit}`) ?? '' : '',
    unitLabel: fromPriceUnit && !isB2b && !isArticle ? t(`fromPriceUnitsShort.${fromPriceUnit}`) ?? '' : '',
  };
};

const ProductCardWrapper = ({
  counterMin,
  price: productPrice,
  hideWishlistAction,
  showCounter,
  isArticle,
  unit,
  ...props
}: React.ComponentProps<typeof ProductCard> & {
  counterMin?: number;
  hideWishlistAction?: boolean;
  showCounter?: boolean;
  isArticle?: boolean;
  unit?: string;
}) => {
  const { favorites, toggleFavorite } = useFavorites();
  const { showPrice } = usePrice();
  const {
    priceToShow: productPriceToShow,
    fromPriceUnit: productFromPriceUnit,
    strikePrice: productStrikePrice,
    isLoading: priceInfoLoading,
    productLabels,
  } = useProductAccountPrice({ productId: props.id, price: productPrice });

  const {
    priceToShow: articlePriceToShow,
    strikePrice: articleStrikePrice,
    isLoading: articlePriceInfoLoading,
  } = useArticleAccountPrice({ articleId: props.id, price: productPrice, unit, enabled: !!isArticle });

  const price = isArticle ? articlePriceToShow : productPriceToShow;
  const strikePrice = isArticle ? articleStrikePrice : productStrikePrice;
  const fromPriceUnit = isArticle ? unit : productFromPriceUnit;

  const { mapProductLabels } = useProductMap();

  const labels =
    props.labels || productLabels ? [...mapProductLabels(productLabels || []), ...(props.labels || [])] : undefined;

  const translations = useProductCardTranslations({
    translations: props.translations,
    isArticle,
    fromPriceUnit,
  });

  return (
    <ProductCard
      {...props}
      counterMin={counterMin}
      handleAddToWishlist={hideWishlistAction ? undefined : () => toggleFavorite('PRODUCTS', props.id)}
      inWishlist={favorites['PRODUCTS']?.includes(props.id)}
      labels={labels}
      price={showPrice ? price : undefined}
      priceIsLoading={priceInfoLoading || articlePriceInfoLoading}
      showCounter={showCounter}
      strikePrice={isArticle ? articleStrikePrice : strikePrice}
      translations={translations}
    />
  );
};

export default ProductCardWrapper;
