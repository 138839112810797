import { types } from '@boss/boss-client';
import { IOrderAddress } from '@boss/types/b2b-b2c';

import { IAccountOrder, IOrderLine } from '../types';

export const mapOrderToAccountOrder = (order: types.account.Order): IAccountOrder => ({
  id: order.id,
  salesId: order.salesid,
  custAccount: order.custaccount,
  customerRef: order.customerref,
  deliverymode: order.deliverymode,
  deliveryMethod: order.delivery?.deliverymethod,
  deliveryLocation: order.deliverylocation,
  deliveryStatus: order.delivery?.status,
  distributionDescription: order.distributiondescription,
  receiptDateRequested: order.receiptdaterequested,
  createdDatetime: order.createddatetime,
  online: order.online,
  salesStatus: order.salesstatus,
  intreatment: order.intreatment,
  basketId: order.basketid,
  paid: order.paid,
  salesType: order.salestype,
  tourInfo: order.tourinfo,
  addresses: order.addresses?.map(mapOrderAddress),
  lines: order.lines?.map(line => mapOrderLine(line, order.id)),
  trackAndTrace: order.delivery?.tracktracelink,
});

const mapOrderAddress = (address: types.account.OrderAddress): IOrderAddress => ({
  role: address.role,
  street: address.street,
  streetNumber: address.streetnumber,
  postbox: address.postbox,
  zipcode: address.zipcode,
  city: address.city,
  countryRegionId: address.countryregionid,
});

const mapOrderLine = (line: types.account.OrderLine, orderId: string): IOrderLine => ({
  lineNumber: line.linenumber,
  itemId: line.itemid,
  name: line.name,
  colorId: line.colorid,
  colorName: line.colorname,
  colorCode: line.colorcode,
  colorTypeGroups: line.colortypegroups,
  rgb: line.rgb,
  backorder: line.backorder,
  quantity: {
    secundaryQty: line.quantity?.secundaryqty,
    secundaryUnit: line.quantity?.secundaryunit,
    salesQty: line.quantity?.salesqty,
    salesUnit: line.quantity?.salesunit,
    onorderQty: line.quantity?.onorderqty,
    canceledQty: line.quantity?.canceledqty,
    remainSalesPhysical: line.quantity?.remainsalesphysical,
    deliveredQuantity: line.quantity?.deliveredquantity,
    invoicedQuantity: line.quantity?.invoicedquantity,
    tobeDeliveredQty: line.quantity?.tobedeliveredqty,
  },
  salesStatus: line.salesstatus,
  priceDiscount: {
    salesPrice: line.pricediscount?.salesprice,
    linePercentage: line.pricediscount?.linepercentage,
    netAmountexvat: line.pricediscount?.netamountexvat,
    campaignId: line.pricediscount?.campaignid,
  },
  receiptDateRequested: line.receiptdaterequested,
  imageUrl: line.imageurl ?? (orderId?.startsWith('UW') ? '/packshot-alt.png' : '/default-packshot.png'),
});
