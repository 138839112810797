import { twMerge } from 'tailwind-merge';

const RoundedItem = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return (
    <div className={twMerge('shadow-m rounded-full', className)}>
      <span className="absolute left-1/2 top-1/2 -translate-x-[50%] -translate-y-[50%] transform">{children}</span>
    </div>
  );
};

export default RoundedItem;
