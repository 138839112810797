import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type VariantProps, cva } from 'class-variance-authority';
import { useCollapse } from 'react-collapsed';
import { twMerge } from 'tailwind-merge';

import { NavigationItem } from '@boss/types/b2b-b2c';

import FooterNavItem from './FooterNavItem';

type MobileNavigationItemProps = VariantProps<typeof LabelStyles> &
  VariantProps<typeof LinkStyles> & {
    navItem: NavigationItem;
    boldLabel?: boolean;
    mobileText?: boolean;
    isCollapsible?: boolean;
    linkClassName?: string;
    labelClassName?: string;
    liClassName?: string;
  };

const LabelStyles = cva('w-full flex justify-between items-center text-body-mobile text-white break-all', {
  variants: {
    boldLabel: {
      true: 'font-bold',
      false: '',
    },
    type: {
      primary: 'pb-5',
      secondary: 'text-lg',
    },
  },
  defaultVariants: {
    boldLabel: true,
  },
});

const LinkStyles = cva('flex items-center gap-1.5 text-footer-text pb-5 text-white', {
  variants: {
    mobileText: {
      true: 'md:text-body text-body-mobile',
      false: 'text-body',
    },
    hasUrl: {
      true: 'hover:underline text-white',
      false: 'text-white',
    },
  },
  defaultVariants: {
    mobileText: false,
  },
});

const ListStyles = cva('w-full', {
  variants: {
    type: {
      primary: '',
      secondary: 'mt-4',
    },
  },
});

const MobileNavigationItem = ({
  navItem,
  boldLabel = true,
  mobileText = false,
  isCollapsible = true,
  type = 'primary',
  labelClassName,
  linkClassName,
  liClassName,
}: MobileNavigationItemProps) => {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <li className={twMerge('col-span-full flex flex-col items-start lg:hidden', liClassName)} key={navItem.id}>
      {navItem.label && (
        <div
          className={twMerge(LabelStyles({ boldLabel, type }), labelClassName)}
          {...(isCollapsible ? { ...getToggleProps() } : null)}
        >
          {navItem.label}
          {isCollapsible && <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />}
        </div>
      )}
      <ul className={ListStyles({ type })} {...(isCollapsible ? { ...getCollapseProps() } : null)}>
        {navItem.childNavigationItems?.map((childNavItem, i) => {
          return (
            <li
              className={twMerge(LinkStyles({ mobileText, hasUrl: !!childNavItem.href }), linkClassName)}
              key={`${childNavItem.id}-${i}`}
            >
              {childNavItem?.icon && <FontAwesomeIcon icon={childNavItem.icon} />}
              <FooterNavItem link={childNavItem.href}>{childNavItem.label}</FooterNavItem>
            </li>
          );
        })}
      </ul>
    </li>
  );
};

export default MobileNavigationItem;
