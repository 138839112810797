import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { DEFAULT_COLOR_GRID_SIZE, getCollapsePropsWithOffset, paintguidePageSlugs } from '@boss/constants/b2b-b2c';
import { useIsResponsive, useRouter } from '@boss/hooks';
import {
  Color,
  ColorGroup,
  Nullable,
  OverviewFields,
  PromotionCtaFields,
  SectionFields,
  SimpleColor,
} from '@boss/types/b2b-b2c';
import {
  Button,
  ColorButton,
  ColorGroupButton,
  ColorsGridSkeleton,
  Divider,
  Image,
  InspirationImage,
  Note,
  Presence,
  SearchBar,
  Section,
} from '@boss/ui';

import { useColorByColorId, useColorsByGroup, useRelatedColors, useSearchColor } from '../../client-queries';
import {
  ColorGridWrapper,
  ColorToolSection,
  ComponentMapper,
  CtaCardsSection,
  RecentlyViewedColors,
} from '../../components';
import { useRecentViewedColors } from '../../hooks';
import { isB2b, mapColorSlugField } from '../../utils';

type Props = {
  colorGroups: ColorGroup[];
  showMoreAmount?: number;
  generalContent?: {
    callToActions: Nullable<PromotionCtaFields[]>;
  };
  colorToolSection: Nullable<SectionFields>;
  cmsData: Nullable<OverviewFields>;
};

const ColorOverviewPage = ({
  cmsData,
  colorGroups,
  showMoreAmount = DEFAULT_COLOR_GRID_SIZE,
  generalContent,
  colorToolSection,
}: Props) => {
  const { t } = useTranslation('color');
  const { locale } = useRouter();
  const isMobile = useIsResponsive();
  const { addColor } = useRecentViewedColors();

  const [showMoreColors, setShowMoreColors] = useState(false);
  const [selectedColorGroup, setSelectedColorGroup] = useState<ColorGroup>();
  const [selectedColor, setSelectedColor] = useState<Color>();
  const [searchValue, setSearchValue] = useState('');
  const [searchedValue, setSearchedValue] = useState('');
  const [hasSearched, setHasSearched] = useState(false);
  const { data: selectedCfColor } = useColorByColorId(selectedColor?.id ?? '');

  const { data: initialColors, isInitialLoading: colorsLoading } = useColorsByGroup({
    groupCode: selectedColorGroup?.code,
  });
  const { data: searchColors, isInitialLoading: searchLoading } = useSearchColor({
    value: searchedValue,
    enabled: !!hasSearched,
  });
  const { data: relatedColors, isInitialLoading: loadingRelated } = useRelatedColors(selectedColor?.id);
  const hasShowMore = (initialColors?.length ?? 0) > showMoreAmount;
  const activeColors = showMoreColors ? initialColors : [...(initialColors ?? [])]?.splice(0, showMoreAmount);
  const inspiration = selectedCfColor?.inspirations?.[0];

  const handleSearch = () => {
    setSearchedValue(searchValue);
    setHasSearched(!!searchValue);
  };

  const handleSearchValueChange = (value: string) => {
    if (value.length === 0) {
      setHasSearched(false);
    }

    setSearchValue(value);
  };

  const handleShowMore = () => setShowMoreColors(val => !val);

  const handleFavorite = () => {
    // TODO: Implement favorite
  };

  const handleChangeColor = (color: SimpleColor, colorList: Color[] | undefined) => {
    const foundColor = colorList?.find(listColor => listColor.code === color.code);

    if (foundColor) {
      setSelectedColor(foundColor);

      if (isMobile) {
        const element = document.getElementById('color-image-presence');
        const offset = 100;
        const elementPosition = element?.getBoundingClientRect().top ?? 0;
        const offsetPosition = elementPosition + window.scrollY - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  const handleChangeColorClick = (color: SimpleColor) => {
    handleChangeColor(color, initialColors);
  };

  const handleChangeSearchColorClick = (color: SimpleColor) => {
    handleChangeColor(color, searchColors);
  };
  const handleColorGroupSelect = (group: ColorGroup) => {
    setSelectedColorGroup(group);

    const element = document.getElementById('color-picker-overview-colors-presence');
    const offset = 100;
    const elementPosition = element?.getBoundingClientRect().top ?? 0;
    const offsetPosition = elementPosition + window.scrollY - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (!selectedColorGroup && colorGroups) {
      setSelectedColorGroup(colorGroups[0]);
    }
  }, [colorGroups, selectedColorGroup]);

  useEffect(() => {
    if (initialColors && !selectedColor) {
      setSelectedColor(initialColors[0]);
    }
  }, [initialColors, selectedColor]);

  return (
    <div className="lg:gap-x-30 grid md:grid-cols-3 md:gap-x-10">
      <Presence className="md:-order-1 md:col-span-2" id="color-overview-top-section" visible>
        <Section
          className="pt-0"
          content={
            <div className="flex flex-col gap-8 md:gap-10">
              <div className="flex flex-col gap-5">
                <h1>{t('colorPicker')}</h1>
                <SearchBar
                  onSearch={handleSearch}
                  searchLabel={t('searchInputFieldPlaceholder') ?? undefined}
                  setSearchValue={handleSearchValueChange}
                  type="primary"
                  value={searchValue}
                />
                <span className="small text-gray-dark hidden md:inline">{t('cautionMessage')}</span>
              </div>

              <Presence
                className="flex flex-col gap-8"
                id="color-overview-search-colors-presence"
                isLoading={searchLoading}
                loader={<ColorsGridSkeleton amount={5} gridClassName="md:grid-cols-5" variant="secondary" />}
                visible={hasSearched}
                {...getCollapsePropsWithOffset(isMobile ? '-2rem' : '-2.5rem')}
              >
                <ColorGridWrapper
                  colors={searchColors}
                  gridClassName="md:grid-cols-5"
                  onChangeColorClick={handleChangeSearchColorClick}
                  selectedColor={selectedColor}
                  title={t('grid.results') ?? undefined}
                  translations={t('grid', { ns: 'color', returnObjects: true })}
                  variant="secondary"
                />
                <Divider className="hidden md:flex" />
              </Presence>

              <div className="grid grid-cols-2 justify-items-center gap-4 md:grid-cols-5">
                {colorGroups.map(group => (
                  <div className="w-full" key={`overview-color-group-${group.code}`}>
                    <ColorGroupButton
                      active={selectedColorGroup?.code === group.code}
                      backgroundColor={group.rgb}
                      className="md:hidden"
                      name={group.name ?? group.code}
                      onClick={() => handleColorGroupSelect(group)}
                      variant="primary"
                    />
                    <ColorButton
                      active={selectedColorGroup?.code === group.code}
                      backgroundColor={group.rgb}
                      className="hidden justify-center md:flex"
                      name={group.name ?? group.code}
                      onClick={() => handleColorGroupSelect(group)}
                      variant="secondary"
                    />
                  </div>
                ))}

                <Note className="bg-yellow col-span-full flex w-full items-center gap-2 rounded-md p-2 md:hidden">
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span className="caption">{t('cautionMessage')}</span>
                </Note>
              </div>

              <Divider className="hidden md:flex" />

              <Presence
                className="w-full"
                id="color-picker-overview-colors-presence"
                isLoading={colorsLoading}
                loader={<ColorsGridSkeleton amount={10} gridClassName="md:grid-cols-5" variant="secondary" />}
                visible
              >
                <ColorGridWrapper
                  colors={activeColors}
                  gridClassName="md:grid-cols-5"
                  handleShowMore={handleShowMore}
                  hasShowMore={hasShowMore}
                  id="active-overview-colors"
                  onChangeColorClick={handleChangeColorClick}
                  onFavorite={handleFavorite}
                  selectedColor={selectedColor}
                  showMore={showMoreColors}
                  title={t('mostPickedColorsFor') + ` "${selectedColorGroup?.name?.toLowerCase() ?? ''}"`}
                  titleClassName="h2 md:h3"
                  translations={t('colorPickerModal', { ns: 'product', returnObjects: true })}
                  variant="secondary"
                />
              </Presence>
            </div>
          }
        />
      </Presence>
      <Presence
        className="md:col-span-full"
        id="related-overview-colors-presence"
        isLoading={loadingRelated}
        visible={!!relatedColors?.length}
      >
        <Section
          backgroundColor="bg-beige-light"
          content={
            <div className="flex flex-col gap-10">
              <h3>{t('relatedColorsTitle', { name: selectedColor?.name })}</h3>
              {relatedColors?.length && (
                <ColorGridWrapper
                  colors={relatedColors}
                  gridClassName="grid-cols-2 sm:grid-cols-3 md:grid-cols-6"
                  id="selected-colors"
                  onChangeColorClick={addColor}
                  onClickAction="navigate"
                  translations={t('colorPickerModal', { ns: 'product', returnObjects: true })}
                  variant="secondary"
                />
              )}
            </div>
          }
        />
      </Presence>
      <Section
        className="md:-order-1 md:col-span-1"
        content={
          <div className="flex flex-col gap-10">
            {selectedColor && (
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-3">
                  <span className="h3 text-gray-dark">
                    {selectedColor.code} {selectedColor.name ? `- ${selectedColor.name}` : ''}
                  </span>
                </div>
                <Presence id="color-image-presence" layoutId="color-images" mode="wait" visible>
                  {inspiration ? (
                    <InspirationImage
                      asset={inspiration.image.image}
                      className="m-0"
                      imageClassName="rounded-tl-none h-100 md:h-150"
                    />
                  ) : (
                    <Image
                      alt="paint-image"
                      className="h-100 md:h-150 object-cover"
                      height={600}
                      round="all"
                      square="tl"
                      src="/verfwijzer.png"
                      style={{ backgroundColor: selectedColor.rgb ?? '' }}
                      width={300}
                    />
                  )}
                </Presence>
              </div>
            )}
            <Presence id="inspiration-buttons-presence" visible={!!selectedColor}>
              <div className="flex flex-col gap-4">
                {selectedCfColor?.slug && (
                  <Button
                    className="text-center md:w-full"
                    href={mapColorSlugField(locale, selectedCfColor).slug}
                    label={t('viewColorButton') ?? undefined}
                    type="primary"
                  />
                )}

                {selectedColor?.id && !isB2b && (
                  <Button
                    className="text-wrap text-center md:w-full"
                    href={`${paintguidePageSlugs[locale]}?color=${selectedColor.id}`}
                    label={t('chooseColorType') ?? undefined}
                    type="secondary"
                  />
                )}
              </div>
            </Presence>
          </div>
        }
      />

      <div className="col-span-full">
        {cmsData?.prefixSections?.map(entry => (
          <ComponentMapper entry={entry} key={entry.id} locale={locale} />
        ))}

        {!!generalContent?.callToActions?.length && <CtaCardsSection callToActions={generalContent?.callToActions} />}

        <RecentlyViewedColors />

        {colorToolSection && !isB2b && (
          <ColorToolSection entry={colorToolSection} locale={locale} selectedColor={selectedColor} />
        )}

        {cmsData?.suffixSections?.map(entry => (
          <ComponentMapper entry={entry} key={entry.id} locale={locale} />
        ))}
      </div>
    </div>
  );
};

export default ColorOverviewPage;
