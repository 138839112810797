import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';

import { IDeliveryOptions, PAYMENT_TYPE_UNION } from '@boss/services/client';

import { useAccount, useCreateAccount, usePatchBasket } from '../../client-queries';
import { checkoutConfig, isB2b } from '../../utils';

export interface StoreType {
  id: string;
  name: string;
  payServiceId?: string;
  warehouseId?: string;
}

export interface PickupMethod {
  type: 'pickup';
  choice?: 'shop' | 'clickandcollect';
  pickupStore?: StoreType;
  clickAndCollectStore?: StoreType;
  warehouseId?: string;
}

export interface DeliveryMethod {
  type: 'delivery';
  address?: 'standard' | 'wharf';
  addressDetail?: string;
  deliveryStore?: StoreType;
}

export enum DeliveryMethodEnum {
  StandardDelivery = 1,
  WharfDelivery = 2,
  Pickup = 3,
  ClickAndCollect = 4,
}

export type DeliveryMethodTypes = PickupMethod | DeliveryMethod | null;

type Address = {
  type?: string;
  street?: string;
  streetnumber?: string;
  zipcode?: string;
  postbox?: string;
  city?: string;
  country?: string;
};

export type DeliveryOptions = {
  info?: string;
  address?: Address;
  contactfordelivery?: string;
  invoice?: {
    reference?: string;
    info?: string;
    separatefororders?: boolean;
    address?: Address;
  };
  delivery?: {
    info?: string;
    storeId?: string;
  };
  deliverymode?: string;
  sameAddressAsBilling?: boolean;
  orderType?: 'private' | 'business';
  account?: {
    name?: string;
    vatnumber?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
    preferences?: {
      newsletter?: boolean;
      storeId?: string;
    };
  };
} | null;

const EXCLUDE_INVOICE_PAYMENT_TERMS = ['0D', '0DB', '0DC', '0DN'];

/**
 * Custom hook for accessing the checkout state and functions.
 * @returns The checkout state and functions.
 */
export const useCheckout = () => {
  const { locale } = useRouter();
  const { data: account } = useAccount(locale as string);
  const { mutateAsync: createAccount } = useCreateAccount();
  const { mutateAsync: patchBasket } = usePatchBasket();
  const [deliveryMethod, setDeliveryMethod] = useState<DeliveryMethodTypes>(null);
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryOptions>(null);
  const [paymentMethod, setPaymentMethod] = useState<PAYMENT_TYPE_UNION>();
  const [deliveryDate, setDeliveryDate] = useState<string | null>(null);
  const [deliveryMode, setDeliveryMode] = useState<string | null>(null);
  const [warehouseId, setWarehouseId] = useState<string | undefined>(undefined);
  const variant = checkoutConfig.deliveryVariant;

  const accountInvoiceAddress = account?.addresses?.find(address => address.type === 'invoice');

  const checkAddressFields = (
    address:
      | {
          street?: string | undefined;
          streetnumber?: string | undefined;
          zipcode?: string | undefined;
          postbox?: string | undefined;
          city?: string | undefined;
          country?: string | undefined;
        }
      | undefined,
  ) => {
    return address && address.street && address.streetnumber && address.zipcode && address.city && address.country;
  };

  const validateInformation = (
    address:
      | undefined
      | {
          street?: string | undefined;
          streetnumber?: string | undefined;
          zipcode?: string | undefined;
          postbox?: string | undefined;
          city?: string | undefined;
          country?: string | undefined;
        },
  ) => {
    if (
      deliveryMethod?.type === 'delivery' &&
      deliveryMethod.address === 'wharf' &&
      deliveryMethod.addressDetail === 'new'
    ) {
      return !!address && checkAddressFields(address);
    }

    return true;
  };

  /**
   * For certain clients the reference field is required and should be checked before continuing
   */
  const validateReference = (shouldValidate: boolean, deliveryOptions: DeliveryOptions) => {
    if (!shouldValidate) {
      return true;
    }
    return !!deliveryOptions?.invoice?.reference?.length;
  };

  const validatePickupOption = () => {
    if (deliveryMethod?.type !== 'pickup') {
      return true;
    }

    if (deliveryMethod?.choice === 'shop') {
      return !!deliveryMethod.pickupStore?.id;
    } else {
      return !!deliveryMethod.clickAndCollectStore?.id;
    }
  };

  const validateDeliveryOption = () => {
    if (deliveryMethod?.type !== 'delivery') {
      return true;
    }

    // Check for 'secondary' variant with an address, or 'primary' variant without an address.
    const isSecondaryWithAddress = variant === 'secondary' && !!deliveryMethod.address;

    const isPrimaryWithoutAddress = variant === 'primary' && !deliveryMethod.address;

    return isSecondaryWithAddress || isPrimaryWithoutAddress;
  };

  const validateDelivery = () => deliveryMethod && validatePickupOption() && validateDeliveryOption();

  const mapDeliveryMethod = (method: DeliveryMethodTypes): DeliveryMethodEnum | null => {
    switch (method?.type) {
      case 'pickup':
        return method.choice === 'clickandcollect' ? DeliveryMethodEnum.ClickAndCollect : DeliveryMethodEnum.Pickup;
      case 'delivery':
        return method.address === 'wharf' ? DeliveryMethodEnum.WharfDelivery : DeliveryMethodEnum.StandardDelivery;
      default:
        return null;
    }
  };

  const mappedDeliveryOptions = useMemo((): IDeliveryOptions => {
    const options: IDeliveryOptions = {
      deliverymethod: mapDeliveryMethod(deliveryMethod) ?? 1,
      contactfordelivery: deliveryOptions?.contactfordelivery ?? '',
      info: deliveryOptions?.delivery?.info ?? '',
      requesteddate: deliveryDate ?? '',
      deliverymode: deliveryMode ?? '',
      invoice: {
        address: deliveryOptions?.invoice?.address ?? accountInvoiceAddress,
      },
    };

    switch (deliveryMethod?.type) {
      case 'pickup':
        if (deliveryMethod.choice === 'shop') {
          options.warehouse = deliveryMethod.pickupStore?.warehouseId ?? '';
        }

        options.storeid =
          deliveryMethod.choice === 'clickandcollect'
            ? deliveryMethod.clickAndCollectStore?.id
            : deliveryMethod.pickupStore?.id;
        break;
      case 'delivery':
        options.address = deliveryOptions?.sameAddressAsBilling ? options?.invoice?.address : deliveryOptions?.address;
        options.storeid = deliveryOptions?.delivery?.storeId;
        break;
    }

    return options;
  }, [deliveryMethod, deliveryOptions, deliveryDate, deliveryMode, accountInvoiceAddress]);

  const _setDeliveryMethod = (deliveryMethod: DeliveryMethodTypes, basketId: string) => {
    setDeliveryMethod(deliveryMethod);

    let warehouseId = '';

    if (!isB2b && deliveryMethod?.type === 'pickup' && deliveryMethod.clickAndCollectStore) {
      warehouseId = deliveryMethod.clickAndCollectStore?.warehouseId ?? '';
    } else if (deliveryMethod?.type === 'pickup' && deliveryMethod?.choice === 'shop') {
      warehouseId = deliveryMethod?.pickupStore?.warehouseId ?? '';
    } else {
      warehouseId = 'HM';
    }

    if (!isB2b) {
      patchBasket({
        basketId,
        patchLines: [
          {
            path: '/delivery/deliverymethod',
            op: 'replace',
            value: {
              deliverymethod: mapDeliveryMethod(deliveryMethod),
            },
          },
        ],
      });
    }

    setWarehouseId(warehouseId);
  };

  const mapAddress = (address: Address | undefined, type: 'invoice' | 'delivery') => ({
    type,
    street: address?.street ?? '',
    streetnumber: address?.streetnumber ?? '',
    postbox: address?.postbox ?? '',
    zipcode: address?.zipcode ?? '',
    city: address?.city ?? '',
    country: address?.country ?? '',
  });

  const createGuestAccount = async ({ errorCallback }: { errorCallback?: (error: unknown) => void }) => {
    const invoiceAddress = mappedDeliveryOptions?.invoice?.address;
    const deliveryAddress = mappedDeliveryOptions?.address;

    return await createAccount(
      {
        contactinfo: {
          email: deliveryOptions?.account?.email ?? '',
          secondaryphonenumber: deliveryOptions?.account?.phoneNumber ?? '',
          language: locale,
        },
        addresses: [mapAddress(invoiceAddress, 'invoice'), mapAddress(deliveryAddress, 'delivery')],
        billinginfo: {
          companyname: deliveryOptions?.account?.name ?? '',
          vatnumber: deliveryOptions?.account?.vatnumber ?? '',
        },
        accountinfo: {
          firstname: deliveryOptions?.account?.firstName ?? '',
          lastname: deliveryOptions?.account?.lastName ?? '',
        },
        preference: {
          shop: deliveryOptions?.account?.preferences?.storeId ?? '',
        },
      },
      {
        onError: (error: unknown) => errorCallback && errorCallback(error),
      },
    );
  };

  useEffect(() => {
    setDeliveryDate(null);
  }, [deliveryMethod]);

  return {
    deliveryMethod,
    setDeliveryMethod: _setDeliveryMethod,
    validateDelivery,
    validateReference,
    validateInformation,
    deliveryOptions,
    setDeliveryOptions,
    paymentMethod,
    setPaymentMethod,
    deliveryDate,
    setDeliveryDate,
    setDeliveryMode,
    deliveryMode,
    warehouseId,
    mappedDeliveryOptions,
    createGuestAccount,
    invoicePaymentAllowed: !EXCLUDE_INVOICE_PAYMENT_TERMS.includes(account?.billinginfo?.paymentterm ?? ''),
  };
};
