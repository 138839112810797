import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { BasicVariant } from '@boss/types/b2b-b2c';

export interface Props {
  className?: string;
  classNameIcon?: string;
  label?: string;
  subtitle?: string;
  icon: IconDefinition;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  chip?: boolean;
  disabled?: boolean;
  variant?: BasicVariant;
  testid?: string;
}

const ChipButtonStyles = cva(
  'w-8 h-8 flex items-center justify-center rounded-full bg-white drop-shadow-highlight shadow-m',
  {
    variants: {
      disabled: {
        true: 'opacity-50 cursor-not-allowed',
      },
    },
  },
);

const FullButtonStyles = cva('flex w-full items-stretch gap-7 text-left', {
  variants: {
    variant: {
      primary: 'rounded-tl-none rounded-5 shadow-m',
      secondary: 'shadow-lg',
    },
  },
});

const FullButtonIconStyles = cva('rounded- items-center justify-center bg-opacity-50 px-7 py-7 align-middle', {
  variants: {
    variant: {
      primary: 'bg-green-entangled rounded-bl-5 [&>*]:text-blue',
      secondary: 'bg-blue-light flex [&>*]:text-blue-dark ',
    },
  },
});

const IconButton = ({
  label,
  subtitle,
  icon,
  onClick: handleClick,
  disabled,
  chip,
  className,
  classNameIcon,
  variant = 'primary',
  testid = 'icon-button',
}: Props) => {
  return chip ? (
    <button
      className={twMerge(ChipButtonStyles({ disabled }), className)}
      data-testid={testid}
      disabled={disabled}
      onClick={handleClick}
    >
      <FontAwesomeIcon className={twMerge(classNameIcon, 'color')} icon={icon} />
    </button>
  ) : (
    <button className={twMerge(FullButtonStyles({ variant }), className)} onClick={handleClick}>
      <div className={twMerge(FullButtonIconStyles({ variant }), classNameIcon)}>
        <FontAwesomeIcon className="text-2xl" icon={icon} />
      </div>
      <div className="flex-inline-block flex items-center justify-center py-2 pr-7">
        <div>
          <div className="text-2xl font-bold">{label}</div>
          <div>{subtitle}</div>
        </div>
      </div>
    </button>
  );
};

export default IconButton;
