import { event } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: false };
const HEADERS = { 'Content-Type': 'application/json' };

export const getEvents = (locale: string) => {
  return bossApi<event.Event[]>(`/service/v2/events`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

export const participateEvent = ({
  eventId,
  data,
  isLoggedIn,
  locale,
}: {
  eventId: string;
  data: Record<string, unknown>;
  isLoggedIn?: boolean;
  locale: string;
}) => {
  const basePath = isLoggedIn ? '/service/v2' : '/guest/service/v2';

  return bossApi<event.ParticipateEventResponse>(`${basePath}/events/${eventId}/participants`, {
    ...DEFAULT_OPTIONS,
    headers: HEADERS,
    method: 'POST',
    body: Object.fromEntries(
      Object.entries(data).filter(([, value]) => value !== null && value !== undefined && value !== ''),
    ),
    locale,
  });
};

export const getEventById = ({
  locale,
  eventId,
  isLoggedIn,
}: {
  locale: string;
  eventId: string;
  isLoggedIn?: boolean;
}) => {
  const basePath = isLoggedIn ? '/service/v2' : '/guest/service/v2';

  return bossApi<event.Event[]>(`${basePath}/events?code=${eventId}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};
