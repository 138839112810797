import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { ComponentProps, InputHTMLAttributes, ReactNode } from 'react';
import { StringSchema } from 'yup';

import { useRouter } from '@boss/hooks';
import {
  CheckboxGroup,
  Counter,
  CounterList,
  DateField,
  FormFieldWrapper,
  InputAction,
  InputField,
  PhoneField,
  RadioGroup,
  SearchableSelect,
  Select,
  Textarea,
} from '@boss/ui';
import { removeEmojisFromString } from '@boss/utils';

import { FALLBACK_COUNTRY_MAP, HIGHLIGHTED_COUNTRY_CODES } from '../../../constants';
import { normalizePhonenumber, revertPhonenumberNotation } from '../../../utils';
import AddressBlock from '../../Form/AddressBlock';
import AddressSuggestion from '../../Form/AddressSuggestion';

type BaseProps = {
  required?: boolean;
  validation?: StringSchema;
  initialValue?: string | boolean;
  label?: ReactNode;
  labelClassName?: string;
  colStyle?: string; // e.g., 'col-span-6 md:col-span-3'
  errorLabel?: string;
  name: string;
  key?: string;
  validateName?: string;
  addressPrefix?: string;
};

/**
 * Omit the following values as these values are either props passed to the mapper or props managed by Formik
 **/
type OmittedValues =
  | 'onBlur'
  | 'onChange'
  | 'touched'
  | 'value'
  | 'label'
  | 'light'
  | 'selectedOption'
  | 'values'
  | 'children'
  | 'checked';

type GeneralizeType<T> = Omit<T, OmittedValues>;

type InputFieldProps = BaseProps &
  GeneralizeType<ComponentProps<typeof InputField>> & {
    type: 'input';
    validationType?: 'email' | 'VAT' | 'number' | 'password';
  };

type DateFieldProps = BaseProps &
  GeneralizeType<ComponentProps<typeof InputField>> & {
    type: 'date';
  };

type PhoneNumberFieldProps = BaseProps &
  GeneralizeType<ComponentProps<typeof PhoneField>> & {
    type: 'phone';
    validationType?: 'phone';
  };

type TextareaFieldProps = BaseProps &
  GeneralizeType<ComponentProps<typeof Textarea>> & {
    type: 'textarea';
  };

type SelectProps = BaseProps &
  GeneralizeType<ComponentProps<typeof Select>> & {
    type: 'select';
    value?: string;
  };

type CheckboxGroupProps = BaseProps &
  GeneralizeType<ComponentProps<typeof CheckboxGroup>> & {
    type: 'checkboxGroup';
  };

type CheckboxProps = BaseProps &
  GeneralizeType<ComponentProps<typeof InputAction>> & {
    type: 'checkbox';
  };

type RadioGroupProps = BaseProps &
  GeneralizeType<ComponentProps<typeof RadioGroup>> & {
    type: 'radioGroup';
  };

type SearchableSelectProps = BaseProps &
  GeneralizeType<ComponentProps<typeof SearchableSelect>> & {
    type: 'searchableSelect';
  };

type CounterProps = Omit<BaseProps, 'initialValue'> &
  GeneralizeType<ComponentProps<typeof Counter>> & {
    type: 'counter';
    initialValue?: number;
  };

type AddressSuggestionProps = BaseProps &
  GeneralizeType<ComponentProps<typeof AddressSuggestion>> & {
    type: 'addressSuggestion';
  };

export type AddressBlockProps = BaseProps &
  GeneralizeType<ComponentProps<typeof AddressSuggestion>> & {
    type: 'address';
    fields: FormField[];
    scope?: string;
  };

type CounterListProps = BaseProps &
  GeneralizeType<ComponentProps<typeof CounterList>> & {
    type: 'counterList';
    listTitle?: string;
  };

export type FormField =
  | SelectProps
  | InputFieldProps
  | TextareaFieldProps
  | SearchableSelectProps
  | PhoneNumberFieldProps
  | CheckboxGroupProps
  | CheckboxProps
  | RadioGroupProps
  | CounterProps
  | AddressSuggestionProps
  | AddressBlockProps
  | DateFieldProps
  | CounterListProps;

type Props = {
  field: FormField;
  formik: ReturnType<typeof useFormik>;
  className?: string;
  formId?: string;
  variant?: 'light' | 'dark' | 'transparent';
};

const FormFieldMapper = ({ formId, field, formik, variant, className }: Props) => {
  const { locale } = useRouter();
  const { t } = useTranslation('forms');
  const fieldName = field.name;
  const required = typeof field.required === 'undefined' ? true : !!field.required; // When required is undefined, make the field required
  const label = field.label ?? t(`fields.${field.key ?? field.name}`, { ns: 'forms' }) ?? '';
  const value = formik.values[fieldName] ?? '';
  const touched = !!formik.touched[fieldName];

  const errorValue = formik.errors?.[fieldName] ? formik.errors?.[fieldName] ?? field.errorLabel : undefined;

  const mappedError = errorValue && String(Array.isArray(errorValue) ? errorValue.join(', ') : errorValue);

  const error = touched ? mappedError : undefined;

  const onBlur = formik.handleBlur;
  const onChange = formik.handleChange;

  const defaultFieldProps = {
    className,
    error,
    label,
    variant,
    name: field.name,
    onBlur,
    onChange,
    touched,
    value,
    required,
    id: `${formId}-${field.name}`,
  };

  switch (field.type) {
    case 'input': {
      const fieldInputTypes: Record<
        Required<InputFieldProps>['validationType'],
        InputHTMLAttributes<HTMLInputElement>['type']
      > = {
        email: 'email',
        number: 'number',
        VAT: 'text',
        password: 'password',
      };

      // Exclude validationType from the field props to ensure it isn't passed to the input tag
      const { validationType, ...fieldProps } = field;

      return (
        <InputField
          {...defaultFieldProps}
          {...fieldProps}
          onChange={e => formik.setFieldValue(fieldName, removeEmojisFromString(e.target.value))}
          type={validationType ? fieldInputTypes[validationType] : 'text'}
        />
      );
    }
    case 'date':
      return (
        <DateField
          placeholder={field.placeholder ?? ''}
          {...field}
          {...defaultFieldProps}
          locale={locale}
          onChange={e => {
            formik.setFieldValue(fieldName, e);
          }}
          value={value}
        />
      );
    case 'phone':
      return (
        <PhoneField
          placeholder={field.placeholder ?? ''}
          {...defaultFieldProps}
          {...field}
          defaultCountry={field.defaultCountry ?? FALLBACK_COUNTRY_MAP[locale]}
          highlightedCountryCodes={HIGHLIGHTED_COUNTRY_CODES}
          onChange={phoneNumber => {
            formik.setFieldValue(fieldName, revertPhonenumberNotation(phoneNumber));
          }}
          value={normalizePhonenumber(value)}
        />
      );
    case 'textarea':
      return <Textarea {...field} {...defaultFieldProps} />;
    case 'select':
      return <Select {...field} {...defaultFieldProps} value={value} />;

    case 'checkbox':
      return (
        <InputAction
          {...field}
          {...defaultFieldProps}
          checked={formik.values[fieldName]}
          onChange={e => formik.setFieldValue(fieldName, e.target.checked)}
          type="checkbox"
          value={value}
        >
          {label}
        </InputAction>
      );

    case 'checkboxGroup':
      return (
        <CheckboxGroup
          {...field}
          {...defaultFieldProps}
          onChange={values => formik.setFieldValue(fieldName, values)}
          values={formik.values[fieldName]}
        />
      );

    case 'radioGroup':
      return (
        <RadioGroup {...field} {...defaultFieldProps} onChange={values => formik.setFieldValue(fieldName, values)} />
      );

    case 'searchableSelect':
      return (
        <SearchableSelect
          {...field}
          {...defaultFieldProps}
          onChange={option => formik.setFieldValue(fieldName, option.value)}
        />
      );

    case 'counter': {
      const { value, ...rest } = defaultFieldProps;

      return (
        <FormFieldWrapper {...field} {...rest}>
          <Counter onChange={value => formik.setFieldValue(fieldName, value)} value={value} />
        </FormFieldWrapper>
      );
    }

    case 'address': {
      const { ...rest } = defaultFieldProps;

      return (
        <FormFieldWrapper {...field} {...rest}>
          <AddressBlock formId={formId} {...field} formik={formik} name="addressBlock" testId="addressblock" />
        </FormFieldWrapper>
      );
    }

    case 'counterList': {
      const { ...rest } = defaultFieldProps;

      return (
        <FormFieldWrapper {...field} {...rest}>
          <CounterList
            error={error}
            listTitle={field.listTitle ?? ''}
            onChange={items => formik.setFieldValue(fieldName, items)}
            placeholder={field.placeholder ?? ''}
          />
        </FormFieldWrapper>
      );
    }

    default:
      return null;
  }
};

export default FormFieldMapper;
