import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ReactNode, useState } from 'react';

import { BasicVariant } from '@boss/types/b2b-b2c';

import Divider from '../Divider';
import PricingCard from '../ShoppingCart/PricingCard';

export interface Props {
  collapseContent?: ReactNode;
  buttonInformation?: string;
  buttonIcon?: IconDefinition;
  buttonTranslations: {
    visible: string;
    hidden: string;
  };
  variant?: BasicVariant;
  children: ReactNode;
  collapseInitialState?: boolean;
}

const ButtonStyles = cva('w-full text-left', {
  variants: {
    variant: {
      primary: 'border-t-1 border-b-1 border-gray py-2 mb-9.5 flex items-center',
      secondary: 'border-t-1 border-b-1 border-gray py-2 mb-10 mt-1 flex items-center',
    },
    isCollapseVisible: {
      true: '',
    },
  },
});

const CollapseStyles = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: 'px-6 pt-6',
    },
    isCollapseVisible: {
      true: '',
    },
  },
  compoundVariants: [
    {
      variant: ['primary', 'secondary'],
      isCollapseVisible: true,
      class: 'flex flex-col w-full gap-5',
    },
    {
      variant: ['primary', 'secondary'],
      isCollapseVisible: false,
      class: 'hidden',
    },
  ],
});

const IconStyles = cva('transition', {
  variants: {
    isCollapseVisible: {
      true: 'rotate-180',
    },
  },
});

const CardStyles = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: 'p-0',
    },
  },
});

const ChildrenStyles = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: 'bg-gray-100 px-6 pb-6',
    },
  },
});

const CartSummary = ({
  collapseContent,
  buttonInformation,
  buttonIcon,
  buttonTranslations,
  variant = 'primary',
  collapseInitialState,
  children,
}: Props) => {
  const [isCollapseVisible, setIsCollapseVisible] = useState(!!collapseInitialState);

  return (
    <div>
      <button
        className={ButtonStyles({ variant, isCollapseVisible })}
        onClick={() => setIsCollapseVisible(!isCollapseVisible)}
      >
        <div className="flex w-full gap-1">
          {isCollapseVisible ? <span>{buttonTranslations.hidden}</span> : <span>{buttonTranslations.visible}</span>}
          {buttonInformation && <span>{buttonInformation}</span>}
        </div>

        {buttonIcon && <FontAwesomeIcon className={IconStyles({ isCollapseVisible })} icon={buttonIcon} />}
      </button>
      <PricingCard className={CardStyles({ variant })} title="">
        <div className={CollapseStyles({ variant, isCollapseVisible })}>{collapseContent}</div>
        <Divider className="mt-3" />
        <div className={ChildrenStyles({ variant })}>{children}</div>
      </PricingCard>
    </div>
  );
};

export default CartSummary;
