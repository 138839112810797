import * as Sentry from '@sentry/nextjs';

import { getAlgolia } from '@boss/algolia-client';
import { color as colorService } from '@boss/services/client';
import { BossColor, Color, ISearchColor } from '@boss/types/b2b-b2c';

import { getColorSearchIndexName } from '../../utils';

const fetchAllColorGroups = async () => {
  try {
    return await colorService.getColorGroups();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch colorGroups',
      },
    });
  }
};

const fetchColorsByGroupCodeAndProductId = async ({
  locale,
  groupCode,
  productId,
  skuId,
}: {
  locale: string;
  groupCode?: string;
  productId?: string;
  skuId?: string;
}) => {
  try {
    let url = `/api/color/group-code?locale=${locale}`;

    if (groupCode) {
      url += `&groupCode=${groupCode}`;
    }

    if (productId) {
      url += `&productId=${productId}`;
    }

    if (skuId) {
      url += `&skuId=${skuId}`;
    }

    const colors = await fetch(url);

    return await colors.json();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch colors by groupCode',
      },
    });
  }
};

// todo: fix this plz
const fetchColorsByColorCodesAndProductId = async (locale: string, colorCodes?: string[], productId?: string) => {
  try {
    let url = `/api/color/color-code?locale=${locale}`;

    if (colorCodes?.length) {
      url += `&colorCodes=${colorCodes?.join(',')}`;
    }

    if (productId) {
      url += `&productId=${productId}`;
    }

    const colors = await fetch(url);

    return await colors.json();
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch colors by colorCode',
      },
    });
  }
};

/**
 * Fetch the given color by it's id
 */
const fetchRelatedColors = async ({ colorCode, locale }: { colorCode?: string; locale: string }) => {
  try {
    if (!colorCode) {
      return null;
    }
    const { searchIndex } = getAlgolia({ isShared: true, indexName: getColorSearchIndexName(locale) });

    if (!searchIndex) {
      return null;
    }
    const color = await searchIndex.getObject<ISearchColor>(colorCode);

    return color?.relatedColors;
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch related colors by color code',
      },
    });
  }
};

const fetchColorByColorId = async (
  colorId: string,
  locale: string,
  channel: string,
  showSamples = false,
): Promise<Color | undefined> => {
  try {
    const url = `/api/color/${colorId
      .replace(' ', '')
      .toLowerCase()}?locale=${locale}&channel=${channel}&showSamples=${showSamples}`;

    const response = await fetch(url);
    const color = await response.json();

    return colorService.mapColor(color);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e, {
      tags: {
        type: 'Fetch color by colorId',
      },
    });
  }
};

const fetchColorsByColorTypeGroup = async (
  base: string,
  productId: string,
  locale: string,
  showSamples = true,
): Promise<BossColor[]> => {
  const url = `/api/colors/byproductid?colortypegroup=${base}&productId=${productId}&showSamples=${showSamples}`;
  const colors = await fetch(url, {
    headers: {
      'Accept-Language': locale,
    },
  });

  return await colors.json();
};

const searchColor = async ({
  locale,
  value,
  productId,
  skuId,
  colortypegroup,
}: {
  locale: string;
  value?: string;
  productId?: string;
  skuId?: string;
  colortypegroup?: string;
}) => {
  let url = `/api/color/search?locale=${locale}`;

  if (value) {
    url += `&keyword=${value}`;
  }

  if (productId) {
    url += `&productId=${productId}`;
  }

  if (skuId) {
    url += `&skuId=${skuId}`;
  }

  if (colortypegroup) {
    url += `&colortypegroup=${colortypegroup}`;
  }

  const colors = await fetch(url);

  return await colors.json();
};

export {
  fetchColorByColorId,
  fetchColorsByColorCodesAndProductId,
  fetchColorsByColorTypeGroup,
  fetchAllColorGroups,
  fetchColorsByGroupCodeAndProductId,
  searchColor,
  fetchRelatedColors,
};
