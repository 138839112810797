import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { BasicVariant, ImageFields } from '@boss/types/b2b-b2c';

import Image from '../Image';
import Link from '../Link';

export interface Props {
  title: string;
  image?: ImageFields | null;
  footerContent?: ReactNode;
  footerAction?: ReactNode;
  children?: ReactNode;
  variant?: BasicVariant;
  href?: string;
  className?: string;
}

const CardStyles = cva('pb-4 bg-white shadow-lg flex flex-col content-between h-full', {
  variants: {
    variant: {
      primary: 'rounded-t-modal rounded-r-modal overflow-hidden ',
      secondary: '',
    },
    clickable: {
      true: 'cursor-pointer flex flex-col h-full',
    },
  },
});

const ContentStyles = cva('px-4 flex flex-col h-full', {
  variants: {
    variant: {
      primary: 'pt-6',
      secondary: 'pt-4',
    },
  },
});

const FooterStyles = cva('mt-auto grid gap-4 items-center px-4', {
  variants: {
    variant: {
      primary: '',
      secondary: 'mt-4',
    },
    hasContent: {
      true: '',
    },
    hasAction: {
      true: '',
    },
  },
  compoundVariants: [
    {
      variant: ['primary', 'secondary'],
      hasAction: true,
      hasContent: true,
      class: '',
    },
    {
      variant: ['primary', 'secondary'],
      hasAction: false,
      hasContent: true,
      class: '',
    },
    {
      variant: ['primary', 'secondary'],
      hasAction: true,
      hasContent: false,
      class: '',
    },
  ],
});

const ChildrenStyles = cva('', {
  variants: {
    variant: {
      primary: 'text-gray-dark',
      secondary: '',
    },
  },
});

const EventCard = ({
  title,
  image,
  footerContent,
  footerAction,
  variant = 'primary',
  children,
  className,
  href,
}: Props) => {
  const Tag = href ? Link : 'div';

  return (
    <div className={twMerge(CardStyles({ variant, clickable: !!href }), className)}>
      <Tag data-testid="event-card" href={href}>
        {image ? (
          <div className="aspect-[16/9]">
            <Image alt={title} className="object-cover" fill wrapperClassName="aspect-[16/9]" {...image} />
          </div>
        ) : (
          <div className="aspect-[16/9] bg-gray-200" />
        )}
        <div className={ContentStyles({ variant })}>
          <h4>{title}</h4>
          {children && <div className={ChildrenStyles({ variant })}>{children}</div>}
        </div>
      </Tag>
      {(footerContent || footerAction) && (
        <div className={FooterStyles({ variant, hasContent: !!footerContent, hasAction: !!footerAction })}>
          {footerContent && <div className="flex items-center md:col-span-2">{footerContent}</div>}
          {footerAction && <div className="md:col-span-3 xl:col-span-2">{footerAction}</div>}
        </div>
      )}
    </div>
  );
};

export default EventCard;
