import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ImageProps } from '@boss/types/b2b-b2c';

import Image from '../Image';

type Props = {
  message?: MessageObject;
  image?: ImageProps;
  variant: 'primary' | 'secondary';
};

type MessageObject = {
  text?: ReactNode;
};

const wrapperLayout = cva('grid grid-cols-5  w-full  text-white h-full', {
  variants: {
    variant: {
      primary: 'rounded-tl-md rounded-b-md md:rounded-tl-lg md:rounded-b-lg bg-gray-storm',
      secondary: 'relative',
    },
  },
});
const imageWrapper = cva('col-span-2', {
  variants: {
    variant: {
      primary: '',
      secondary: 'relative w-full h-full',
    },
  },
});
const imageLayout = cva('', {
  variants: {
    variant: {
      primary: 'rounded-br-md',
      secondary: '',
    },
  },
});
const textWrapper = cva('', {
  variants: {
    variant: {
      primary: ' py-12 px-8 h1 flex',
      secondary: 'relative w-full h-full',
    },
    hasImage: {
      true: 'col-span-3',
      false: 'col-span-5',
    },
  },
  defaultVariants: {
    hasImage: true,
  },
});

const text = cva('h4 break-words', {
  variants: {
    variant: {
      primary: 'self-center',
      secondary: 'top-1/2 left-6 right-6 absolute transform -translate-y-1/2 uppercase',
    },
  },
});

const bgImageStyle = cva('w-full h-full object-cover object-left bg-no-repeat', {
  variants: {
    variant: {
      primary: 'hidden',
      secondary: '',
    },
  },
});
const SpecialMessageNotice = ({ message, image, variant }: Props) => {
  return (
    <>
      {message && (
        <div className={twMerge(wrapperLayout({ variant }))}>
          <div className={twMerge(textWrapper({ variant, hasImage: !!image }))}>
            <Image
              className={twMerge(bgImageStyle({ variant }))}
              height={391}
              src="/stripes/stripe_blue.png"
              useNext
              width={1984}
            />
            <span className={twMerge(text({ variant }))}>{message.text}</span>
          </div>
          {image && (
            <div className={twMerge(imageWrapper({ variant }))}>
              <Image {...image} className={twMerge(imageLayout({ variant }))} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default SpecialMessageNotice;
