import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { trackEvents } from '@boss/constants/shared';

import BoldTextPart from '../BoldTextPart';
import Image from '../Image';
import Link from '../Link';

interface ProductResult {
  id: string;
  name: string;
  price?: number;
  image?: {
    url: string;
    alt?: string;
  };
  slug: string;
}

interface PageResult {
  id: string;
  label: string;
  slug: string;
}

interface SuggestionResult {
  label: string;
  slug: string;
}

interface ProductResults {
  type: 'product';
  results: ProductResult[];
  title: string;
}

interface PageResults {
  type: 'page';
  results: PageResult[];
  title: string;
}

interface SuggestionResults {
  type: 'suggestion';
  results: SuggestionResult[];
  title?: string;
}

type SearchResult = ProductResults | PageResults | SuggestionResults;

export type SearchResults = SearchResult[];
export interface Props {
  results: SearchResults;
  query?: string;
}

const commonLinkStyle = cva('py-2 flex', {
  variants: {
    variant: {
      product: 'hover:bg-gray-lightest',
      default: 'hover:text-black',
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

const SearchResultItems = ({ result, query }: { result: SearchResult; query?: string }) => {
  switch (result.type) {
    case 'suggestion':
      return (
        <div>
          {result.results.map((page, index) => (
            <Link
              className={commonLinkStyle()}
              href={page.slug}
              key={page.label}
              role="option"
              trackInfo={{
                searchIndex: 'suggestion',
                customEvent: trackEvents.SEARCH_RESULT_SELECTED,
                searchQuery: query ?? '',
                resultName: page.label,
                searchPosition: index + 1,
              }}
            >
              <BoldTextPart boldText={query ?? ''} label={page.label} />
            </Link>
          ))}
        </div>
      );

    case 'product':
      return (
        <div>
          {result.results.map((product, index) => (
            <Link
              className={twMerge(commonLinkStyle({ variant: 'product' }), 'gap-3')}
              href={`${product.slug}?search=true&query=${query}`}
              key={product.id}
              role="option"
              trackInfo={{
                searchIndex: 'product',
                customEvent: trackEvents.SEARCH_RESULT_SELECTED,
                searchPosition: index + 1,
                searchQuery: query ?? '',
                resultName: product.name,
              }}
            >
              <div className="relative mx-2 h-12 w-12">
                <Image
                  alt={product.image?.alt}
                  className="object-contain"
                  height={30}
                  resizingBehavior="fit"
                  src={product.image?.url}
                  useNext
                  width={30}
                />
              </div>
              <div>
                <div className="font-bold">{product.name}</div>
                {product.price && <div className="text-sm">€ {product.price}</div>}
              </div>
            </Link>
          ))}
        </div>
      );

    case 'page':
      return (
        <div>
          {result.results.map((page, index) => (
            <Link
              className={twMerge(commonLinkStyle(), 'underline')}
              href={page.slug}
              key={page.id}
              role="option"
              trackInfo={{
                searchIndex: 'content',
                customEvent: trackEvents.SEARCH_RESULT_SELECTED,
                searchQuery: query ?? '',
                resultName: page.label,
                searchPosition: index + 1,
              }}
            >
              {page.label}
            </Link>
          ))}
        </div>
      );

    default:
      return null;
  }
};

const SearchMenu = ({ results, query }: Props) => {
  const resultCount = results.reduce((acc, result) => acc + result.results.length, 0);

  return (
    <div aria-label={`${resultCount} search results found for ${query}`} role="listbox">
      {results.map(result => (
        <div
          aria-label={`${result.results.length} results for ${result.title ?? 'search suggestions'}`}
          className="my-3 py-1 [&:not(:last-child)]:border-b-2"
          key={result.title}
          role="list"
        >
          <div className="mb-2 font-bold">{result.title}</div>
          <SearchResultItems query={query} result={result} />
        </div>
      ))}
    </div>
  );
};

export default SearchMenu;
