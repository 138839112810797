import { Inline } from '@contentful/rich-text-types';

import { CommonProps } from '@boss/types/b2b-b2c';
import { TextLink } from '@boss/ui';

type propsType = CommonProps & {
  hyperlink: Inline;
};

const InlineHyperlink = ({ hyperlink, className }: propsType) => {
  const { uri } = hyperlink.data;
  let value;

  if ('value' in hyperlink.content[0]) {
    value = hyperlink.content[0].value;
  }

  if (!uri || !value) {
    return null;
  }

  return (
    <TextLink className={className} href={uri}>
      {value}
    </TextLink>
  );
};

export default InlineHyperlink;
