export * from './queries';

export const colorKeys = {
  allColorGroups: (locale: string) => ['allColorGroups', locale],
  colorsByGroupCode: ({
    locale,
    code,
    productId,
    skuId,
  }: {
    locale: string;
    code?: string;
    productId?: string;
    skuId?: string;
  }) => ['colorsByGroupCode', locale, code, productId, skuId],
  colorsByColorCodes: (locale: string, codes: string[], productId?: string) => [
    'colorsByColorCodes',
    locale,
    productId,
    ...codes,
  ],
  colorByColorId: (locale: string, id: string, showSamples = false) => ['colorByColorId', locale, id, showSamples],
  colorSearch: ({
    locale,
    value,
    productId,
    skuId,
    colortypegroup,
  }: {
    locale: string;
    value?: string;
    productId?: string;
    skuId?: string;
    colortypegroup?: string;
  }) => ['colorSearch', locale, value, productId, skuId, colortypegroup],
  colortypeGroup: (base: string, locale: string, productId?: string, showSamples?: boolean) => [
    'colortypeGroup',
    base,
    productId,
    locale,
    showSamples,
  ],
  relatedColors: (locale: string, code?: string) => ['relatedColors', locale, code],
};
