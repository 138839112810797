import { faChevronLeft, faChevronRight, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useState } from 'react';

import { NavigationItem } from '@boss/types/b2b-b2c';

import { Link } from '../../../..';

type MenuItemProps = {
  link: NavigationItem;
  iconPosition?: 'before' | 'after';
  showIcon?: boolean;
};

const MenuItemStyles = cva('flex gap-2.5', {
  variants: {
    iconPosition: {
      before: 'flex-row-reverse w-fit',
      after: 'justify-between',
    },
    active: {
      true: 'text-blue font-medium flex-row-reverse w-fit items-center',
    },
  },
});

const MenuItem = ({ link, iconPosition = 'after', showIcon }: MenuItemProps) => {
  const [activeLink, setActiveLink] = useState<NavigationItem | null>(null);

  const active = link.id === activeLink?.id;
  const hasChildren = !!link.childNavigationItems;

  const handleLink = (link: NavigationItem) => {
    if (link && link !== activeLink) {
      return setActiveLink(link);
    }

    setActiveLink(null);
  };

  if (!link.label) {
    return null;
  }

  return (
    <div className="text-brown-dark relative flex flex-col">
      <Link
        className={MenuItemStyles({ iconPosition, active })}
        href={hasChildren ? null : link.href}
        onClick={() => handleLink(link)}
      >
        {link.label}
        {!active && showIcon && <FontAwesomeIcon icon={iconPosition === 'before' ? faChevronLeft : faChevronRight} />}
        {active && <FontAwesomeIcon icon={faChevronUp} />}
      </Link>
      {active && link.childNavigationItems && (
        <div className="border-l-1 border-blue/10 mt-6 flex flex-col gap-6 px-3">
          {link.childNavigationItems.map(link => {
            const hasChildren = !!link.childNavigationItems;

            return (
              <MenuItem
                key={link.id ?? `${link.href ?? 'main-link'}-${link.label}`}
                link={link}
                showIcon={hasChildren}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
