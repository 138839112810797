import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Locale, format } from 'date-fns';
import { fr, nl } from 'date-fns/locale';
import { useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { twMerge } from 'tailwind-merge';

import 'react-day-picker/dist/style.css';
import Popper from '../Popper';

interface PropsBase {
  value: Date | string | undefined;
  className?: string;
  language?: string;
  inputClassName?: string;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  locale?: string;
}

interface PropsWithIsoFormatTrue extends PropsBase {
  isoFormat: true;
  onChange: (val: string) => void;
}

interface PropsWithIsoFormatFalse extends PropsBase {
  isoFormat?: false;
  onChange: (val: Date) => void;
}

type Props = PropsWithIsoFormatTrue | PropsWithIsoFormatFalse;

const mapLocaleToDateFnsLocale = (locale?: string): Locale => {
  if (locale === 'fr') {
    return fr;
  }
  if (locale === 'nl') {
    return nl;
  }
  return nl;
};

/**
 * Uses the browser's native datepicker.
 *
 * If this component somehow lacks desired functionality or layout,
 * consider looking into https://react-day-picker.js.org/. This one can quickly be implemented by using the <Popper/> component.
 **/
const Datepicker = ({
  value,
  className,
  inputClassName,
  isoFormat = false,
  onChange,
  minDate,
  maxDate,
  placeholder,
  locale,
}: Props) => {
  const incomingValue = value ? new Date(value) : new Date();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={twMerge('relative', className)}>
      <Popper
        button={
          <button
            className={twMerge(
              'border-1 border-gray-light text-textColor flex min-h-9 w-full items-center justify-between gap-2 rounded-full px-6 py-1',
              inputClassName,
            )}
            type="button"
          >
            {value && <span>{format(incomingValue, 'dd/MM/yyyy')}</span>}
            {!value && placeholder && <span className="text-gray-300">{placeholder}</span>}
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        }
        floating={
          <div className="z-nav bg-white shadow-lg">
            <DayPicker
              ISOWeek
              fromDate={minDate ?? undefined}
              locale={mapLocaleToDateFnsLocale(locale)}
              mode="single"
              onSelect={e => {
                if (e) {
                  setIsOpen(false);

                  if (isoFormat) {
                    // Set to midday to avoid annoying timezone issues
                    e.setHours(12, 0, 0, 0);
                    // TypeScript is to dumb to infer the onChange value based on isoformat prop, hence casting
                    (onChange as (val: string) => void)(e.toISOString());
                  } else {
                    // TypeScript is to dumb to infer the onChange value based on isoformat prop, hence casting
                    (onChange as (val: Date) => void)(e);
                  }
                }
              }}
              selected={value ? new Date(value) : undefined}
              toDate={maxDate}
            />
          </div>
        }
        isOpen={isOpen}
        offset={0}
        onChangeOpenStatus={setIsOpen}
        placement="bottom"
      ></Popper>
    </div>
  );
};

export default Datepicker;
