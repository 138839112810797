import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { Cta } from '@boss/types/b2b-b2c';
import { getLink } from '@boss/utils';

import MobileMenu from './MobileMenu';
import {
  Backdrop,
  Button,
  Container,
  Cta as CtaComponent,
  LocaleSelector,
  Logo,
  MainNavInstanceProps,
  Portal,
  SearchBar,
  SubNav,
} from '../../';
import { SecondaryLink, ShoppingBasket, UniqueLink } from '../MenuLinks';

const MainNavPrimary = ({
  className,
  closeMenu,
  favorite,
  basket,
  handleMobileMenuClick,
  highlightedCta,
  isOpen,
  localizedSlugs,
  logo,
  extraNav,
  mainNav,
  onSearch,
  searchValue,
  setSearchValue,
  personal,
  searchLabel,
  secondaryNavItems,
  translations,
  type = 'default',
  searchResults,
  onCloseSearchMenu,
  suggestions,
  showSearchSuggestions,
  onShowSearchSuggestions,
}: MainNavInstanceProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { locale } = useRouter();

  const handleOpenMenu = (hoverState: boolean) => setMenuIsOpen(hoverState);

  const getSubLinks = () => {
    const baseLinks = [...(secondaryNavItems ?? []), personal, favorite, basket];

    if (highlightedCta) {
      baseLinks.push(highlightedCta);
    }

    return baseLinks;
  };

  const getUrl = (navItem: Cta) => {
    if (navItem.internalLink) {
      return getLink(navItem.internalLink, locale);
    }

    if (navItem.externalLink) {
      return navItem.externalLink;
    }
  };

  if (type === 'simple') {
    return (
      <nav className={twMerge('relative z-20 bg-white py-5 shadow-sm', className)}>
        <Container>{logo && <Logo alt={logo.imageAlt} href={logo.href} src={logo.imageUrl} />}</Container>
      </nav>
    );
  }

  return (
    <>
      <nav className={twMerge('relative z-20 bg-white shadow-sm', className)}>
        {isOpen && (
          <Backdrop className="md:hidden" onClick={closeMenu} visible>
            {/* FIXME
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error Portal component type definition needs to be updated to handle children prop correctly */}
            <Portal>
              <MobileMenu
                className="z-nav fixed left-0 top-0 w-full md:hidden"
                extraNavigation={extraNav?.map((link, index) => (
                  <CtaComponent
                    className="text-base"
                    key={index}
                    {...link}
                    href={getUrl(link)}
                    icon={undefined}
                    type="link"
                  />
                ))}
                links={mainNav}
                localizedSlugs={localizedSlugs}
                logo={logo ?? undefined}
                onClose={closeMenu}
                onCloseSearchMenu={onCloseSearchMenu}
                onSearch={onSearch}
                searchLabel={searchLabel}
                searchResults={searchResults}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                subLinks={getSubLinks()}
                translations={translations}
              />
            </Portal>
          </Backdrop>
        )}
        {extraNav?.length && (
          <Container className="hidden justify-end gap-4 pb-2 pt-2 md:flex">
            {extraNav?.map((link, index) => (
              <CtaComponent key={index} {...link} href={getUrl(link)} icon={undefined} type="link" />
            ))}
          </Container>
        )}
        <div className={extraNav?.length ? '' : 'pt-5'}>
          <Container className={`flex flex-wrap items-center pb-3 md:flex-nowrap md:pb-0`}>
            <button aria-label="hamburger-button" className="mr-2.5 md:hidden" onClick={handleMobileMenuClick}>
              <FontAwesomeIcon icon={faBars} size="xl" />
            </button>
            {logo && <Logo alt={logo.imageAlt} className="flex md:mr-12" href={logo.href} src={logo.imageUrl} />}
            <SearchBar
              className="md:max-w-110 mt-4 md:mr-4 md:mt-0"
              onCloseSearchMenu={onCloseSearchMenu}
              onSearch={onSearch}
              onShowSearchSuggestions={onShowSearchSuggestions}
              searchLabel={searchLabel}
              searchResults={searchResults}
              setSearchValue={setSearchValue}
              showSearchSuggestions={showSearchSuggestions}
              suggestions={suggestions}
              type="primary"
              value={searchValue}
            />
            <div className="ml-auto flex items-center gap-4 pl-2 md:order-last">
              <div className="flex items-center gap-4">
                {secondaryNavItems?.map(
                  (link, index) =>
                    link.href && <SecondaryLink {...link} key={`${link.id}-${index}`} slug={link.href} />,
                )}

                {personal && (
                  <UniqueLink
                    icon={personal.icon}
                    iconClass="text-base"
                    label={personal.label}
                    onClick={personal.onClick}
                    slug={personal.href}
                  />
                )}
              </div>

              <div className="flex items-center gap-4">
                {favorite.onClick && (
                  <UniqueLink
                    ariaLabel={`${favorite.label} link`}
                    icon={favorite?.icon}
                    onClick={favorite?.onClick}
                    slug={favorite.href}
                  />
                )}
                {basket?.onClick && <ShoppingBasket current={basket.amount} onClick={basket.onClick} type="primary" />}
              </div>
              <LocaleSelector className="text-brown-dark hidden md:block" localizedSlugs={localizedSlugs} />
            </div>
          </Container>
        </div>

        <Container className="mt-7.5 relative hidden content-between items-end justify-between md:flex">
          {mainNav && (
            <SubNav nav={mainNav} onCloseMenu={() => setMenuIsOpen(false)} onOpenMenu={handleOpenMenu} type="primary" />
          )}
          {highlightedCta && (
            <Button className="mb-4" href={highlightedCta.href} label={highlightedCta.label} type="tertiary" />
          )}
        </Container>
      </nav>

      <Backdrop onClick={() => setMenuIsOpen(false)} visible={menuIsOpen} />
    </>
  );
};

export default MainNavPrimary;
