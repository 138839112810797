import { useAccountPriceInfoBySkuId } from '../../client-queries';

export const useArticleAccountPrice = ({
  articleId,
  price,
  enabled,
  unit = 'l',
}: {
  articleId: string;
  price?: number;
  enabled?: boolean;
  unit?: string;
}) => {
  const { data, isFetching } = useAccountPriceInfoBySkuId(articleId, 1, unit, enabled);

  const { netunitprice, grossunitprice } = data?.price || {};

  // Get the price to display, prioritizing net price from account info, then regular price from account info,
  // and falling back to the provided price prop
  const priceToShow = netunitprice || grossunitprice || price;

  // Show strike price only when there is a net price that differs from the regular price
  const strikePrice = netunitprice && grossunitprice !== netunitprice ? grossunitprice : undefined;

  return { priceToShow, strikePrice, isLoading: isFetching };
};

export default useArticleAccountPrice;
