import { useAccountPriceInfoByProductId } from '../../client-queries';

export const useProductAccountPrice = ({
  productId,
  price,
  enabled = true,
}: {
  productId: string;
  price?: number;
  enabled?: boolean;
}) => {
  const { data, isFetching } = useAccountPriceInfoByProductId(productId, enabled);

  const { fromPriceAmount, fromPriceAmountNet, fromPriceUnit } = data?.prices || {};

  // Get the price to display, prioritizing net price from account info, then regular price from account info,
  // and falling back to the provided price prop
  const priceToShow = fromPriceAmountNet || fromPriceAmount || price;

  // Show strike price only when there is a net price that differs from the regular price
  const strikePrice = fromPriceAmountNet && fromPriceAmount !== fromPriceAmountNet ? fromPriceAmount : undefined;

  return { priceToShow, fromPriceUnit, strikePrice, isLoading: isFetching, productLabels: data?.labels };
};

export default useProductAccountPrice;
