import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IColorGroup } from '@boss/services';
import { SimpleColor } from '@boss/types/b2b-b2c';
import { Button, ColorPicker } from '@boss/ui';

import { ColorPickerModal } from '../../components';
import { useProductColor } from '../../hooks';

type Props = {
  preSelectedColor?: string | undefined;
  colorGroups: IColorGroup[];
  onNextClick: (chosenColor: string, colorBase?: string) => void;
  previousButton: JSX.Element;
  buttonClassName?: string;
};

const PaintguideColorPicker = ({
  preSelectedColor,
  colorGroups,
  onNextClick,
  previousButton,
  buttonClassName,
}: Props) => {
  const { t } = useTranslation('paintguide');
  const [colorBase, setColorBase] = useState('');

  const {
    colorType,
    handleColorGroupClick,
    onColorClick,
    searchValue,
    selectedColor,
    setSearchValue,
    searchedValue,
    setSelectedColorGroup,
    handleProductPanelSearch,
    showColorModal,
    selectedColorGroup,
    setShowColorModal,
    setSelectedColor,
    showColors,
    favoriteColors,
  } = useProductColor({ initialColor: preSelectedColor, isPaint: true, showBaseColors: false });

  const _setSelectedColor = (color: SimpleColor, colorBase?: string) => {
    setSelectedColor(color);
    setColorBase(colorBase ?? '');
  };

  return (
    <>
      <ColorPicker
        colorType={colorType}
        colors={colorGroups}
        favoriteColors={favoriteColors}
        onChangeGroupClick={handleColorGroupClick}
        onColorClick={onColorClick}
        onSearch={handleProductPanelSearch}
        searchValue={searchValue}
        selectedColor={selectedColor}
        setSearchValue={setSearchValue}
        translations={t('productPanel.colorPicker', { ns: 'product', returnObjects: true })}
        variant="fullwidth"
      />

      <div className="mt-15 flex w-full justify-between gap-4">
        {previousButton}

        <Button
          className={buttonClassName}
          icon={faArrowRight}
          iconPosition="right"
          label={t('next')}
          onClick={() => onNextClick(selectedColor?.id ?? '', colorBase)}
          type="primary"
        />
      </div>

      {showColors && showColorModal && (
        <ColorPickerModal
          colorGroups={colorGroups}
          colorType={colorType}
          favoriteColors={favoriteColors}
          initialColorGroup={selectedColorGroup}
          initialSearchValue={searchedValue}
          onClose={() => setShowColorModal(false)}
          selectedColor={selectedColor}
          setSelectedColor={_setSelectedColor}
          setSelectedColorGroup={setSelectedColorGroup}
        />
      )}
    </>
  );
};

export default PaintguideColorPicker;
