import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { ComponentProps, ReactNode, forwardRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRouter } from '@boss/hooks';
import { IEnrichedQuestion } from '@boss/services';
import { BasicVariant, OverviewFields } from '@boss/types/b2b-b2c';
import { Container, Presence, Stepper } from '@boss/ui';

import { InfoBox } from '..';
import { ComponentMapper } from '../Mappers';

type Steps = ComponentProps<typeof Stepper>['steps'];

type Props = {
  handleStepChange: (step: number) => void;
  variant: BasicVariant;
  currentStep: number;
  children: ReactNode;
  steps: Steps;
  visible: boolean;
  id: string;
  paintguideOverviewPage: OverviewFields | null;
  onlyRouting?: boolean;
  disableLastStep?: boolean;
  showInfoBox?: boolean;
  onCloseInfoBox?: () => void;
  enrichedQuestion?: IEnrichedQuestion;
};

const StepperWrapperStyles = cva('', {
  variants: {
    stepperVariant: { primary: 'bg-generic-blue-light pb-1 md:pb-15 mb-10 mx-[calc(50%-50vw)]', secondary: 'mb-17.5' },
  },
});

const PaintGuideWrapper = forwardRef<HTMLDivElement, Props>(function PaintGuideWrapper(
  {
    handleStepChange,
    onlyRouting = false,
    steps,
    currentStep,
    children,
    variant,
    id,
    visible,
    disableLastStep,
    paintguideOverviewPage,
    showInfoBox,
    onCloseInfoBox,
    enrichedQuestion,
  }: Props,
  stepperRef,
) {
  const { t } = useTranslation('paintguide');
  const { locale } = useRouter();
  const [openTitleInfo, setOpenTitleInfo] = useState(false);

  return (
    <Presence id={id} visible={visible}>
      <div className="mb-10">
        <div className={StepperWrapperStyles({ stepperVariant: variant })}>
          <h1 className="mb-10 w-full text-center">{t('paintguideLabel')}</h1>

          <Container>
            {paintguideOverviewPage?.prefixSections && (
              <div className="mb-10 md:mb-20">
                {paintguideOverviewPage.prefixSections.map(entry => (
                  <ComponentMapper entry={entry} key={entry.id} locale={locale} />
                ))}
              </div>
            )}
            <div className="scroll-m-30" ref={stepperRef}></div>
            <Stepper
              currentlyActive={currentStep}
              disableLastStep={disableLastStep}
              infoText={t('infoText') ?? undefined}
              mobileButtonText={t('mobileButtonText')}
              mobileTitle={t('mobileTitle')}
              noSkipQuestions={variant === 'primary'}
              onClick={step => handleStepChange(step)}
              onCloseInfoBox={onCloseInfoBox}
              onlyRouting={onlyRouting}
              showInfoBox={showInfoBox}
              steps={steps}
              translate={({ step, steps }) => t('stepIndicator', { step, steps })}
              variant={variant}
            />
          </Container>
        </div>
        {enrichedQuestion && (
          <div className="flex flex-col items-center justify-center">
            <h2 className="relative mb-10 mt-5 text-center md:mb-20 md:mt-10">
              {enrichedQuestion.title}
              {enrichedQuestion.extraInfo && (
                <button
                  aria-label="tooltip-button"
                  className="absolute -right-4 top-0 inline-flex pl-1"
                  onClick={() => setOpenTitleInfo(!openTitleInfo)}
                  type="button"
                >
                  <FontAwesomeIcon className="text-blue-dark text-base" icon={faCircleInfo} />
                </button>
              )}
            </h2>

            {openTitleInfo && <InfoBox className="mb-10 md:mb-20" info={enrichedQuestion.extraInfo} locale={locale} />}
          </div>
        )}
        {children}
      </div>

      {paintguideOverviewPage?.suffixSections && (
        <div className="mt-10 md:mt-20">
          {paintguideOverviewPage.suffixSections.map(entry => (
            <ComponentMapper entry={entry} key={entry.id} locale={locale} />
          ))}
        </div>
      )}
    </Presence>
  );
});

export default PaintGuideWrapper;
