import { useTranslation } from 'next-i18next';
import { twMerge } from 'tailwind-merge';

import { Image } from '@boss/ui';
import { isInstanceOfImage } from '@boss/utils';

import { SectionMapperProps } from '..';
import { storeFinderVariant } from '../../../../utils/options';
import StoreFinder from '../../../StoreFinder';

const ColoraStoreFinder = ({ entry, className }: SectionMapperProps) => {
  const { t } = useTranslation(['stores', 'routes']);
  const imageContent = entry.content.find(item => item.__typename === 'image');

  return (
    <>
      {isInstanceOfImage(imageContent) && (
        <div className={twMerge('lg:col-span-2', className)}>
          <Image
            alt={imageContent.altText}
            className="rounded-l-30 rounded-br-30 xl:rounded-l-50 xl:rounded-br-50 h-[22em] rounded-tr-none"
            image={imageContent.image}
            width={500}
          />
        </div>
      )}
      <div className="lg:col-span-3">
        <StoreFinder
          overviewUrl={t('verkooppunten', { ns: 'routes' })}
          redirect
          translations={t('storeFinder', { ns: 'stores', returnObjects: true })}
          variant={storeFinderVariant}
        />
      </div>
    </>
  );
};

export default ColoraStoreFinder;
