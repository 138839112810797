import { Nullable } from 'vitest';

import * as bossClient from '@boss/boss-client';

import { IWishlist, IWishlistLine, IWishlists } from './types';

/**
 * Fetches all wishlist items for an account
 */
export const all = async (accountId: string, locale: string) => {
  const lists = (await bossClient.wishlist.getAllWishlists(accountId, locale)) || [];

  return lists.reduce((acc, list) => {
    const type = list.type;

    if (type === 'sku' || type === 'product') {
      if (acc[type]?.length) {
        acc[type].push(list);
      } else {
        acc[type] = [list];
      }
    } else if (type === 'favoritecolor') {
      // Filter out lines without valid color object
      acc[type] = {
        ...list,
        lines: list.lines?.filter(line => line.color && line.color.colorcode && line.color.rgb) || [],
      };
    } else {
      acc[type] = list;
    }
    return acc;
  }, {} as IWishlists);
};

/**
 * Create a new wishlist
 */
export const create = async ({
  wishlist,
  locale,
}: {
  wishlist: IWishlist;
  locale: string;
}): Promise<Nullable<IWishlist>> => {
  return await bossClient.wishlist.createWishlist({ wishlist, locale });
};

/**
 * Delete a wishlist
 */
export const deleteWishlist = async ({ wishlistId, locale }: { wishlistId: string; locale: string }) => {
  return await bossClient.wishlist.deleteWishlist({ wishlistId, locale });
};

/**
 * Update a wishlist
 */
export const update = async ({ wishlist, locale }: { wishlist: IWishlist; locale: string }) => {
  return await bossClient.wishlist.updateWishlist({ wishlist, locale });
};

/**
 * Create a wishlistLine
 */
export const createLine = async ({
  wishlistLine,
  wishlistId,
  locale,
}: {
  wishlistLine: IWishlistLine;
  wishlistId: string;
  locale: string;
}): Promise<Nullable<IWishlistLine>> => {
  return await bossClient.wishlist.createWishlistLine({
    wishlistLine,
    wishlistId,
    locale,
  });
};

/**
 * Create multiple wishlistLines
 */
export const createLines = async ({
  wishlistLines,
  wishlistId,
  locale,
}: {
  wishlistLines: IWishlistLine[];
  wishlistId: string;
  locale: string;
}): Promise<Nullable<IWishlistLine>[]> => {
  return await Promise.all(
    wishlistLines.map(wishlistLine => bossClient.wishlist.createWishlistLine({ wishlistLine, wishlistId, locale })),
  );
};

/**
 * Delete a wishlistLine
 */
export const deleteLine = async ({
  wishlistLineId,
  wishlistId,
  locale,
}: {
  wishlistLineId: string;
  wishlistId: string;
  locale: string;
}) => {
  return await bossClient.wishlist.deleteWishlistLine({ wishlistLineId, wishlistId, locale });
};

/**
 * Update a wishlistLine
 */
export const updateLine = async ({
  wishlistLine,
  wishlistId,
  locale,
}: {
  wishlistLine: IWishlistLine;
  wishlistId: string;
  locale: string;
}): Promise<Nullable<IWishlistLine>> => {
  return await bossClient.wishlist.updateWishlistLine({ wishlistLine, wishlistId, locale });
};
