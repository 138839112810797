import { Fragment } from 'react';

import { Image, Link } from '../';
import DesktopNavigationItem from './components/DesktopNavigationItem';
import FooterNavItem from './components/FooterNavItem';
import FooterWrapper from './components/FooterWrapper';
import LegalFootnotes from './components/LegalFootNotes';
import MobileNavigationItem from './components/MobileNavigationItem';

import { FooterProps } from '.';

const Footer = ({ navigationItems, legalLinks, logo, translations }: FooterProps) => (
  <FooterWrapper className="bg-footer-primary break-before before:bg-footer-primary break-after after:bg-footer-primary relative">
    {logo && (
      <div className="pt-16.5 w-fit md:pt-12">
        <Link href="/">
          <Image alt={logo.imageAlt ?? 'Boss paints Logo'} className="h-auto w-40 object-contain" src={logo.imageUrl} />
        </Link>
      </div>
    )}
    <nav className="pt-16.5 w-full pb-11 lg:py-12">
      <ul className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-5 sm:gap-x-6">
        {navigationItems?.map((navItem, i) => (
          <Fragment key={`${navItem.id}-${i}`}>
            <DesktopNavigationItem
              itemClassName="md:block"
              labelClassName="min-h-0"
              mobileText={true}
              navItem={navItem}
            />
            <MobileNavigationItem
              boldLabel
              isCollapsible={false}
              labelClassName="body"
              liClassName={'md:hidden'}
              linkClassName="text-white body"
              mobileText={true}
              navItem={navItem}
            />
          </Fragment>
        ))}
      </ul>
    </nav>
    <div className="text-white">
      <div className="border-t-1 g-x-4 border-whiteFaded flex flex-col border-solid py-6 sm:gap-x-6  md:flex-row ">
        <LegalFootnotes>
          <div className="flex flex-col justify-between gap-3 md:flex-row">
            {legalLinks?.map((link, i) => {
              return (
                <li className="inline first:ml-0 md:ml-2.5" key={`${link.id}-${i}`}>
                  <FooterNavItem className="opacity-0.5 text-white hover:underline hover:opacity-100" link={link.href}>
                    {link.label}
                  </FooterNavItem>
                </li>
              );
            })}
            <br className="md:hidden" />
            <FooterNavItem className="opacity-0.5 ml-0 md:ml-2.5">
              {translations?.brand} {new Date().getFullYear()}
            </FooterNavItem>
          </div>
        </LegalFootnotes>
      </div>
    </div>
  </FooterWrapper>
);

export default Footer;
