import { IconDefinition } from '@fortawesome/free-brands-svg-icons';
import { twMerge } from 'tailwind-merge';

import Button from '../Button';

type CtaBannerProps = {
  className?: string;
  prefix?: string;
  title: string;
  description: string;
  cta: {
    label: string;
    icon: IconDefinition;
    href: string;
  };
};

const CtaBanner = ({ className, prefix, title, description, cta }: CtaBannerProps) => (
  <div
    className={twMerge(
      'bg-ctaBanner/30 flex w-full flex-col gap-6 rounded-lg rounded-tl-none p-4 md:flex-row md:gap-0 md:rounded-none md:rounded-r-full md:p-0',
      className,
    )}
  >
    <div className="min-h-12.5 flex gap-4 md:items-center md:px-3 md:py-2.5">
      {prefix && <span>{prefix}</span>}
      <div className="flex flex-col gap-2 md:flex-row md:items-center md:gap-4">
        <span className="font-bold">{title}</span>
        <span className="small">{description}</span>
      </div>
    </div>
    <div className="ml-auto">
      <Button
        className="bg-gray-storm [&>*]:small ml-auto flex h-full rounded-full md:rounded-l-none md:rounded-r-full md:px-3 md:py-0 [&>*]:font-bold"
        href={cta.href}
        icon={cta.icon}
        iconPosition="right"
        label={cta.label}
        type="primary"
      />
    </div>
  </div>
);

export default CtaBanner;
