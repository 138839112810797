import { format } from 'date-fns';
import { nl } from 'date-fns/locale';

import { toTitleCase } from '@boss/utils';

import Button from '../Button';

interface Translations {
  expirationDate: string;
  dateCreated: string;
  downloadAction: string;
  location: string;
}

export interface QuotationProps {
  dateCreated: string;
  expirationDate: string;
  title?: string;
  location: string;
  translations: Translations;
  onHandleDownload?: () => void;
}

export const Quotation = ({
  dateCreated,
  expirationDate,
  title,
  location,
  translations,
  onHandleDownload,
}: QuotationProps) => {
  return (
    <div className="shadow-m flex-gap-5 flex flex-col gap-5 bg-white p-5">
      <div className="md:flex md:justify-between">
        {title && <h4 className="text-blue-dark mb-4 text-lg">{toTitleCase(title)}</h4>}
        <p className="ml-auto">
          <span className="font-bold">{translations.dateCreated}:</span>{' '}
          {format(new Date(dateCreated), 'dd/MM/yyyy', { locale: nl })}
        </p>
      </div>

      <div>
        <p className="mb-2">
          <span className="font-bold">{translations.location}:</span> {location}
        </p>

        <div className="items-center justify-between sm:flex">
          <p>
            <span className="font-bold">{translations.expirationDate}:</span>{' '}
            {format(new Date(expirationDate), 'dd/MM/yyyy', { locale: nl })}
          </p>
          {onHandleDownload && (
            <Button channel="colora" label={translations.downloadAction} onClick={onHandleDownload} type="link" />
          )}
        </div>
      </div>
    </div>
  );
};

export default Quotation;
