import * as Sentry from '@sentry/nextjs';

import { events as eventService } from '@boss/services/client';

const participateEvent = async ({
  eventId,
  data,
  isLoggedIn,
  locale,
}: {
  eventId: string;
  data: Record<string, unknown>;
  isLoggedIn?: boolean;
  locale: string;
}) => {
  try {
    return await eventService.participate({ eventId, data, isLoggedIn, locale });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Participate event',
      },
    });

    // Needs a re-throw
    throw error;
  }
};

const getEvent = async ({ eventId, locale, isLoggedIn }: { eventId: string; locale: string; isLoggedIn?: boolean }) => {
  try {
    return await eventService.getEvent({ locale, eventId, isLoggedIn });
  } catch (error) {
    Sentry.captureException(error, {
      tags: {
        type: 'Get event',
      },
    });
  }
};

export { participateEvent, getEvent };
