import { useTranslation } from 'next-i18next';
import { useState } from 'react';

import type { CtaFields, Theme } from '@boss/types/b2b-b2c';
import { Button, Cta } from '@boss/ui';

import { useSubmitForm } from '../../../client-queries';
import { useProfile } from '../../../hooks';
import { SubscribeModal } from '../../Modals';
import { getButtonProps, getCtaProps } from '../PropsMapper';

interface CtaComponentProps {
  entry: CtaFields;
  theme: Theme;
  locale: string;
  inline?: boolean;
  additionalProps?: Record<string, unknown>;
  columns?: string[];
}

const CtaComponent = ({ entry, theme, locale, inline, additionalProps, columns }: CtaComponentProps) => {
  if (
    !entry.internalLink &&
    !entry.externalLink &&
    !entry.internalFixedLink &&
    !entry.fileToDownload &&
    !entry.filteredEventOverviewLink &&
    !entry.action
  ) {
    return null;
  }
  // Should a cta be rendered inside a section with columns, it should be shown as a cta
  if (columns?.includes(additionalProps?.parentDisplay as string)) {
    return (
      <Cta
        className="flex flex-col items-center justify-center gap-4 p-4"
        {...getCtaProps(entry, theme, locale, inline)}
        {...additionalProps}
      />
    );
  }
  return <Button {...getButtonProps(entry, theme, locale, inline)} {...additionalProps} />;
};

export const CtaMapper = ({ ...props }: CtaComponentProps) => {
  const { action } = props.entry;
  const { t } = useTranslation();
  const { isLoggedIn } = useProfile();
  const [showPaintGuidModal, setShowPaintGuidModal] = useState(false);
  const { mutate: sendMail, isLoading: isSendingMail } = useSubmitForm({
    toasts: {
      success: {
        title: t('common:toast.paintguide.success.title') as string,
      },
    },
  });

  if (action && action === 'mail paint guide') {
    const translations = {
      title: t('mailPaintGuide.title'),
      description: !isLoggedIn ? t('magazineCard.modal.description') : undefined,
      subscribe: t('mailPaintGuide.subscribe'),
    };

    const handleSubmit = (values: {
      email: string;
      firstName: string;
      lastName: string;
      subscribeNewsletter: boolean;
    }) => {
      sendMail(
        {
          email: values.email,
          firstname: values.firstName,
          lastname: values.lastName,
          subscribetonewsletter: values.subscribeNewsletter || undefined,
          url: props.entry?.fileToDownload?.file?.url,
          type: 'SendPaintguide',
        },
        {
          onSuccess: () => {
            setShowPaintGuidModal(false);
          },
        },
      );
    };

    return (
      <>
        <CtaComponent
          {...props}
          additionalProps={{
            ...props.additionalProps,
            href: undefined,
            onClick: () => setShowPaintGuidModal(true),
          }}
        />
        {showPaintGuidModal && (
          <SubscribeModal
            isLoading={isSendingMail}
            onCloseModal={setShowPaintGuidModal}
            onSubmit={handleSubmit}
            translations={translations}
          />
        )}
      </>
    );
  }

  return <CtaComponent {...props} />;
};

export default CtaMapper;
