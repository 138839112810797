import {
  Options,
  RenderMark,
  RenderNode,
  documentToReactComponents as contentFulDocumentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, Inline } from '@contentful/rich-text-types';
import React, { ReactElement, ReactNode } from 'react';

import type { CommonProps } from '@boss/types/b2b-b2c';
import { List, Paragraph } from '@boss/ui';

import AssetHyperlink from './AssetHyperlink';
import EntryHyperlink from './EntryHyperlink';
import InlineHyperlink from './InlineHyperlink';

type ComponentMapper<TComponent, TTheme> = ({
  entry,
  theme,
  locale,
}: {
  entry: TComponent;
  theme?: TTheme;
  inline?: boolean;
  locale: string;
  embeddedEntry?: boolean;
  additionalProps?: Record<string, unknown>;
  imageProps?: Record<string, unknown>;
}) => JSX.Element;

type MapperOptions<TTheme> = { theme?: TTheme; locale: string };

export type RenderOptions = {
  noParagraph?: boolean;
  noWrapper?: boolean;
  noXSpacing?: boolean;
};

/*
    replace new line flag with html new line. textblock.ts - toDocument
    libs/services/src/product/textblock.ts
*/
const inlineText = (input: string) => {
  const text = input.replace(/<ENTER_NEW_LINE_HERE>/g, '\n');

  return /^\s+$/.test(text) ? '' : text.replace(/\xA0/g, ' '); // Replace &nbsp;
};

const defaultMarkRenderers: RenderMark = {};

const defaultNodeRenderers = <TComponent, TTheme>(
  mapperOptions: MapperOptions<TTheme>,
  renderOptions: RenderOptions,
  imageProps?: Record<string, unknown>,
  ComponentMapper?: ComponentMapper<TComponent, TTheme>,
): RenderNode => {
  return {
    [BLOCKS.HEADING_1]: (_, children) => <h1 className="mb-6">{children}</h1>,
    [BLOCKS.HEADING_2]: (_, children) => <h2 className="mb-4">{children}</h2>,
    [BLOCKS.HEADING_3]: (_, children) => <h3 className="mb-4">{children}</h3>,
    [BLOCKS.HEADING_4]: (_, children) => <h4 className="mb-4">{children}</h4>,
    [BLOCKS.HEADING_5]: (_, children) => <h5 className="mb-4">{children}</h5>,
    [BLOCKS.HEADING_6]: (_, children) => <h6 className="mb-4">{children}</h6>,
    [BLOCKS.PARAGRAPH]: (_, children) =>
      renderOptions.noParagraph ? <div>{children}</div> : <Paragraph>{children}</Paragraph>,
    [BLOCKS.UL_LIST]: (_, children) => <List>{children}</List>,
    [BLOCKS.OL_LIST]: (_, children) => <List as="ordered">{children}</List>,
    [BLOCKS.LIST_ITEM]: (_, children) => <li>{children}</li>,
    [BLOCKS.TABLE]: (_, children) => (
      <table className="w-full text-left">
        <tbody>{children}</tbody>
      </table>
    ),
    [BLOCKS.TABLE_ROW]: (_, children) => (
      <tr className="border-brown border-b-[0.5px] last-of-type:border-none">{children}</tr>
    ),
    [BLOCKS.TABLE_CELL]: (_, children) => <td className="py-0 pr-6 align-top md:py-6 md:pr-10">{children}</td>,
    [INLINES.HYPERLINK]: node => <InlineHyperlink hyperlink={node as Inline} />,
    [INLINES.ENTRY_HYPERLINK]: node => <EntryHyperlink hyperlink={node as Inline} />,
    [INLINES.ASSET_HYPERLINK]: node => <AssetHyperlink hyperlink={node as Inline} />,
    [BLOCKS.EMBEDDED_ENTRY]: node =>
      ComponentMapper ? (
        <ComponentMapper
          additionalProps={{ ...renderOptions, ...mapperOptions }}
          embeddedEntry
          entry={node.data.target as TComponent}
          imageProps={imageProps}
          {...mapperOptions}
        />
      ) : null,
    [INLINES.EMBEDDED_ENTRY]: node =>
      ComponentMapper ? (
        <ComponentMapper
          additionalProps={{ ...renderOptions, ...mapperOptions }}
          entry={node.data.target as TComponent}
          imageProps={imageProps}
          {...mapperOptions}
          inline
        />
      ) : null,
  };
};

const documentToReactComponents = <TComponent, TTheme>(
  richTextDocument: Document,
  options: Partial<Options> = {},
  { renderNode, renderMark }: { renderNode?: RenderNode; renderMark?: RenderMark } = {},
  {
    ComponentMapper,
    mapperOptions,
    renderOptions,
    imageProps,
  }: {
    ComponentMapper?: ComponentMapper<TComponent, TTheme>;
    mapperOptions: MapperOptions<TTheme>;
    renderOptions: RenderOptions;
    imageProps?: Record<string, unknown>;
  },
): ReactNode => {
  if (!richTextDocument) {
    return null;
  }

  return contentFulDocumentToReactComponents(richTextDocument, {
    renderMark: {
      ...{ ...defaultMarkRenderers, ...renderMark },
      ...options.renderMark,
    },
    renderNode: {
      ...{ ...defaultNodeRenderers(mapperOptions, renderOptions, imageProps, ComponentMapper), ...renderNode },
      ...options.renderNode,
    },
    renderText: (text: string) => {
      return inlineText(text)
        .split('\n')
        .reduce((children: ReactNode[], textSegment: string, index: number) => {
          return [...children, index > 0 && <br />, textSegment];
        }, [])
        .map((child: ReactNode) => {
          if ((child as ReactElement).type === 'br') {
            return React.cloneElement(child as ReactElement, { key: `${Math.random()}` });
          }

          return child;
        });
    },
  });
};

type Props<TComponent, TTheme> = CommonProps & {
  className?: string;
  content: Document;
  renderNode?: RenderNode;
  renderMark?: RenderMark;
  ComponentMapper?: ComponentMapper<TComponent, TTheme>;
  mapperOptions: MapperOptions<TTheme>;
  renderOptions?: RenderOptions;
  imageProps?: Record<string, unknown>;
};

const RichText = <TComponent, TTheme>({
  className,
  content,
  renderNode = {},
  renderMark = {},
  ComponentMapper,
  mapperOptions,
  renderOptions = {},
  imageProps,
}: Props<TComponent, TTheme>) => {
  if (!content) {
    return null;
  }

  const richText = documentToReactComponents(
    content,
    {},
    { renderNode, renderMark },
    { ComponentMapper: ComponentMapper, mapperOptions, renderOptions, imageProps },
  );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return renderOptions.noWrapper ? <>{richText}</> : <div className={className}>{richText}</div>;
};

export default RichText;
