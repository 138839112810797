import { useTranslation } from 'next-i18next';

import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { FormType, useFormField, useFormSubmit } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = [
  'salutation',
  'clientnumber',
  'name',
  'firstname',
  'lastname',
  'phonenumber',
  'email',
  'message',
  'newsletter',
  'termsandconditions',
] as const;

type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: Partial<FieldOverwrite>;
  additionalFields?: FormField[];
};

const BasicInfoForm = ({ fieldsToShow: initialFieldsToShow, fieldsOverwrite, additionalFields, type }: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();
  const {
    salutation,
    clientnumber,
    firstname,
    lastname,
    name,
    email,
    phonenumber,
    termsandconditions,
    question,
    newsletter,
    store,
  } = useFormField();
  const { t } = useTranslation('forms');

  const baseFields: FormField[] = [
    salutation,
    {
      ...clientnumber,
      required: false,
    },
    {
      ...name,
    },
    firstname,
    lastname,
    {
      ...phonenumber,
    },
    {
      ...store,
      label: t('fields.favoriteShop'),
    },
    email,
    question,
    {
      ...newsletter,
      colStyle: 'ml-0 col-span-full',
    },
    termsandconditions,
  ];

  const handleSubmit = (vals: FormValues) => {
    return onSubmit(type, {
      ...vals,
      newsletter: vals.newsletter ? true : undefined,
      storeid: vals.store,
      firstname: vals.firstname ?? '',
      lastname: vals.lastname ?? '',
    });
  };

  return (
    <DynamicForm
      fields={buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onSubmit={handleSubmit}
      variant="light"
    />
  );
};

export default BasicInfoForm;
