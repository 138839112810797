import * as bossClient from '@boss/boss-client';
import { TrainingFields, WorkshopFields } from '@boss/types/b2b-b2c';

import { IEvent } from './types';

/**
 * Transform event from bossClient Event to IEvent
 * @param event Boss client event
 * @param contentfullTraining Training or Workshop fields from Contentful
 * @param relatedEvents Related events
 * @returns {IEvent}
 */
export const transformEvent = ({
  event,
  contentfullTraining,
  relatedEvents,
}: {
  event: bossClient.types.event.Event;
  contentfullTraining?: TrainingFields | WorkshopFields;
  relatedEvents?: IEvent[];
  // eslint-disable-next-line
}): IEvent => {
  return {
    id: event.id ?? '',
    status: event.status ?? '',
    type: event.type ?? '',
    title: contentfullTraining?.title ?? event.title ?? event.description ?? '',
    location: event.location ?? '',
    locationId: event.locationid ?? '',
    productLink: event.productlink ?? '',
    date: event.date ?? '',
    startTime: event.starttime ?? '',
    endTime: event.endtime ?? '',
    duration: event.duration ?? '',
    price: event.price ?? 0,
    complete: event.complete ?? false,
    numberOfParticipants: event.numberofparticipants ?? 0,
    language: event.language ?? '',
    skuId: event.skuid ?? '',
    image: contentfullTraining?.image ?? null,
    description: contentfullTraining?.description ?? null,
    prefixSections: contentfullTraining?.prefixSections ?? null,
    suffixSections: contentfullTraining?.suffixSections ?? null,
    relatedEvents: relatedEvents ?? [],
    theme: contentfullTraining && 'theme' in contentfullTraining ? contentfullTraining.theme : '',
  };
};

export const transformWorkshop = transformEvent;
export const transformTraining = transformEvent;
