import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { QuoteInstanceProps } from '../';
import { Image } from '../..';

const ContentWrapperStyling = cva('relative flex flex-col md:gap-10 w-full gap-5 p-4 md:p-10', {
  variants: {
    hasAuthor: {
      true: 'pb-8',
      false: '',
    },
  },
});

const QuotePrimary = ({ quote, author, company, className, background }: QuoteInstanceProps) => (
  <div aria-label="quote" className={twMerge('relative h-full w-full pb-20', className)}>
    {background && (
      <Image
        className="max-w-95 absolute -bottom-20 object-contain"
        height={80}
        src="/stripes/blue-stripe.svg"
        useNext
        width={380}
      />
    )}

    <div className={ContentWrapperStyling({ hasAuthor: !!author })}>
      <h6 className="h2 sm:h3 lg:h2 before:content-[attr(title)] after:content-[attr(title)]" title={`"`}>
        {quote}
      </h6>
      {author && (
        <span className="small font-raleway absolute left-4 top-full italic md:left-10">
          <span>{author}</span>
          {company && <span>{`, ${company}`}</span>}
        </span>
      )}
    </div>
  </div>
);

export default QuotePrimary;
