import { faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { ImageFields } from '@boss/types/b2b-b2c';

import { Image } from '..';

/**
 * Represents the properties of the MagazineCard
 * @property {string} annotation - for now: the edition of the magazine but might be used for titles,.. in the future
 * @property {Nullable<SimpleImage>} image - the image used as illustration (cover of the magazine).
 * @property {string} previewUrl - link to the preview of the magazine
 * @property {object} translations - an object containing the necessary copy for the component
 */

export type MagazineCardProps = {
  annotation: string;
  image: ImageFields;
  translations: {
    mail: string;
  };
  onMailAction: () => void;
};

const MagazineCard = ({ annotation, image, translations, onMailAction: handleMailAction }: MagazineCardProps) => {
  return (
    <div className="flex flex-col">
      <div className="body text-textColor mb-4 ml-2">{annotation}</div>
      <Image {...image} wrapperClassName="mb-4 aspect-[4/5]" />
      <div className="text-linkColor mb-2 block cursor-pointer" onClick={handleMailAction}>
        <FontAwesomeIcon className="mr-3" icon={faEnvelope} size="sm" />
        <span className="underline">{translations.mail}</span>
      </div>
    </div>
  );
};

export default MagazineCard;
