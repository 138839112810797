import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

import { NavigationItem } from '@boss/types/b2b-b2c';

import FooterNavItem from './FooterNavItem';

type SocialLinksProps = {
  locale: string;
  links: NavigationItem[];
  className?: string;
};

const SocialLinks = ({ links, className }: SocialLinksProps) => {
  return (
    <ul className={twMerge('flex flex-wrap gap-x-8 gap-y-4 md:gap-4', className)}>
      {links.map((socialLink, i) => {
        if (!socialLink?.icon) {
          return null;
        }

        return (
          <li className="block" key={`${socialLink.id}-${i}`}>
            <FooterNavItem link={socialLink.href}>
              <FontAwesomeIcon icon={socialLink.icon} size="xl" />
            </FooterNavItem>
          </li>
        );
      })}
    </ul>
  );
};

export default SocialLinks;
