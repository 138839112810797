import { cva } from 'class-variance-authority';
import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import type { Nullable, SimpleImage } from '@boss/types/corporate-jobs';

import CorporateTabsNavigationDropdown from './CorporateTabsNavigationDropdown';
import CorporateTabsNavigationTabs from './CorporateTabsNavigationTabs';

export type CorporateTabItem = {
  title?: string;
  icon?: Nullable<SimpleImage>;
  color: string;
  content: ReactNode | string;
};

interface CorporateTabsProps {
  items: CorporateTabItem[];
  activeItem?: number;
  className?: string;
  contentClassName?: string;
  buttonClassName?: string;
}

const ItemStyling = cva('', {
  variants: {
    active: {
      true: 'visible',
      false: 'hidden',
    },
  },
});

const CorporateTabs: React.FC<CorporateTabsProps> = ({
  items,
  activeItem = 0,
  className,
  contentClassName,
  buttonClassName,
}) => {
  const [currentTab, setCurrentTab] = useState<number>(activeItem);

  const usedItems = items.slice(0, 5);

  return (
    <div className={twMerge('my-4', className)}>
      <div className="hidden md:block">
        <CorporateTabsNavigationTabs
          buttonClassName={buttonClassName}
          currentTab={currentTab}
          items={usedItems}
          navigate={setCurrentTab}
        />
        <div className={twMerge('mt-4', contentClassName)}>
          {usedItems.map((item: CorporateTabItem, index: number) => {
            return (
              <div className={ItemStyling({ active: index === currentTab })} key={`content-${index}`}>
                {item.content}
              </div>
            );
          })}
        </div>
      </div>
      <CorporateTabsNavigationDropdown
        buttonClassName={buttonClassName}
        className="visible md:hidden"
        currentTab={currentTab}
        items={usedItems}
        navigate={setCurrentTab}
      />
    </div>
  );
};

export default CorporateTabs;
