import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Portal from '../Portal';

type Props = {
  className?: string;
  children?: ReactNode;
  onClick?: () => void;
  visible?: boolean;
};

const Backdrop = ({ className, children, onClick, visible }: Props) => (
  <>
    {visible && (
      // FIXME
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      <Portal>
        <div
          className={twMerge(
            'bg-backdrop z-backdrop fixed bottom-0 left-0 right-0 top-0 h-full w-full opacity-50',
            className,
          )}
          onClick={onClick}
        />
        {children}
      </Portal>
    )}
  </>
);

export default Backdrop;
