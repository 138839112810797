import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useState } from 'react';

import { NavigationItem } from '@boss/types/b2b-b2c';

import { Link } from '../../../';

type MenuItemProps = {
  link: NavigationItem;
  topLevel?: boolean;
};

const TopLevelStyling = cva('flex', {
  variants: {
    topLevel: {
      true: 'border-b-1 border-brown flex py-2.5 font-bold',
    },
  },
});

const MenuItem = ({ link, topLevel }: MenuItemProps) => {
  const [activeLink, setActiveLink] = useState<NavigationItem | null>(null);
  const active = link.id === activeLink?.id;

  const handleLink = (link: NavigationItem) => {
    if (link && link !== activeLink) {
      return setActiveLink(link);
    }

    setActiveLink(null);
  };

  if (!link.label) {
    return null;
  }

  return (
    <div className="flex flex-col gap-6">
      <Link
        className={TopLevelStyling({ topLevel })}
        href={link.childNavigationItems ? undefined : link.href}
        onClick={() => handleLink(link)}
      >
        {link.icon && <FontAwesomeIcon className="mr-2" icon={link.icon} />}
        {link.label}
        {link.childNavigationItems && (
          <FontAwesomeIcon className={`${active ? 'rotate-180' : ''} ml-auto mr-0 self-end`} icon={faChevronDown} />
        )}
      </Link>
      {active && link.childNavigationItems && (
        <div className="ml-4 flex flex-col gap-6">
          {link.childNavigationItems.map(link => (
            <MenuItem key={link.id ?? `${link.href ?? 'main-link'}-${link.label}`} link={link} />
          ))}
        </div>
      )}
    </div>
  );
};

export default MenuItem;
