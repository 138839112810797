import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonType, LocalizedSlugs, NavigationItem, Nullable } from '@boss/types/b2b-b2c';

import { Button, Container, Link, LocaleSelector } from '../../../';

type Props = {
  links: Nullable<NavigationItem[]>;
  highlightedLinks?: Nullable<NavigationItem[]>;
  highlightedButtonClassName?: string;
  handleLink: (link: NavigationItem) => void;
  className?: string;
  localizedSlugs: LocalizedSlugs;
  highlightedButtonMobileType?: ButtonType;
  topLinks?: Nullable<NavigationItem[]>;
};

type MenuItemProps = {
  link: NavigationItem;
  handleLink: (link: NavigationItem) => void;
};

const NavItemStyles = cva('relative flex flex-col border-b-1 border-white border-opacity-30', {
  variants: {
    active: {
      true: 'bg-black-light break-before break-after before:bg-black-light after:bg-black-light border-none',
    },
  },
});

const MenuItem = ({ link, handleLink }: MenuItemProps) => {
  const [active, setActive] = useState(false);
  const { query } = useRouter();

  useEffect(() => {
    setActive(!!link.childNavigationItems?.find(link => link.href === query?.slug));
  }, [query, link.childNavigationItems]);

  const handleClick = () => {
    setActive(!active);
    handleLink(link);
  };

  return (
    <div className={NavItemStyles({ active })}>
      <Link
        className="flex justify-between py-4"
        href={!link.childNavigationItems?.length ? link.href : ''}
        onClick={handleClick}
      >
        {link.label}
        {!!link.childNavigationItems?.length && (
          <FontAwesomeIcon className={active ? 'rotate-180' : ''} icon={faChevronDown} />
        )}
      </Link>
      {active && !!link.childNavigationItems?.length && (
        <div className="flex flex-col py-2.5 pl-8">
          {link.childNavigationItems.map(link => (
            <Link
              className="flex py-2.5"
              href={link.href}
              key={link.id || `${link.id || 'main-link'}-${link.label}`}
              onClick={() => handleLink(link)}
            >
              {link.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

const MobileMenu = ({
  links,
  highlightedLinks,
  handleLink,
  className,
  localizedSlugs,
  highlightedButtonClassName,
  highlightedButtonMobileType,
  topLinks,
}: Props) => (
  <div className={twMerge('h-full w-full bg-black py-8 text-white', className)}>
    <Container className="flex h-full flex-col justify-between overflow-y-auto">
      <div className="mb-3">
        {!!links?.length && (
          <div className="mb-3 flex flex-col">
            {links.map(link => (
              <MenuItem
                handleLink={handleLink}
                key={link.id || `${link.id || 'main-link'}-${link.label}`}
                link={link}
              />
            ))}
          </div>
        )}
      </div>
      <div className="mb-3 flex flex-col">
        {!!highlightedLinks?.length && (
          <div className="flex flex-col gap-2">
            {highlightedLinks.map(link => (
              <Button
                className={twMerge('w-full border-white text-center ', highlightedButtonClassName)}
                href={link.href}
                key={link.id || `${link.id || 'main-link'}-${link.label}`}
                label={link.label}
                type={highlightedButtonMobileType ?? 'secondary'}
              />
            ))}
          </div>
        )}
        <div className="mb-6">
          {!!topLinks?.length &&
            topLinks.map(topLink => (
              <Link
                anchorClassName="w-full block"
                className="text-m border-b-1 block w-full border-white border-opacity-30 py-4"
                href={topLink.href}
                key={topLink.id}
              >
                {topLink.label}
              </Link>
            ))}
        </div>
        <LocaleSelector className="w-fit" localizedSlugs={localizedSlugs} placement="top" />
      </div>
    </Container>
  </div>
);

export default MobileMenu;
