import { Inline } from '@contentful/rich-text-types';

import { CommonProps } from '@boss/types/b2b-b2c';
import { TextLink } from '@boss/ui';

type propsType = CommonProps & {
  hyperlink: Inline;
};

const AssetHyperlink = ({ hyperlink, className }: propsType) => {
  const { target } = hyperlink.data;
  let value;

  if ('value' in hyperlink.content[0]) {
    value = hyperlink.content[0].value;
  }

  if (!target || !value) {
    return null;
  }

  return (
    <TextLink className={className} href={`https:${target.file?.url ?? target.fields?.file?.url}`}>
      {value}
    </TextLink>
  );
};

export default AssetHyperlink;
