import type { color } from '../../types';
import bossApi from '../../utils/fetch-api';

const DEFAULT_OPTIONS = { m2m: true, authScope: process.env.BOSS_API_AUTH_SCOPE_COLOR };

export async function* getColorGroups(locale: string): AsyncGenerator<color.ColorGroup> {
  const bossColorGroups = await bossApi<color.ColorGroup[]>(`/color/v2/colorgroups`, {
    ...DEFAULT_OPTIONS,
    locale,
  });

  if (!bossColorGroups) {
    return null;
  }

  for (const bossColorGroup of bossColorGroups) {
    yield bossColorGroup;
  }
}

export async function* getColors(locale: string): AsyncGenerator<color.Color> {
  let skip = 0;
  const limit = 500;
  let fetchMore = true;

  while (fetchMore) {
    const bossColors = await bossApi<color.Color[]>(`/color/v2/colors?skip=${skip}&limit=${limit}`, {
      ...DEFAULT_OPTIONS,
      locale,
    });

    if (bossColors && bossColors.length > 0) {
      for (const bossColor of bossColors) {
        yield bossColor;
      }

      if (bossColors.length < limit) {
        fetchMore = false;
      } else {
        skip += limit;
      }
    } else {
      fetchMore = false;
    }
  }
}

/**
 * Search colors by searchString
 */
export const searchColors = ({
  searchString,
  productId,
  skuId,
  skip = 0,
  limit = 100,
  colortypegroup,
  locale,
}: {
  searchString: string;
  productId?: string;
  skuId?: string;
  limit?: number;
  skip?: number;
  colortypegroup?: string;
  locale: string;
}) => {
  let optionalQueryParams = skuId ? `&skuId=${skuId}` : productId ? `&productId=${productId}` : '';

  if (colortypegroup) {
    optionalQueryParams += `&colortypegroup=${colortypegroup}`;
  }

  const cleanedSearchString = searchString?.replace(/[^a-zA-Z0-9 ]/g, '');

  return bossApi<color.Color[]>(
    `/color/v2/colors/bysearchstring?searchString=${cleanedSearchString}&skip=${skip}&limit=${limit}${optionalQueryParams}`,
    {
      ...DEFAULT_OPTIONS,
      locale,
    },
  );
};

/**
 * Search colors by colorGroup
 */
export const byColorGroup = ({
  colorGroup,
  productId,
  skuId,
  locale,
}: {
  colorGroup: string;
  productId?: string;
  skuId?: string;
  locale: string;
}) => {
  const optionalQueryParams = skuId ? `&skuId=${skuId}` : productId ? `&productId=${productId}` : '';

  return bossApi<color.Color[]>(`/color/v2/colors/bycolorgroupid?colorGroupId=${colorGroup}${optionalQueryParams}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};

export const byId = (colorId: string, locale: string, showSamples = false) => {
  const optionalQueryParams = showSamples ? `?showSampleColor=${showSamples.toString()}` : '';

  return bossApi<color.ColorDetail>(`/color/v2/colors/${colorId}${optionalQueryParams}`, {
    ...DEFAULT_OPTIONS,
    locale,
  });
};
