import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ImageFields } from '@boss/types/b2b-b2c';

import Button from '../Button';
import Image from '../Image';

interface Props {
  image: ImageFields;
  title: string;
  description: ReactNode;
  href: string;
  buttonText: string;
  className?: string;
}

const ActionCard = ({ image, title, description, href, buttonText, className }: Props) => {
  return (
    <div className={twMerge('flex h-full flex-col rounded bg-white shadow-lg', className)}>
      <Image {...image} fill width={500} wrapperClassName="h-[15em] flex-shrink-0" />

      <div className="flex flex-grow flex-col px-6 py-4">
        <div className="mb-2 text-xl font-bold">{title}</div>
        <div className="flex-grow text-base text-gray-700">{description}</div>
      </div>
      <div className="mt-auto px-6 pb-6 pt-4">
        <Button href={href} label={buttonText} type="primary" />
      </div>
    </div>
  );
};

export default ActionCard;
