import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { Image, Link } from '..';

type Props = {
  title: string;
  description: string;
  slug: string;
  imageUrl: string;
  imageAlt: string;
  className?: string;
  translations?: {
    linkLabel: string;
  };
  whitespace?: 'ratio' | 'none';
  style?: React.CSSProperties;
  variant?: 'normal' | 'wide' | 'image-on-left' | 'image-on-right';
};

const textStyles = cva('w-full p-4 md:col-span-2 md:px-12 md:py-5 md:px-8', {
  variants: {
    variant: {
      ratio: '',
      none: 'h-auto',
    },
    isWide: {
      true: 'md:flex md:items-center md:h-full',
    },
    imagePosition: {
      normal: '',
      left: 'md:col-start-2',
      right: 'md:col-start-1',
    },
  },
});

const imageStyles = cva('w-full overflow-hidden bg-gray-100', {
  variants: {
    variant: {
      ratio: 'aspect-[16/12] h-auto',
      none: 'h-full',
    },
    isWide: {
      true: 'md:h-full',
    },
    imagePosition: {
      normal: 'row-start-1',
      left: 'md:h-full md:col-start-1 md:row-start-1 md:row-span-3',
      right: 'md:h-full md:col-start-2 md:row-start-1 md:row-span-3',
    },
  },
  defaultVariants: {
    variant: 'ratio',
    imagePosition: 'normal',
  },
});

const VARIANTS_MAP = {
  NORMAL: 'normal',
  WIDE: 'wide',
  IMAGE_LEFT: 'image-on-left',
  IMAGE_RIGHT: 'image-on-right',
} as const;

const HORIZONTAL_VARIANTS = [VARIANTS_MAP.WIDE, VARIANTS_MAP.IMAGE_LEFT, VARIANTS_MAP.IMAGE_RIGHT] as const;

const isHorizontalLayout = (variant: Props['variant']) =>
  HORIZONTAL_VARIANTS.includes(variant as (typeof HORIZONTAL_VARIANTS)[number]);

const ContentTile = ({
  imageUrl,
  imageAlt,
  title,
  description,
  slug,
  className,
  translations,
  whitespace = 'ratio',
  style,
  variant = 'normal',
}: Props) => {
  return (
    <Link className={twMerge('relative block h-full w-full')} href={slug}>
      <div
        className={twMerge(
          'border-1 rounded-t-brand rounded-r-brand border-gray-light grid h-full overflow-hidden bg-white shadow-sm',
          isHorizontalLayout(variant) ? 'md:grid md:h-full md:grid-cols-2' : 'auto-rows-auto grid-cols-1',
          className,
        )}
        style={style}
      >
        <div
          className={imageStyles({
            variant: whitespace,
            isWide: variant === HORIZONTAL_VARIANTS[0],
            imagePosition:
              variant === VARIANTS_MAP.IMAGE_LEFT ? 'left' : variant === VARIANTS_MAP.IMAGE_RIGHT ? 'right' : 'normal',
          })}
        >
          {imageUrl && (
            <Image
              alt={imageAlt}
              className="h-full w-full object-cover"
              src={imageUrl}
              width={500}
              wrapperClassName="h-full"
            />
          )}
        </div>
        <div className={twMerge('row-start-2', isHorizontalLayout(variant) && 'flex flex-col md:row-start-1')}>
          <div
            className={textStyles({
              variant: whitespace,
              isWide: variant === VARIANTS_MAP.WIDE,
              imagePosition:
                variant === VARIANTS_MAP.IMAGE_LEFT
                  ? 'left'
                  : variant === VARIANTS_MAP.IMAGE_RIGHT
                  ? 'right'
                  : 'normal',
            })}
          >
            <div className="flex flex-col gap-2">
              <span className="h3 hyphens-auto">{title}</span>
              <span>{description}</span>
            </div>
          </div>
          {translations?.linkLabel && variant === 'wide' && (
            <div className="mt-auto flex justify-end px-8 pb-4">
              <span className="text-blue flex items-center gap-2 py-2">
                {translations?.linkLabel} <FontAwesomeIcon icon={faArrowRight} />
              </span>
            </div>
          )}
        </div>
        {translations?.linkLabel && variant !== 'wide' && (
          <div
            className={twMerge(
              'row-start-3 flex justify-end px-8 pb-4',
              variant === 'image-on-right' ? 'md:col-start-1' : 'md:col-span-2',
              isHorizontalLayout(variant) && 'md:row-start-2',
            )}
          >
            <span className="text-blue flex items-center gap-2 py-2">
              {translations?.linkLabel} <FontAwesomeIcon icon={faArrowRight} />
            </span>
          </div>
        )}
      </div>
    </Link>
  );
};

export default ContentTile;
