import * as bossClient from '@boss/boss-client';
import { IAccountPriceInfBlock, IArticle, IArticleStock, ISkuDiscountInfo } from '@boss/types/b2b-b2c';

import { mapArticle } from './mapper';

export const byIds = async ({
  ids,
  locale,
  channel,
  colorCode,
}: {
  ids: string[];
  locale: string;
  channel: string;
  colorCode?: string;
}): Promise<IArticle[]> => {
  if (!ids?.length) {
    return [];
  }

  const articles = await bossClient.articleClient.getArticleByArticleIds({ articleIds: ids, locale });

  return (
    articles
      ?.filter(article => {
        return !colorCode || article.color.code === '0' || article.color.code === colorCode;
      })
      .map(article => mapArticle(article, channel)) ?? []
  );
};

export const byProductId = async ({
  productId,
  locale,
  channel,
}: {
  productId: string;
  locale: string;
  channel: string;
}): Promise<IArticle[]> => {
  const articles = await bossClient.articleClient.getArticleByProductId({ productId, locale });

  return articles?.map(article => mapArticle(article, channel)) ?? [];
};

export const getMymxArticles = async ({
  colorId,
  productId,
  locale,
  channel,
}: {
  colorId: string;
  productId: string;
  locale: string;
  channel: string;
}): Promise<IArticle[]> => {
  const articles = await bossClient.articleClient.getMymxArticles({ productId, colorId, locale });

  return articles?.map(article => mapArticle(article, channel)) ?? [];
};

export const getPremixedArticles = async ({
  colorId,
  productId,
  locale,
  channel,
}: {
  colorId: string;
  productId: string;
  locale: string;
  channel: string;
}): Promise<IArticle[]> => {
  const articles = await bossClient.articleClient.getPremixedArticles({ productId, colorId, locale });

  return articles?.map(article => mapArticle(article, channel)) ?? [];
};

export const getBaseArticles = async ({
  productId,
  locale,
  channel,
  colorId,
}: {
  productId: string;
  locale: string;
  channel: string;
  colorId: string;
}): Promise<IArticle[]> => {
  const articles = await bossClient.articleClient.getBaseArticles({ productId, locale, colorId });

  return articles?.map(article => mapArticle(article, channel)) ?? [];
};

export const getArticleStockByWarehouseIds = async ({
  skus,
  warehouseIds,
  locale,
}: {
  skus: {
    skuid: string;
    quantity?: number;
    unit?: string;
  }[];
  warehouseIds: string[];
  locale: string;
}): Promise<IArticleStock> => {
  return (await bossClient.articleClient.getArticleStockByWarehouseIds({ skus, warehouseIds, locale })) ?? [];
};

export const getAccountPriceInfoBySkuIds = async ({
  skuIds,
  locale,
  isLoggedIn,
}: {
  skuIds: string[];
  locale: string;
  isLoggedIn: boolean;
}): Promise<IAccountPriceInfBlock[] | null> => {
  if (!skuIds?.length) {
    return null;
  }

  return (await bossClient.articleClient.getAccountPriceInfoBySkuIds({ skuIds, locale, isLoggedIn })) ?? [];
};

export const getAccountPriceInfoBySkuId = async ({
  skuId,
  locale,
  unit,
  quantity,
  isLoggedIn,
}: {
  skuId: string;
  locale: string;
  unit?: string;
  quantity?: number;
  isLoggedIn?: boolean;
}): Promise<IAccountPriceInfBlock | null> => {
  return (
    (await bossClient.articleClient.getAccountPriceInfoBySkuId({ skuId, locale, unit, quantity, isLoggedIn })) ?? null
  );
};

export const getDiscountInfoBySkuId = async ({
  skuId,
  locale,
  unit,
  quantity,
}: {
  skuId: string;
  locale: string;
  unit: string;
  quantity: number;
}): Promise<ISkuDiscountInfo | null> => {
  const discountInfo =
    (await bossClient.articleClient.getDiscountInfoBySkuId({ skuId, unit, locale, quantity })) ?? null;

  return {
    skuId: discountInfo?.skuid ?? '',
    unit: discountInfo?.unit ?? '',
    quantity: discountInfo?.qty ?? 0,
    info: discountInfo?.info ?? [],
  };
};
