import {
  FloatingArrow,
  FloatingPortal,
  Placement,
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { ReactElement, ReactNode, cloneElement, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  button: ReactElement;
  children?: ReactNode;
  className?: string;
  arrowClassName?: string;
  floating: ReactElement;
  isOpen?: boolean;
  placement?: Placement;
  showArrow?: boolean;
  offset?: number;
  onChangeOpenStatus?: (status: boolean) => void;
}

/**
 * A component used for automatically positioning floating elements
 *
 *  @param {ReactElement} props.button - Component that opens the floating portal with the floating ReactElement present
 *  @param {ReactNode} props.children - Optional content that is rendered next to the button
 *  @param {string} props.className - ClassName
 *  @param {string} props.arrowClassName - arrowClassName
 *  @param {ReactNode} props.floating - Component that gets rendered when the floating portal is open
 *  @param {boolean} props.isOpen - Is open?
 *  @param {Placement} props.placement - Placement of floating
 *  @param {function} props.setIsOpen - Set is open
 *  @param {boolean} props.showArrow - Show an arrow
 *  @param {number} props.offset - Offset of floating content compared to button
 *  @param {function} props.onChangeOpenStatus - Callback for when the open status changes
 */
const Popper = ({
  children,
  className,
  arrowClassName,
  button,
  floating,
  isOpen: initialOpen,
  placement = 'bottom',
  showArrow,
  offset: offsetOverwrite = 5,
  onChangeOpenStatus: handleChangeOpenStatus,
}: Props) => {
  const [isOpen, setIsOpen] = useState(initialOpen);
  const arrowRef = useRef(null);
  const floatingRef = useRef<HTMLDivElement | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    placement,
    open: isOpen,
    onOpenChange: state => {
      if (handleChangeOpenStatus) {
        handleChangeOpenStatus(state);
      } else {
        setIsOpen(state);
      }
    },
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetOverwrite),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'end',
      }),
      shift({ padding: 10 }),
      arrow({
        element: arrowRef,
      }),
    ],
  });

  useEffect(() => {
    setIsOpen(initialOpen);
  }, [initialOpen]);

  /**
   * Effect hook that handles closing the popper when scrolling occurs
   * Uses capture phase to detect scrolling in any part of the DOM tree
   * Cleans up event listener on unmount or when popper closes
   */
  useEffect(() => {
    if (isOpen) {
      const handleScroll = () => {
        if (handleChangeOpenStatus) {
          handleChangeOpenStatus(false);
        } else {
          setIsOpen(false);
        }
      };

      window.addEventListener('scroll', handleScroll, true);
      return () => window.removeEventListener('scroll', handleScroll, true);
    }
  }, [isOpen, handleChangeOpenStatus]);

  const role = useRole(context);
  const click = useClick(context);
  const dismiss = useDismiss(context, {
    outsidePress: true,
    outsidePressEvent: 'mousedown',
    referencePress: false,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([role, click, dismiss]);

  return (
    <div className={twMerge('relative', className)}>
      {children}
      {cloneElement(button, {
        ...(button.props ?? {}),
        ref: refs.setReference,
        ...getReferenceProps(),
      })}
      <FloatingPortal>
        {isOpen && (
          <div
            {...(floating.props ?? {})}
            ref={node => {
              if (typeof refs.setFloating === 'function') {
                refs.setFloating(node);
              }
              floatingRef.current = node;
            }}
            style={{ ...floatingStyles }}
            {...getFloatingProps()}
          >
            {floating.props.children}
            {showArrow && <FloatingArrow className={arrowClassName} context={context} ref={arrowRef} />}
          </div>
        )}
      </FloatingPortal>
    </div>
  );
};

export default Popper;
