import { BLOCKS } from '@contentful/rich-text-types';
import { htmlStringToDocument } from 'contentful-rich-text-html-parser';
import isString from 'lodash.isstring';
import { useTranslation } from 'next-i18next';
import { ComponentProps, useEffect, useState } from 'react';

import { paintguidePageSlugs } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { RichText } from '@boss/rich-text';
import { IEnrichedQuestion, IOption, IPaintsystem, IProduct, IStore } from '@boss/services';
import { OverviewFields, SimpleColor } from '@boss/types/b2b-b2c';
import { Button } from '@boss/ui';
import { toTitleCase } from '@boss/utils';

import { useAccount, useAccountStore, useColorByColorId } from '../../client-queries';
import {
  MailPaintguideButton,
  PaintguideResultsWrapper,
  PaintguideWrapper,
  SavePaintguideButton,
} from '../../components';
import { B2C_ID_ORDER, CUSTOM_CALC, CUSTOM_COLOR, CUSTOM_COLOR_BASE, RESULTS } from '../../constants';
import {
  getPaintguideStepsFromQuestions,
  isB2b,
  stepperVariant as pageVariant,
  paintguideQuestionAttributes,
} from '../../utils';

type Props = {
  finishProduct: IProduct | null | undefined;
  supportProduct: IProduct | null | undefined;
  questions: Record<string, IOption>;
  paintguideOverviewPage: OverviewFields | null;
  colorGroups: SimpleColor[] | null;
  enrichedQuestions?: Record<string, IEnrichedQuestion>;
  additionalInfo?: IPaintsystem['textblocks'];
  pretreatmentProduct?: IProduct | null | undefined;
  wallpaperApplicationProduct?: IProduct | null | undefined;
  aftertreatmentProduct?: IProduct | null | undefined;
};

type Steps = ComponentProps<typeof PaintguideWrapper>['steps'];

const QUESTIONS = paintguideQuestionAttributes;
const CURRENT_STEP = Object.values(QUESTIONS).length - 1;
const QUESTION_ATTRIBUTES = Object.values(QUESTIONS);

const InfoBlock = ({ text, locale }: { text: string[]; locale: string }) => {
  const infoDocument = htmlStringToDocument(text.join(''));

  return (
    <RichText
      content={infoDocument}
      mapperOptions={{ locale }}
      renderNode={{
        [BLOCKS.HEADING_2]: (_, children) => <h2 className="md:text-2.5xl text-red-dark mb-4 text-2xl">{children}</h2>,
        [BLOCKS.HEADING_3]: (_, children) => <h3 className="text-blue mb-3 text-xl md:text-2xl">{children}</h3>,
        [BLOCKS.HEADING_4]: (_, children) => <h4 className="bold mb-1 text-base md:mb-0">{children}</h4>,
        [BLOCKS.PARAGRAPH]: (_, children) => <p className="mb-8 text-base">{children}</p>,
      }}
    />
  );
};

/**
 * Recursively flatmap and enrich textblock entries with html tags
 *
 * @param level - For every level the recursion passes, another html tag is needed
 * @returns array of html strings
 */
const additionalInfoToHtml = ({
  textBlocks,
  level = 0,
  titleMapping,
}: {
  textBlocks: IPaintsystem['textblocks'];
  level?: number;
  titleMapping: Record<string, string>;
}) => {
  if (!textBlocks?.length) {
    return [];
  }

  const headerTagMap = ['h2', 'h3', 'h4', 'h5'];
  const tag = headerTagMap[level] ?? 'p';

  const nodes: string[] = [];

  for (const block of textBlocks) {
    const name = titleMapping[block?.name?.toLowerCase()] || block.name;

    nodes.push(`<${tag}>${toTitleCase(name)}</${tag}>`);

    if (block.textblocks) {
      // Recursion point
      nodes.push(...additionalInfoToHtml({ textBlocks: block.textblocks, level: level + 1, titleMapping }));
    }
    if (block.texts) {
      nodes.push(...block.texts);
    }
  }

  return nodes;
};

const PaintGuideResultPage = ({
  paintguideOverviewPage,
  colorGroups,
  finishProduct,
  supportProduct,
  questions,
  enrichedQuestions,
  additionalInfo = null,
  pretreatmentProduct,
  wallpaperApplicationProduct,
  aftertreatmentProduct,
}: Props) => {
  const { t } = useTranslation('paintguide');

  const titleMapping: Record<string, string> = t('titleMapping', { returnObjects: true }) || {};
  const infoBlock = additionalInfo ? additionalInfoToHtml({ textBlocks: additionalInfo, titleMapping }) : [];

  const [steps, setSteps] = useState<Steps>([]);
  const { locale, query, push: routerPush } = useRouter();
  const [preCalculatedSurface, setPreCalculatedSurface] = useState(0);
  const [selectedColorCode, setSelectedColorCode] = useState('');
  const [selectedColorBase, setSelectedColorBase] = useState('');
  const [savedStore, setSavedStore] = useState<IStore>();
  const { data: selectedColor } = useColorByColorId(selectedColorCode);

  const { data: accountStore } = useAccountStore(locale);
  const { data: account } = useAccount(locale);

  const handleStepChange = (stepIndex: number) => {
    const query: Record<string, string> = {};

    for (let i = 0; i < stepIndex; i++) {
      const id = B2C_ID_ORDER[i];
      const queryKey = `q${i + 1}`;

      if (questions[id]) {
        query[queryKey] = questions[id].propertyValueId;
      }
      if (id === CUSTOM_CALC) {
        query.sqm = String(preCalculatedSurface);
      }
      if (id === CUSTOM_COLOR) {
        query.color = selectedColorCode;
      }
      if (id === CUSTOM_COLOR_BASE) {
        query.colorBase = selectedColorBase;
      }
    }

    routerPush({
      pathname: `/${paintguidePageSlugs[locale]}`,
      query,
    });
  };

  useEffect(() => {
    if (accountStore) {
      setSavedStore(accountStore);
    }
  }, [accountStore]);

  /** Set and Translate steps on locale switch */
  useEffect(() => {
    const sqm = query.sqm && !isNaN(Number(query.sqm)) ? query.sqm : 0.0;
    const _steps = getPaintguideStepsFromQuestions(QUESTION_ATTRIBUTES, t, questions, enrichedQuestions).map(step => {
      if (step.id === CUSTOM_CALC) {
        return {
          label: enrichedQuestions?.[CUSTOM_CALC]?.question ?? t(CUSTOM_CALC) ?? '',
          selected: String(sqm),
          id: CUSTOM_CALC,
        };
      }

      if (step.id === CUSTOM_COLOR && selectedColor) {
        return {
          label: enrichedQuestions?.[CUSTOM_COLOR]?.question ?? t(CUSTOM_COLOR) ?? '',
          selected: (
            <div className="flex min-w-20 flex-col items-center text-xs">
              {selectedColor.name && <span className="text-xs">{selectedColor.name}</span>}
              {selectedColor.code && <span className="text-xs">{selectedColor.code}</span>}
            </div>
          ),
          color: selectedColor.rgb,
          id: CUSTOM_COLOR,
        };
      }

      return step;
    });

    setSteps(_steps);
  }, [locale, t, query.sqm, questions, selectedColor, enrichedQuestions]);

  useEffect(() => {
    if (query.sqm && !isNaN(Number(query.sqm))) {
      const sqm = Number(query.sqm);

      setPreCalculatedSurface(sqm);
    }

    if (query.color && isString(query.color)) {
      const [colorCode, colorBase] = query.color.split('+');

      setSelectedColorCode(colorCode);
      setSelectedColorBase(colorBase);
    }
  }, [query]);

  return (
    <PaintguideWrapper
      currentStep={CURRENT_STEP}
      enrichedQuestion={enrichedQuestions?.[RESULTS]}
      handleStepChange={handleStepChange}
      id="paintguideResultPage"
      onlyRouting
      paintguideOverviewPage={paintguideOverviewPage}
      steps={steps}
      variant={pageVariant}
      visible={!!steps.length}
    >
      <PaintguideResultsWrapper
        aftertreatmentProduct={aftertreatmentProduct}
        colorGroups={colorGroups}
        finishProduct={finishProduct}
        preCalculatedSurface={preCalculatedSurface}
        pretreatmentProduct={pretreatmentProduct}
        selectedColor={selectedColor}
        selectedColorBase={selectedColorBase}
        showBaseColors={!!account?.accountinfo?.mixingmachine}
        store={savedStore}
        supportProduct={supportProduct}
        wallpaperApplicationProduct={wallpaperApplicationProduct}
        withCounter
      />
      <div className="xl:ml-80">
        {infoBlock && <InfoBlock locale={locale} text={infoBlock} />}
        <div className="mb-20 flex flex-col gap-4 md:flex-row md:items-center">
          <Button className="ml-auto px-6 py-4" href={paintguidePageSlugs[locale]} label={t('redo')} type="primary" />
          {!isB2b && <SavePaintguideButton />}
          <MailPaintguideButton type="primary" />
        </div>
      </div>
    </PaintguideWrapper>
  );
};

export default PaintGuideResultPage;
