import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import debounce from 'lodash.debounce';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { DEFAULT_EXIT_ANIMATION, TRANSITION_TIMING_FAST } from '@boss/constants/b2b-b2c';
import { NavigationItem, Nullable } from '@boss/types/b2b-b2c';

import { Highlight, MainNavItem, SubNavLink } from './links';
import Link from '../Link';
import Presence from '../Presence';

type Props = {
  type: 'primary' | 'secondary';
  nav: NavigationItem[];
  onOpenMenu?: (state: boolean) => void;
  onCloseMenu: () => void;
  className?: string;
};

type MenuProps = {
  closeMenu: (isLink: boolean) => void;
  menu: NavigationItem[];
  initialHighlights: Nullable<NavigationItem[]>;
  type: 'primary' | 'secondary';
};

type MenuLinks = {
  children: Nullable<NavigationItem[]>;
  highlights: Nullable<NavigationItem[]>;
};

const FOCUSABLE_ELEMENTS = 'a, button, [tabindex="0"]';

const classes = {
  primary: {
    firstColumnClass: 'px-3 bg-green-light',
    subNavLinkClass:
      'relative py-3 text-lg before:opacity-0 before:block before:h-0 before:content-[""] before:-left-8 before:w-[calc(100%+2rem)] before:bg-blue before:h-full before:absolute before:rounded-r-8.75 focus:outline-none focus-visible:before:opacity-100 focus-visible:text-white',
    linkClass: 'text-lg whitespace-nowrap focus:outline-none focus-visible:font-medium focus-visible:text-blue',
    lastColumnClass: 'h-full bg-beige px-10 py-6 flex flex-col gap-4',
    middleColum: '',
    activeState: 'before:opacity-100 text-white',
  },
  secondary: {
    firstColumnClass: 'px-0 bg-white  border-white-dark',
    subNavLinkClass:
      '[&>*]:text-brown relative whitespace-nowrap leading-20 [&>*]:hover:text-white hover:font-bold relative py-3 focus:outline-none [&>*]:focus-visible:text-white focus-visible:font-bold',
    linkClass: 'leading-20 whitespace-nowrap text-brown focus:outline-none focus-visible:font-bold',
    lastColumnClass: 'h-full bg-white-dark px-4 py-4 flex flex-col gap-4',
    activeState: '',
    middleColum: 'border-l-1',
  },
};

const MenuStyling = cva('grid justify-between', {
  variants: {
    type: {
      primary: 'grid grid-cols-3 min-h-100',
      secondary: 'grid-cols-3',
    },
  },
});

const MenuWrapperStyling = cva('absolute left-0 top-full mx-5 md:mx-8 xl:m-0 flex shadow-sm items-center bg-white', {
  variants: {
    type: {
      primary: 'overflow-hidden rounded-bl-11 rounded-br-11',
      secondary: '',
    },
  },
});

const ActiveLinkStyling = cva('', {
  variants: {
    type: {
      primary: 'text-blue font-medium',
      secondary: 'font-bold',
    },
  },
});

const Menu = ({ menu, initialHighlights, type, closeMenu }: MenuProps) => {
  const [children, setChildren] = useState<Nullable<NavigationItem[]>>(null);
  const [highlights, setHighlights] = useState<Nullable<NavigationItem[]>>(initialHighlights);
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const [activeLinkChild, setActiveLinkChild] = useState<string | null>(null);

  const { firstColumnClass, subNavLinkClass, linkClass, lastColumnClass, activeState, middleColum } = classes[type];

  const handleInteraction = (
    id: string,
    children: Nullable<NavigationItem[]>,
    highlightedChildren: Nullable<NavigationItem[]>,
    secondChildrenColumn?: boolean,
  ) => {
    setChildren(current => (secondChildrenColumn ? current : children));
    setHighlights(() => (highlightedChildren?.length ? highlightedChildren : initialHighlights));
    setActiveLink(currentId => (secondChildrenColumn ? currentId : id));
    setActiveLinkChild(() => (secondChildrenColumn ? id : null));
  };

  useEffect(() => {
    setChildren(null);
    setHighlights(initialHighlights);
  }, [initialHighlights, menu]);

  return (
    <div className={MenuWrapperStyling({ type })}>
      <div className={MenuStyling({ type })}>
        <ul className={`py-6 ${firstColumnClass}`}>
          {menu.map(({ icon, id, label, childNavigationItems, highlightedChildNavigationItems, href }, i) => {
            const active = activeLink === id;

            return (
              label && (
                <li
                  key={`${id}_${i}`}
                  onFocus={() => handleInteraction(id, childNavigationItems, highlightedChildNavigationItems)}
                  onMouseEnter={() => handleInteraction(id, childNavigationItems, highlightedChildNavigationItems)}
                >
                  <SubNavLink
                    active={type === 'primary' ? active : false}
                    className={twMerge(
                      'flex cursor-pointer items-center justify-between',
                      subNavLinkClass,
                      active && activeState,
                    )}
                    closeMenu={() => closeMenu(!!href)}
                    hasChildren={!!childNavigationItems?.length}
                    href={href}
                    icon={icon}
                    label={label}
                    onKeyDown={(e: React.KeyboardEvent) => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        handleInteraction(id, childNavigationItems, highlightedChildNavigationItems);
                      }
                      if (e.key === 'ArrowRight' && childNavigationItems?.length) {
                        e.preventDefault();
                        handleInteraction(id, childNavigationItems, highlightedChildNavigationItems);
                        const submenu = document.getElementById('subnav-mainChildren-presence');
                        const firstFocusableElement = submenu?.querySelector(FOCUSABLE_ELEMENTS) as HTMLElement;

                        firstFocusableElement?.focus();
                      }
                    }}
                    type={type}
                  />
                </li>
              )
            );
          })}
        </ul>

        <ul className={twMerge('px-10 py-6', middleColum)}>
          <Presence id="subnav-mainChildren-presence" transition={TRANSITION_TIMING_FAST} visible={!!children?.length}>
            {children?.map(({ id, label, href, childNavigationItems, highlightedChildNavigationItems }, i) => {
              const hasChildren = !!childNavigationItems?.length;

              if (!href && !hasChildren) {
                return null;
              }

              return (
                <li
                  className={twMerge('leading-20 flex')}
                  key={`${id}_${i}`}
                  onMouseEnter={() =>
                    handleInteraction(id, childNavigationItems, highlightedChildNavigationItems, true)
                  }
                >
                  <Link
                    anchorClassName={twMerge('w-full cursor-pointer', linkClass)}
                    className={twMerge('flex w-full items-center justify-between py-3')}
                    href={href}
                    onClick={() => closeMenu(!!href)}
                  >
                    <div className="relative w-full">
                      <span
                        className={twMerge(
                          'absolute left-0 top-1/2 block w-full -translate-y-[50%] truncate py-3',
                          activeLinkChild === id ? ActiveLinkStyling({ type }) : '',
                        )}
                      >
                        {label}
                      </span>
                      <span className="invisible py-3 font-medium">{label}</span>
                    </div>
                    {type === 'primary' && hasChildren && <FontAwesomeIcon className="ml-4" icon={faChevronRight} />}
                  </Link>
                </li>
              );
            })}
          </Presence>
        </ul>

        <Presence id="subnav-highlights-presence" visible={!!highlights?.length}>
          <ul className={`${lastColumnClass}`}>
            {highlights?.map(
              ({ id, href, image, label }, i) =>
                href &&
                image &&
                label && (
                  <li key={`${id}_${i}`}>
                    <Highlight closeMenu={closeMenu} image={image} label={label} slug={href} type={type} />
                  </li>
                ),
            )}
          </ul>
        </Presence>
      </div>
    </div>
  );
};

const SubNav = ({ nav, type, onCloseMenu, onOpenMenu, className }: Props) => {
  const [menu, setMenu] = useState<MenuLinks>({ children: null, highlights: null });
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState<string | null>(null);

  // Add ref for the submenu
  const subMenuRef = useRef<HTMLDivElement>(null);

  const menuReset = () => {
    setMenu({ children: null, highlights: null });
    setActiveLink(null);
    onOpenMenu?.(false);
    setSubMenuIsOpen(false);
  };

  // Add focus management when submenu opens
  useEffect(() => {
    if (subMenuIsOpen && subMenuRef.current) {
      const firstFocusableElement = subMenuRef.current.querySelector(FOCUSABLE_ELEMENTS) as HTMLElement;

      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    }
  }, [subMenuIsOpen]);

  const handleCloseMenu = (isLink: boolean) => {
    onCloseMenu();
    if (isLink) {
      menuReset();
    }
  };

  const handleMouseClick = (id: string, menu: MenuLinks) => {
    if (id === activeLink && subMenuIsOpen) {
      menuReset();
    } else {
      setMenu(menu);
      setActiveLink(id);
      setSubMenuIsOpen(true);
      onOpenMenu?.(!!menu.children?.length);

      // Focus the first submenu item after a short delay to ensure the menu is rendered
      setTimeout(() => {
        const firstSubNavLink = subMenuRef.current?.querySelector(FOCUSABLE_ELEMENTS) as HTMLElement;

        firstSubNavLink?.focus();
      }, 0);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      menuReset();
    }

    // Prevent tab navigation to next MainNavItem when submenu is open
    if (e.key === 'Tab' && subMenuIsOpen) {
      const currentTarget = e.target as HTMLElement;
      const isMainNavItem = currentTarget.closest('[role="menuitem"]');

      if (isMainNavItem) {
        e.preventDefault();
        const firstSubNavLink = subMenuRef.current?.querySelector(FOCUSABLE_ELEMENTS) as HTMLElement;

        firstSubNavLink?.focus();
      }
    }
  };

  const debouncedMenuReset = debounce(menuReset, 500);

  return (
    <nav
      aria-label="Sub navigation menu"
      className={className}
      onClick={() => debouncedMenuReset.cancel()}
      onKeyDown={handleKeyDown}
      onMouseLeave={() => debouncedMenuReset()}
      role="navigation"
    >
      <div className="flex items-center" role="menubar">
        {nav.map(
          ({ id, label, href, childNavigationItems, highlightedChildNavigationItems }, i) =>
            label && (
              <MainNavItem
                active={activeLink === id}
                aria-expanded={activeLink === id && subMenuIsOpen}
                aria-haspopup={!!childNavigationItems?.length}
                hasChildren={!!childNavigationItems?.length}
                href={href}
                key={`${id}_${i}`}
                onClick={() =>
                  handleMouseClick(id, {
                    children: childNavigationItems,
                    highlights: highlightedChildNavigationItems,
                  })
                }
                role="menuitem"
                tabIndex={0}
                type={type}
              >
                {label}
              </MainNavItem>
            ),
        )}
      </div>

      <Presence
        exit={{ ...DEFAULT_EXIT_ANIMATION, pointerEvents: 'none' }}
        id="subnav-menu-presence"
        initial={{ ...DEFAULT_EXIT_ANIMATION, pointerEvents: 'auto' }}
        visible={!!menu.children?.length}
      >
        {!!menu.children?.length && subMenuIsOpen && (
          <div ref={subMenuRef}>
            <Menu closeMenu={handleCloseMenu} initialHighlights={menu.highlights} menu={menu.children} type={type} />
          </div>
        )}
      </Presence>
    </nav>
  );
};

export default SubNav;
