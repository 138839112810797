import { twMerge } from 'tailwind-merge';

import { NumberCardProps } from '@boss/types/b2b-b2c';

const NumberCard = ({ number, unit, description, className, textClassName }: NumberCardProps) => (
  <div
    className={twMerge(
      'bg-green-sheen flex h-full flex-col gap-2 p-4 font-extralight text-white sm:gap-5 sm:p-6',
      className,
      textClassName,
    )}
  >
    <div className="flex items-center gap-2.5">
      <span>{number}</span>
      {unit && <span>{unit}</span>}
    </div>
    {description && <div className="text-xl">{description}</div>}
  </div>
);

export default NumberCard;
