import { faLaptop } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { useIsResponsive } from '@boss/hooks';
import { BasicVariant } from '@boss/types/b2b-b2c';

import { InfoLabel, StatusIcon, Warning } from './microComponents';
import Button from '../Button';

type Translations = {
  paid: string;
  date: string;
  orderNumber: string;
  download: string;
  detailPage: string;
  warning: string;
  readMore: string;
  products: string;
  status: string;
  online: string;
  on: string;
  orderDate: string;
  trackAndTrace: string;
};

type OrderLine = {
  name: string;
  quantity: number;
  unit: string;
  id: string;
  colorName?: string;
  colorCode?: string;
  imageUrl?: string;
  myMx?: boolean;
};

export type Props = {
  status: string;
  variant: BasicVariant;
  deliveryStatus: 'onTheMove' | 'ready';
  orderNumber?: string;
  warningMessage?: string;
  className?: string;
  warningLink?: string;
  translations: Translations;
  customerReference?: string;
  distributionDescription?: string;
  deliveryLocation?: string;
  receiptDateRequested?: string;
  online: boolean;
  paid: boolean;
  orderCreationDate: string;
  tourInfo?: string;
  orderLines: OrderLine[];
  href: string;
  trackAndTrace?: string;
};

const InfoStyles = cva('mb-3 flex justify-between [&>*]', {
  variants: {
    variant: {
      primary: 'md:justify-start',
      secondary: 'md:justify-end',
    },
  },
});

const CardStyles = cva('grid grid-cols-1 p-4 md:grid-cols-3 items-center', {
  variants: {
    variant: {
      primary: 'bg-green-light rounded-md md:rounded-lg md:grid-cols-4 p-2 md:p-4',
      secondary: 'shadow-lg',
    },
  },
});

const OrderPreview = ({
  status,
  variant,
  orderNumber,
  deliveryStatus,
  warningMessage,
  className,
  warningLink,
  translations,
  customerReference,
  distributionDescription,
  deliveryLocation,
  receiptDateRequested,
  online,
  orderCreationDate,
  tourInfo,
  orderLines,
  href,
  paid,
  trackAndTrace,
}: Props) => {
  const isMobile = useIsResponsive();
  const orderLinesToShow = isMobile ? 2 : 3;

  return (
    <div className={twMerge('max-w-full', className)} data-testid="order-card">
      <div className="relative mb-4 flex items-baseline justify-between">
        <span className="flex items-baseline gap-2">
          <StatusIcon className="mr-2" status={deliveryStatus} />
          <h5 className="font-semibold">
            {distributionDescription} {deliveryLocation}
          </h5>
          {receiptDateRequested && (
            <span>
              {translations.on} {receiptDateRequested}
            </span>
          )}
          {tourInfo && <span>{tourInfo}</span>}
        </span>
        <div>
          {paid && <span className="mr-5">{translations.paid}</span>}
          {online && (
            <span>
              <FontAwesomeIcon className="mr-2" icon={faLaptop} />
              {translations.online}
            </span>
          )}
        </div>
      </div>
      <div className={InfoStyles({ variant })}>
        {customerReference && variant === 'secondary' && (
          <p className="text-blue-dark mr-auto hidden text-xl md:block">{customerReference}</p>
        )}
        <InfoLabel label={translations.orderDate} value={orderCreationDate} />
        {orderNumber && <InfoLabel className="md:ml-4" label={translations.orderNumber} value={orderNumber} />}
        {status && <InfoLabel className="md:ml-4" label={translations.status} value={status} />}
      </div>
      <div className={CardStyles({ variant })}>
        {variant === 'primary' ? (
          <div className="flex flex-wrap md:col-span-3 md:p-4">
            {orderLines?.slice(0, orderLinesToShow).map(line => (
              <div className="flex w-1/3 flex-col items-center gap-2 p-4 md:w-1/4" key={line.id}>
                {line.imageUrl ? (
                  <img alt={line.name} className="mr-2 h-16 w-16 object-contain text-center" src={line.imageUrl} />
                ) : (
                  <div className="mr-2 h-16 w-16"></div>
                )}
                <div className="w-full break-words text-center text-sm">{line.name}</div>
              </div>
            ))}
            {orderLines?.length > orderLinesToShow && (
              <div className="flex w-1/3 items-center justify-center p-4 md:w-1/4">
                <span className="text-center text-lg">+{orderLines.length - orderLinesToShow}</span>
              </div>
            )}
          </div>
        ) : (
          <div className="md:col-span-2">
            {orderLines?.map(line => (
              <div className="grid grid-cols-3 md:grid-cols-6" key={line.id}>
                <span className="px-2 py-1">
                  {line.quantity} {line.unit}
                </span>
                <span className="col-span-2 px-2 py-1  md:col-span-3">
                  {line.name}
                  <span className="text-sm">
                    {(line.colorName ?? line.colorCode) && line.myMx
                      ? ` (${[line.colorCode, line.colorName].filter(Boolean).join(' - ')})`
                      : ''}
                  </span>
                </span>
                <span className="px-2 py-1 md:col-span-2">{line.id}</span>
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col items-center justify-self-end px-2 py-1">
          <Button href={href} label={translations.detailPage} type="primary" />
          {trackAndTrace && (
            <Button className="mt-3" href={trackAndTrace} label={translations.trackAndTrace} type="link" />
          )}
        </div>
      </div>
      {warningMessage && (
        <Warning className="mt-2" link={warningLink} translations={translations} warning={warningMessage} />
      )}
    </div>
  );
};

export default OrderPreview;
