import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import * as Yup from 'yup';

import { getConflictErrorMessage } from '@boss/boss-client';
import { IVoucher } from '@boss/services/client';
import { Accordion, Button, InputField, RadioGroup } from '@boss/ui';

export interface Props {
  className?: string;
  icon?: IconDefinition;
  onSubmit: (values: {
    type: string;
    discount: string;
    webcode: string;
  }) => Promise<{ success: boolean; error: unknown }>;
  variant?: 'mobile' | 'desktop';
  vouchers?: IVoucher[];
  onRemoveVoucher: (lineid: string) => void;
}

const DiscountButtonStyles = cva('flex flex-col py-2 gap-3 w-full bg-white', {
  variants: {
    variant: {
      mobile: 'rounded-modal',
      desktop: 'rounded-full',
    },
  },
});

const ErrorLabel = ({ error }: { error: string }) => <span className="annotation text-red mt-2">{error}</span>;

const DiscountSection = ({ icon, className, onSubmit, variant = 'mobile', vouchers, onRemoveVoucher }: Props) => {
  const { t } = useTranslation('basket', { keyPrefix: 'discount' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showDiscountSection, setShowDiscountSection] = useState(false);
  const INVALID_VALIDATION_KEY = 'validation.invalid';

  type ErrorMapKey =
    | 'voucher.inuse'
    | 'voucher.invalid'
    | 'voucher.expired'
    | 'voucher.invalid.webcode'
    | 'voucher.used'
    | 'voucher.not.activated';

  const { errors, touched, handleSubmit, values, setFieldValue, resetForm } = useFormik({
    initialValues: {
      type: 'giftvoucher',
      discount: '',
      webcode: '',
    },
    validateOnChange: true,
    enableReinitialize: false,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      discount: Yup.string().required(t('validation.required') ?? ''),
      webcode: Yup.string().when('type', {
        is: 'giftvoucher',
        then: schema => schema.required(t('validation.required') ?? ''),
        otherwise: schema => schema.optional(),
      }),
    }),
    onSubmit: async (values, { setFieldValue, setFieldError }) => {
      setIsSubmitting(true);
      const defaultError = t(INVALID_VALIDATION_KEY) ?? '';

      try {
        const response = await onSubmit(values);

        // Map of error types to their corresponding translation keys
        const errorMap = {
          'voucher.inuse': { field: 'discount', key: 'validation.inUse' },
          'voucher.invalid': { field: 'discount', key: INVALID_VALIDATION_KEY },
          'voucher.expired': { field: 'discount', key: 'validation.expired' },
          'voucher.invalid.webcode': { field: 'webcode', key: 'validation.invalidWebcode' },
          'voucher.used': { field: 'discount', key: 'validation.used' },
          'voucher.not.activated': { field: 'discount', key: 'validation.notActivated' },
        };

        if (response.success) {
          // Reset form and close section on success
          resetForm();
          setShowDiscountSection(false);
          return;
        }

        // Find first matching error type
        const errorType = Object.keys(errorMap).find(type => getConflictErrorMessage(response.error, type)) as
          | ErrorMapKey
          | undefined;

        if (errorType) {
          const { field, key } = errorMap[errorType];

          setFieldError(field, t(key) ?? defaultError);
        } else {
          // Default error if no specific error type matched
          setFieldError('discount', t(INVALID_VALIDATION_KEY) ?? defaultError);
        }
      } catch (error) {
        setFieldError('discount', defaultError);
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  const options = [
    { label: t('discountOptions.gift'), value: 'giftvoucher' },
    { label: t('discountOptions.voucher'), value: 'discountvoucher' },
  ];

  const handleTypeChange = (value: string) => {
    setFieldValue('type', value);
  };

  return (
    <div className={twMerge(DiscountButtonStyles({ variant }), className)}>
      {vouchers && !!vouchers.length && (
        <div className="flex flex-col gap-3">
          <div className="font-bold">{t('activeDiscounts')}</div>
          {vouchers.map(voucher => {
            const invalidVoucher = !voucher.isused;

            return (
              <div
                className={twMerge(
                  'flex items-center justify-between rounded-lg p-4 transition-colors',
                  invalidVoucher ? 'border border-red-200 bg-red-50' : 'bg-gray-100',
                )}
                key={voucher.lineid}
              >
                <div className="flex flex-col gap-1">
                  <div className="flex items-start gap-2">
                    <div className="font-medium">
                      {voucher.reference}
                      {invalidVoucher && (
                        <div className="mt-1 text-xs font-medium text-red-600">{t('validation.invalidVoucher')}</div>
                      )}
                    </div>
                  </div>
                  {!invalidVoucher && <span className="text-sm text-gray-600">{voucher.description}</span>}
                </div>
                <button
                  aria-label="Remove voucher"
                  className="ml-4 shrink-0 text-red-500 transition-colors hover:text-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => onRemoveVoucher?.(voucher.lineid)}
                >
                  <FontAwesomeIcon icon={faTrashCan} />
                </button>
              </div>
            );
          })}
        </div>
      )}
      <div>
        <Accordion
          hideDivider
          iconClassName="hidden"
          isOpen={showDiscountSection}
          setExpanded={setShowDiscountSection}
          title={
            <div className="items-bottom flex">
              {icon && <FontAwesomeIcon className="text-blue mr-3" icon={icon} size="sm" />}
              <div>
                <p className="small text-blue font-bold">{t('addDiscount')}</p>
              </div>
            </div>
          }
          wrapperClassName="w-full"
        >
          <div className="flex w-full flex-col gap-3">
            <RadioGroup
              className="flex flex-row gap-4"
              name="type"
              onChange={handleTypeChange}
              options={options}
              value={values.type}
            />

            <InputField
              error={errors.discount && touched.discount ? errors.discount : undefined}
              inputClassName="w-full"
              name="discount"
              onChange={e => setFieldValue('discount', e.target.value)}
              placeholder={t('inputPlaceholder.discountCode') ?? ''}
              value={values.discount}
            >
              {errors.discount && touched.discount ? <ErrorLabel error={errors.discount} /> : null}
            </InputField>
            {values.type === 'giftvoucher' && (
              <InputField
                error={errors.webcode && touched.webcode ? errors.webcode : undefined}
                name="webcode"
                onChange={e => setFieldValue('webcode', e.target.value)}
                placeholder={t('inputPlaceholder.webCode') ?? ''}
                value={values.webcode}
              >
                {errors.webcode && touched.webcode ? <ErrorLabel error={errors.webcode} /> : null}
              </InputField>
            )}
            <Button disabled={isSubmitting} label={t('applyDiscount')} onClick={() => handleSubmit()} type="primary" />
          </div>
        </Accordion>
      </div>
    </div>
  );
};

export default DiscountSection;
