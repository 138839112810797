import { useRouter } from 'next/router';

import { accountMagazineSubscriptionPageSlugs } from '@boss/constants/b2b-b2c';
import { StringWithAutoComplete } from '@boss/types/b2b-b2c';

import { columns } from './FormHelpers';
import { FormType, useFormField, useFormSubmit } from '../../hooks';
import { buildFormFields } from '../../utils';
import DynamicForm, { FormValues } from '../DynamicForm';
import { FormField } from '../Mappers/FormFieldMapper';

export const FORM_FIELD_KEYS = [
  'salutation',
  'firstname',
  'lastname',
  'email',
  'phonenumber',
  'storeid',
  'address',
  'street',
  'streetnumber',
  'bus',
  'zipcode',
  'city',
  'country',
  'message',
  'newsletter',
  'termsandconditions',
] as const;

export type FormFieldKey = (typeof FORM_FIELD_KEYS)[number];
type FieldOverwrite = {
  [key in FormFieldKey]: Partial<FormField>;
};

type Props = {
  type: FormType;
  fieldsToShow?: StringWithAutoComplete<FormFieldKey>[];
  fieldsOverwrite?: Partial<FieldOverwrite>;
  additionalFields?: FormField[];
};

const AdviceForm = ({ fieldsToShow: initialFieldsToShow, fieldsOverwrite, additionalFields, type }: Props) => {
  const fieldsToShow = [...(initialFieldsToShow ?? FORM_FIELD_KEYS)];
  const { onSubmit, isSubmitting, isSuccess, reset } = useFormSubmit();
  const { asPath, locale } = useRouter();

  const { salutation, firstname, lastname, address, email, phonenumber, newsletter, termsandconditions, message } =
    useFormField();

  const baseFields: FormField[] = [
    {
      ...salutation,
      colStyle: columns.ONE_THIRD,
    },
    {
      ...firstname,
      colStyle: columns.ONE_THIRD,
    },
    {
      ...lastname,
      colStyle: columns.ONE_THIRD,
    },
    address,
    email,
    phonenumber,
    message,
    {
      ...newsletter,
      colStyle: columns.FULL,
    },
    termsandconditions,
  ];

  const handleSubmit = (vals: FormValues) => {
    const values = {
      ...vals,
      newsletter: vals.newsletter ? vals.newsletter : undefined,
      magazine: asPath === accountMagazineSubscriptionPageSlugs[locale as string] ? true : undefined,
    };

    onSubmit(type, values);
  };

  return (
    <DynamicForm
      fields={buildFormFields(baseFields, fieldsToShow, additionalFields, fieldsOverwrite)}
      id={type}
      isSubmitting={isSubmitting}
      isSuccess={isSuccess}
      onCloseAlert={reset}
      onSubmit={handleSubmit}
      showRequiredInformation
      variant="light"
    />
  );
};

export default AdviceForm;
