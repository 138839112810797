import { IconDefinition, faChevronLeft, faChevronRight, faHouse } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

import { Crumble, Nullable } from '@boss/types/b2b-b2c';
import { cleanPath } from '@boss/utils';

import Link from '../Link';
import Skeleton from '../Skeleton';

type Props = {
  path: string;
  className?: string;
  crumbles?: Crumble[];
  loading?: boolean;
  homeLabel?: Nullable<string>;
  locale: string | undefined;
  icon?: IconDefinition;
};

const BreadCrumbs = ({
  crumbles: crumblesOverwrite,
  path,
  className,
  loading,
  homeLabel = 'Home',
  locale,
  icon,
}: Props) => {
  const pathArr = path.split('/');
  const current = cleanPath(pathArr?.pop() ?? '');
  const crumbles =
    crumblesOverwrite ??
    pathArr.map((crumb, i, arr) => ({
      href: arr.slice(0, i + 1).join('/'),
      label: cleanPath(crumb),
    }));

  // EXPLANATION:
  // Since all pages give the current page as last item, we need to take the before last item in the breadcrumb as the previous "button".
  // When that one is not available, we are on the level just below home, which results in Home being returned.
  const mobileCrumbles =
    crumbles.length >= 2 ? crumbles?.[crumbles.length - 2] : { href: `/${locale}`, label: homeLabel };

  if (loading) {
    return (
      <div className={twMerge('my-6 flex items-center gap-5 md:my-0 md:mb-12', className)}>
        <Skeleton className="h-5 w-20" />
        <Skeleton className="h-5 w-5" />
        <Skeleton className="h-5 w-20" />
      </div>
    );
  }

  return (
    <div
      className={twMerge('text-gray-dark flex w-full items-center md:mb-12 md:gap-5', className)}
      data-testid="breadcrumb"
    >
      <Link className="hidden md:flex" href={`/${locale}`}>
        <FontAwesomeIcon icon={icon ?? faHouse} />
      </Link>
      {!!crumbles.length && (
        <div className="hidden items-center gap-5 md:flex">
          <FontAwesomeIcon icon={faChevronRight} />
          {crumbles.map(
            ({ href, label }, i) =>
              label && (
                <Fragment key={i}>
                  {i === crumbles.length - 1 ? (
                    <span className="first-letter:uppercase">{label}</span>
                  ) : (
                    <Link anchorClassName="first-letter:uppercase" href={href}>
                      {label}
                    </Link>
                  )}
                  {(i < crumbles.length - 1 || !crumblesOverwrite) && <FontAwesomeIcon icon={faChevronRight} />}
                </Fragment>
              ),
          )}

          {!crumblesOverwrite && <span>{current}</span>}
        </div>
      )}
      {mobileCrumbles && (
        <div className="my-6 flex items-center md:hidden">
          {!crumblesOverwrite ? (
            <div className="flex items-center gap-5">
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="first-letter:uppercase">{current}</span>
            </div>
          ) : (
            <Link className="flex items-center gap-5" href={mobileCrumbles.href}>
              <FontAwesomeIcon icon={faChevronLeft} />
              <span className="first-letter:uppercase">{mobileCrumbles.label}</span>
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default BreadCrumbs;
