import { cva } from 'class-variance-authority';
import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { ButtonType, NavigationItem, Nullable, SimpleImage } from '@boss/types/corporate-jobs';

import { Button, Link } from '../../../';
import Image from '../../../Image';

type MainBarProps = {
  className?: string;
  handleLink: (link: NavigationItem) => void;
  highlightedButtonType?: ButtonType;
  highlightedLinks?: Nullable<NavigationItem[]>;
  highlightedButtonClassName?: string;
  isOpen?: boolean;
  links: Nullable<NavigationItem[]>;
  logo: Nullable<SimpleImage>;
  menuLabel?: string;
  activeLinkStyle?: string;
  linkStyle?: string;
};

const buttonChildStyle = cva('', {
  variants: {
    variant: {
      primary: '',
      secondary: '',
      tertiary: 'font-bold',
      link: '',
      mobileMenu: '',
    },
  },
});

const MainBar = ({
  logo,
  links,
  highlightedButtonType,
  highlightedLinks,
  handleLink,
  className,
  highlightedButtonClassName,
  linkStyle,
  activeLinkStyle,
}: MainBarProps) => {
  const { asPath } = useRouter();
  const pathRef = useRef('');

  useEffect(() => {
    pathRef.current = asPath;
  }, [asPath]);

  return (
    <div className={twMerge('flex items-center justify-between bg-white py-4', className)}>
      {logo && (
        <Link className="relative block h-9 w-fit" href="/">
          <Image alt={logo.imageAlt} className="object-contain" resizingBehavior="fill" src={logo.imageUrl} />
        </Link>
      )}

      {!!links?.length && (
        <div className="flex h-full items-center md:gap-3 lg:gap-12">
          {links.map(link => (
            <Link
              className={twMerge(
                `flex h-full flex-col justify-center text-center before:h-0 before:text-[1.125rem] before:font-bold before:opacity-0 before:content-[attr(title)]`,
                linkStyle,
                link.href === pathRef.current || `/${link.href}` === pathRef.current ? activeLinkStyle : '',
              )}
              href={link.href}
              key={link.id ?? `${link.href ?? 'main-link'}-${link.label}`}
              onClick={() => handleLink(link)}
              onMouseEnter={() => handleLink(link)}
              title={link.label}
            >
              {link.label}
            </Link>
          ))}
          {!!highlightedLinks?.length &&
            highlightedLinks.map(link => (
              <Button
                childClassName={buttonChildStyle({ variant: highlightedButtonType })}
                className=""
                href={link.href}
                key={link.id ?? `${link.href ?? 'main-link'}-${link.label}`}
                label={link.label}
                labelClassName={twMerge('button', highlightedButtonClassName)}
                type={highlightedButtonType ?? 'primary'}
              />
            ))}
        </div>
      )}
    </div>
  );
};

export default MainBar;
