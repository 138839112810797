import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';

type Props = {
  className?: string;
  onClick: () => void;
  translations: { showLess: string; showMore: string };
};

const ShowMore = ({ className, onClick, translations }: Props) => (
  <button className={twMerge('text-blue-dark', className)} onClick={onClick}>
    {translations.showMore}
    <FontAwesomeIcon icon={faChevronDown} />
  </button>
);

export default ShowMore;
