import { cva } from 'class-variance-authority';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { CampaignFields } from '@boss/types/b2b-b2c';

import Image from '../Image';
import BrushStroke from '../Strokes/Brush';
import RollerStroke from '../Strokes/Roller';

type Props = {
  text: ReactNode;
  backgroundTexture: CampaignFields['backgroundTexture'];
  callToAction?: JSX.Element;
  imageUrl: string;
  backgroundColor: CampaignFields['backgroundColor'];
};

const BrushStyling = cva('h-full w-auto overflow-visible', {
  variants: {
    backgroundColor: {
      'king-blue': 'fill-blue-dark',
      coral: 'fill-coral',
    },
  },
});

const CampaignBanner = ({ text, callToAction, imageUrl, backgroundTexture, backgroundColor }: Props) => (
  <div className="@container relative">
    <div className="min-h-55 pb-15 pt-15 relative z-10 grid grid-cols-6 gap-8 pl-4 md:grid-cols-10">
      <div className="col-span-5 md:col-span-4 md:col-start-2">
        <div className="relative z-10 flex flex-col gap-5 pl-7 text-white md:w-fit md:pl-0 md:pr-0 md:pr-2">
          {text}
          {callToAction}
        </div>
      </div>
      <div className="relative col-span-1 hidden md:col-span-2 md:col-start-8 md:inline">
        <Image className="aspect-[16/9] object-contain" src={imageUrl} width={500} />
      </div>
    </div>
    <div className="absolute left-[calc((100vw-100%)/-1.7)] top-0 z-0 h-full w-screen overflow-hidden md:left-0 md:w-full">
      {backgroundTexture === 'paint-brush-stroke' && (
        <BrushStroke className={twMerge(BrushStyling({ backgroundColor }), 'lg:overflow-auto')} />
      )}
      {backgroundTexture === 'paint-roller-stroke' && (
        <RollerStroke className={twMerge(BrushStyling({ backgroundColor }), 'lg:overflow-visible')} />
      )}
    </div>
  </div>
);

export default CampaignBanner;
