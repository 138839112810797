import { faMagnifyingGlass, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cva } from 'class-variance-authority';
import { useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { useOnClickOutside } from 'usehooks-ts';

import SearchMenu, { SearchResults } from '../SearchMenu';

interface Props {
  searchLabel?: string;
  onSearch: () => void;
  onCloseSearchMenu?: () => void;
  type?: 'primary' | 'secondary';
  className?: string;
  value?: string;
  setSearchValue: (value: string) => void;
  searchResults?: SearchResults;
  wrapperClassname?: string;
  onClearSearch?: () => void;
  suggestions?: JSX.Element;
  showSearchSuggestions?: boolean;
  onShowSearchSuggestions?: (val: boolean) => void;
}

const Styles = cva('flex h-12 w-full items-center px-6', {
  variants: {
    type: {
      primary: 'bg-gray-soft text-gray-dark rounded-9.5',
      secondary: 'text-brown-dark bg-gray-lightest md:bg-white',
    },
  },
});

const IconStyle = cva(null, {
  variants: {
    type: {
      primary: 'text-brown-dark',
      secondary: 'text-gray-dark',
    },
  },
});

const SearchBar = ({
  searchLabel,
  onCloseSearchMenu: handleCloseSearchMenu = () => undefined,
  onSearch,
  setSearchValue,
  value = '',
  type,
  className,
  searchResults,
  wrapperClassname,
  onClearSearch: handleClearSearch,
  suggestions,
  showSearchSuggestions,
  onShowSearchSuggestions,
}: Props) => {
  const inputRef = useRef<null | HTMLInputElement>(null);
  const searchMenuRef = useRef<HTMLDivElement | null>(null);
  const searchSuggestionsRef = useRef<HTMLSpanElement | null>(null);

  // Handle escape key for both menus
  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      handleCloseSearchMenu();
      if (onShowSearchSuggestions) {
        onShowSearchSuggestions(false);
      }
    }
  };

  // Add event listener for escape key
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useOnClickOutside(searchMenuRef, handleCloseSearchMenu);

  const handleSearch = (key: string) => {
    if (key === 'Enter') {
      onSearch();
    } else if (key === 'ArrowDown') {
      // Focus on first result/suggestion if available
      if (searchResults?.length && value) {
        const firstResult = searchMenuRef.current?.querySelector('a, button');

        if (firstResult instanceof HTMLElement) {
          firstResult.focus();
        }
      } else if (showSearchSuggestions && suggestions && value?.length < 3) {
        const firstSuggestion = searchSuggestionsRef.current?.querySelector('a, button');

        if (firstSuggestion instanceof HTMLElement) {
          firstSuggestion.focus();
        }
      }
    }
  };

  const handleClick = () => onSearch();

  const clearInput = () => {
    setSearchValue('');
    if (handleClearSearch) {
      handleClearSearch();
    }
  };

  return (
    <div className={twMerge('relative w-full', wrapperClassname)}>
      <div
        aria-controls="search-dropdown"
        aria-expanded={!!(searchResults?.length || (showSearchSuggestions && suggestions))}
        aria-haspopup="listbox"
        className={twMerge(Styles({ type }), className)}
        role="combobox"
      >
        <input
          aria-autocomplete="list"
          aria-label="search-bar"
          autoComplete="off"
          className="mr-2.5 w-full border-transparent bg-transparent p-0 outline-none focus:border-transparent focus:ring-0"
          name="search"
          onChange={e => setSearchValue(e.target.value)}
          onFocus={onShowSearchSuggestions ? () => onShowSearchSuggestions(true) : undefined}
          onKeyDown={e => handleSearch(e.key)}
          placeholder={searchLabel}
          ref={inputRef}
          type="text"
          value={value}
        />
        {!!value && handleClearSearch && (
          <span className="mr-2 cursor-pointer" onClick={clearInput}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
        )}
        <span className="cursor-pointer" onClick={handleClick}>
          <FontAwesomeIcon className={IconStyle({ type })} icon={faMagnifyingGlass} />
        </span>
      </div>
      {!!searchResults?.length && value && (
        <div
          className={twMerge('z-100 absolute top-12 w-full bg-white px-5 shadow-lg', className)}
          id="search-dropdown"
          ref={searchMenuRef}
          role="listbox"
        >
          <SearchMenu query={value} results={searchResults} />
        </div>
      )}
      {showSearchSuggestions && !!suggestions && value?.length < 3 && (
        <span
          className={twMerge('z-100 absolute top-12 w-full bg-white px-5 shadow-lg', className)}
          ref={searchSuggestionsRef}
        >
          {suggestions}
        </span>
      )}
    </div>
  );
};

export default SearchBar;
