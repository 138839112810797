import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CHANNEL } from '@boss/constants/b2b-b2c';
import { useIsSSR } from '@boss/hooks';
import { ColorGroup } from '@boss/types/b2b-b2c';

import { useColorByColorId } from '../../client-queries';
import { COLOR_TYPE, INVERSED_COLOR_TYPE } from '../../constants/product';
import { useProfile } from '../../hooks';
import { colorsConfig } from '../../utils';

type Options = {
  showBaseColors?: boolean;
  colorGroups?: ColorGroup[];
  colorTypes?: string[] | null;
  colorId?: string;
  colorGroupType?: string;
  productId?: string;
  showSamples?: boolean;
};

export type ProductOption = {
  key: string;
  value: string;
};

const getOptionToSet = ({
  productOptions,
  colorId,
  colorGroupType,
}: {
  productOptions: ProductOption[];
  colorId?: string;
  colorGroupType?: string;
}) => {
  if (colorGroupType && productOptions.some(option => option.key === colorGroupType)) {
    return productOptions.find(option => option.key === colorGroupType) ?? productOptions[0];
  }

  const myMxColorOption = productOptions.find(option => option.key === COLOR_TYPE.MYMX);

  return colorId && myMxColorOption ? myMxColorOption : productOptions[0];
};

const getProductOptions = (
  colorTypes: string[],
  t: (key: string, options: { ns: string }) => string,
  isColora: boolean,
) => {
  // On Colora, only Mymx and premixed are applicable
  const colorTypeOptions = !isColora
    ? colorTypes
    : colorTypes.filter(colorType => colorType === COLOR_TYPE.MYMX || colorType === COLOR_TYPE.PREMIXED);

  return colorTypeOptions.map(c => ({
    key: c,
    value: t(INVERSED_COLOR_TYPE[c as keyof typeof INVERSED_COLOR_TYPE], { ns: 'product' }),
  }));
};

const useInitialColorBase = (isColora: boolean, colorId: string | undefined, isSSR: boolean, colorTypes: string[]) => {
  return useState<string>(!isColora && !colorId && !isSSR ? colorTypes?.[0] ?? COLOR_TYPE.MYMX : COLOR_TYPE.MYMX);
};

const useColorGroups = (colorGroups?: ColorGroup[]) => {
  const [selectedColorGroup, setSelectedColorGroup] = useState<ColorGroup>();

  useEffect(() => {
    if (!selectedColorGroup && colorGroups && colorGroups.length > 0) {
      setSelectedColorGroup(colorGroups[0]);
    }
  }, [colorGroups, selectedColorGroup]);

  return { selectedColorGroup, setSelectedColorGroup };
};

const useProductOptions = ({
  colorTypes,
  t,
  isColora,
  colorId,
  setColorBase,
  colorGroupType,
  productId,
}: {
  colorTypes: string[];
  t: (key: string) => string;
  isColora: boolean;
  colorId: string | undefined;
  setColorBase: (colorBase: string) => void;
  colorGroupType?: string;
  productId?: string;
}) => {
  const productOptions = useMemo(() => {
    return getProductOptions(colorTypes ?? [], t, isColora);
  }, [colorTypes, t, isColora]);

  const [activeProductOption, setActiveProductOption] = useState<ProductOption>();
  const [defaultProducOption, setDefaultProductOption] = useState<ProductOption>();

  useEffect(() => {
    if (colorId) {
      const optionToSet = getOptionToSet({ productOptions, colorId, colorGroupType });

      if (activeProductOption?.value !== optionToSet?.value) {
        setActiveProductOption(optionToSet);
        setColorBase(optionToSet.key);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorId]);

  useEffect(() => {
    /* When navigation from product A to product B,
     * reset the active option
     */
    const optionToSet = getOptionToSet({ productOptions, colorId, colorGroupType });

    if (optionToSet && productOptions.length && !activeProductOption && !defaultProducOption) {
      setDefaultProductOption(optionToSet);
      setColorBase(optionToSet.key);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, productOptions]);

  const handleProductOptionClick = (option: ProductOption) => {
    setActiveProductOption(option);
    if (option?.key && option.value) {
      setColorBase(option.key);
    }
  };

  return { productOptions, activeProductOption: activeProductOption ?? defaultProducOption, handleProductOptionClick };
};

const useColorsVisibility = (isSSR: boolean, isLoggedIn: boolean) => {
  return useMemo(() => {
    if (colorsConfig.visible === 'auth') {
      return !isSSR && isLoggedIn;
    }
    return colorsConfig.visible;
  }, [isSSR, isLoggedIn]);
};

export const useColors = ({
  colorGroups,
  colorTypes: colorTypeOptions,
  showBaseColors,
  colorId,
  colorGroupType,
  productId,
  showSamples = false,
}: Options) => {
  const colorTypes =
    colorTypeOptions?.filter(colorType => (colorType === COLOR_TYPE.BASE ? showBaseColors : true)) ?? [];
  const { data: preSelectedColor, isLoading: preSelectedColorIsLoading } = useColorByColorId(
    colorId ?? '',
    showSamples,
  );
  const isColora = CHANNEL === 'colora';
  const { isLoggedIn } = useProfile();
  const { t } = useTranslation('product');
  const isSSR = useIsSSR();

  const [colorBase, setColorBase] = useInitialColorBase(isColora, colorId, isSSR, colorTypes);

  const { selectedColorGroup, setSelectedColorGroup } = useColorGroups(colorGroups);
  const { productOptions, activeProductOption, handleProductOptionClick } = useProductOptions({
    colorTypes,
    t,
    isColora,
    colorId,
    setColorBase,
    colorGroupType,
    productId,
  });

  const showColors = useColorsVisibility(isSSR, isLoggedIn);

  return {
    showColors,
    productOptions,
    selectedColorGroup,
    handleProductOptionClick,
    activeProductOption,
    colorBase,
    setColorBase,
    setSelectedColorGroup,
    preSelectedColor,
    preSelectedColorIsLoading,
  };
};

export default useColors;
