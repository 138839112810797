import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { timeToString, toSentenceCase } from '@boss/utils';

import Link from '../Link';

type Props = {
  locale: string;
  appointmenttype: string;
  startdatetime: string;
  worksite: {
    id: string;
    name: string;
    street: string;
    number: string;
    postbox: string;
    zipcode: string;
    city: string;
  };
};

const AppointmentCard = ({ locale, appointmenttype, startdatetime, worksite }: Props) => {
  const date = new Date(startdatetime);
  const fullAddress = `${worksite.street} ${worksite.number} ${worksite.postbox ?? ''}, ${worksite.zipcode} ${
    worksite.city
  }`;

  const weekDate = toSentenceCase(date.toLocaleString(locale, { weekday: 'long', day: 'numeric', month: 'numeric' }));
  const time = `${timeToString(date.getHours())}:${timeToString(date.getMinutes())}`;

  return (
    <div className="flex flex-col px-3 pb-5 pt-3 shadow-md" data-testid="appointment-card">
      <h4 className="mb-2.5">{weekDate}</h4>
      <p className="mb-2.5">{time}</p>
      <p className="mb-2.5">{appointmenttype}</p>
      <Link className="flex items-center" href={`https://maps.google.com/?q=${fullAddress}`}>
        <FontAwesomeIcon className="mr-2.5" icon={faLocationPin} />
        <div className="[&_span]:text-sm">
          <div>
            <span>{`${worksite.street} `}</span>
            <span>{worksite.number}</span>
            {worksite.postbox && <span>{worksite.postbox}</span>}
          </div>
          <div>
            <span>{`${worksite.zipcode} `}</span>
            <span>{worksite.city}</span>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default AppointmentCard;
