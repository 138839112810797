import { IArticle, ISearchArticle } from '@boss/types/b2b-b2c';

import { ArticleIdsObject, ArticleValues } from '../../components/ArticlesTable';
import { TAX_RATE } from '../../constants';

export const addTax = (val: number) => val * TAX_RATE + val;

/*
 * Additional logic implemented to ensure the second to last item gets an increase in quantity when its value is more than the last item.
 */
const hasSpillover = (articles: IArticle[], currentArticle: IArticle, index: number, totalPaint: number) => {
  if (index === articles.length - 2) {
    const lastArticle = articles[articles.length - 1];

    if (
      currentArticle.quantity &&
      totalPaint < currentArticle.quantity &&
      lastArticle.quantity &&
      totalPaint > lastArticle.quantity &&
      Math.ceil(totalPaint / lastArticle.quantity) * lastArticle.quantity >= currentArticle.quantity
    ) {
      return true;
    }
  }

  return false;
};

const sortArticlesDesc = (articles: IArticle[]) =>
  [...articles].sort((a, b) => ((a.quantity || 0) > (b.quantity || 0) ? -1 : 1));

const getBaseArticleValues = (article: IArticle): Omit<ArticleValues, 'quantity'> => ({ unit: article.unit });

// Disabling as the complexity is only 16 instead of 15 and I can't reduce the complexity due to the requirements it has to match
// eslint-disable-next-line sonarjs/cognitive-complexity
export const getArticlesForSurface = (amountOfPaint: number, articles?: IArticle[] | null) => {
  if (!articles?.length) {
    return {};
  }

  let totalPaint = amountOfPaint;
  const articlesNeeded: ArticleIdsObject = {};
  const articlesDesc = sortArticlesDesc(articles);

  while (totalPaint > 0) {
    const article: IArticle | undefined = articlesDesc.find((article: IArticle, index) => {
      if (!article.quantity) {
        return false;
      }

      if (hasSpillover(articlesDesc, article, index, totalPaint)) {
        totalPaint = article.quantity;
        return true;
      }

      return article.quantity <= totalPaint;
    });

    if (!article) {
      const smallestArticle = articlesDesc[articlesDesc.length - 1];

      if (smallestArticle.quantity) {
        const currentArticleValues = articlesNeeded[smallestArticle.id] ?? {};

        articlesNeeded[smallestArticle.id] = {
          ...getBaseArticleValues(smallestArticle),
          ...currentArticleValues,
          quantity: (currentArticleValues.quantity ?? 0) + 1,
        };
        totalPaint = 0;
      }
    } else if (article.quantity) {
      const amountOfArticles = Math.floor(totalPaint / article.quantity);

      articlesNeeded[article.id] = {
        ...getBaseArticleValues(article),
        quantity: amountOfArticles + (articlesNeeded[article.id]?.quantity ?? 0),
      };
      totalPaint -= amountOfArticles * article.quantity;
    }
  }

  return articlesNeeded;
};

export const getArticleById = (articleId: string, articles: IArticle[]) =>
  articles.find(article => article.id === articleId);

export const getTotalPriceArticles = (articleIdsObject: ArticleIdsObject, articles?: IArticle[]) => {
  if (!articles?.length) {
    return 0;
  }

  let total = 0;

  Object.entries(articleIdsObject).forEach(([articleId, articleValues]) => {
    const article = articles.find(article => article.id === articleId);
    const price = articleValues?.price;
    const quantityForSelectedPackaging =
      article?.packaging.units?.find(({ unit }) => unit === articleValues.unit)?.quantity ?? 1;

    if (price) {
      total += quantityForSelectedPackaging * articleValues.quantity * price;
    }
  });

  return total;
};

export const getProductArticlesByColorCode = <T extends ISearchArticle[] | IArticle[] | null>(
  articles: T,
  colorCode?: string,
) => {
  if (!articles || !colorCode) {
    return articles;
  }

  // Recasting of the array before filtering to avoid mixing both array types into one
  // return will be ISearchArticle[] | IArticle[] iso (ISearchArticle | IArticle)[]
  const arrToFilter: Array<(typeof articles)[number]> = articles;

  return arrToFilter.filter(article => article.color.code === colorCode);
};

export const getPackagingOptions = (article: {
  unit: string;
  packaging: { units?: [{ unit: string; quantity: number }] };
}) => [
  { name: article.unit, quantity: 1 },
  ...(article.packaging?.units?.map(unit => {
    return { name: unit.unit, quantity: unit.quantity };
  }) ?? []),
];
