import { Nullable } from '@boss/types/b2b-b2c';

import ApiException from './api-exception';

type FetchResponse = globalThis.Response;

/**
 * Handles API response and returns the JSON data or throws an error.
 * @function
 * @async
 * @param {Response} response - The HTTP response object.
 * @param {string} url - The URL of the request.
 * @throws {ApiException} Throws an error if the response is not successful.
 * @returns {Promise<Nullable<T>>} A promise that resolves to the JSON response data or null.
 */
export default async <T>(response: FetchResponse, url: string): Promise<Nullable<T>> => {
  if (response.status === 204) {
    // If the response status is 204, there was a successful request, but there is no content available
    return null;
  }

  if (response.status === 409) {
    // Handle 409 Conflict error
    const conflictData = await response.json();

    throw new ApiException('Conflict error', response.status, url, conflictData);
  }

  if (!response.ok) {
    throw await ApiException.fromResponse(response);
  }

  try {
    const text = await response?.text();

    return text ? JSON.parse(text) : null;
  } catch {
    // throw ApiException if the response is not parsable
    throw new ApiException('An error occured while parsing the fetch data', response.status, url);
  }
};
