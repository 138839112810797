import { IArticle, IProduct, ISearchProduct } from '@boss/types/b2b-b2c';
import { calcAmountOfpaintForSurface, calcTotalPaintVolume } from '@boss/utils';

import { getArticlesForSurface } from '../article';

export type PaintProduct = (IProduct | ISearchProduct) & {
  roller: number;
  brush: number;
  consumptionOptions: ConsumptionOption[];
  articles?: IArticle[];
};

type ConsumptionOption = {
  technique: string;
  usage: {
    average: number;
    min: number;
    max: number;
  };
};

type PaintCalculationParams = {
  product: PaintProduct | null | undefined;
  surface: number;
  layers?: number;
  technique?: string;
  consumptionType?: 'min' | 'average' | 'max';
  articles?: IArticle[];
};

type PaintCalculationResult = {
  amountOfPaintNeeded: number;
  exactTotalPaintNeeded: number;
  articlesNeeded: Record<string, { quantity: number }>;
  totalPaintNeeded: number;
  activeConsumption: number;
};

const getDefaultTechnique = (product: PaintProduct, consumptionOptions: ConsumptionOption[]) => {
  if (product.roller !== 0) {
    return (
      consumptionOptions.find(option => option.technique === 'roller')?.technique ?? consumptionOptions[0]?.technique
    );
  }
  if (product.roller === 0 && product.brush !== 0) {
    return (
      consumptionOptions.find(option => option.technique === 'brush')?.technique ?? consumptionOptions[0]?.technique
    );
  }
  return consumptionOptions[0]?.technique;
};

export const calculatePaintNeeded = ({
  product,
  surface,
  layers = 1,
  technique,
  consumptionType = 'average',
  articles,
}: PaintCalculationParams): PaintCalculationResult | null => {
  if (!product?.consumptionOptions?.length || !surface) {
    return null;
  }

  // Get the appropriate consumption option
  const activeTechnique = technique ?? getDefaultTechnique(product, product.consumptionOptions);
  const activeConsumptionOption =
    product.consumptionOptions.find(option => option.technique === activeTechnique) ?? product.consumptionOptions[0];

  const activeConsumption = activeConsumptionOption.usage[consumptionType];
  const amountOfPaintNeeded = calcAmountOfpaintForSurface(activeConsumption, surface);
  const exactTotalPaintNeeded = calcTotalPaintVolume(amountOfPaintNeeded, layers);
  const articlesNeeded = getArticlesForSurface(exactTotalPaintNeeded, articles ?? product.articles);

  // Calculate total paint needed based on articles
  const totalPaintNeeded = Object.entries(articlesNeeded).reduce((acc, [id, value]) => {
    const article = (articles ?? product.articles)?.find(a => a.id === id);

    return acc + value.quantity * (article?.quantity ?? 0);
  }, 0);

  return {
    amountOfPaintNeeded,
    exactTotalPaintNeeded,
    articlesNeeded,
    totalPaintNeeded,
    activeConsumption,
  };
};
