import { ComponentProps, ReactNode } from 'react';

import { eventPageSlugsB2B } from '@boss/constants/b2b-b2c';
import { useRouter } from '@boss/hooks';
import { IStore } from '@boss/services';
import { BossColor, Pathname, Result, SimpleColor } from '@boss/types/b2b-b2c';

import PrimaryProductPanel from './Primary';
import SecondaryProductPanel from './Secondary';
import { Alert, Button, ColorPicker, ColorPickerSkeleton, Divider, Presence, Tooltip } from '../';
import { ProductOption } from '../ColorPicker';
import { FlyoutTranslations } from '../ColorPicker/Secondary/ColorFlyout';
import Link from '../Link';
import { PriceProps } from '../Price';

type Translations = {
  amountOfPaintNecessary: string;
  priceLabel: string;
  deliveryOptions: string;
  grossPriceLabel: string;
  discountLabel: string;
  disclaimer?: string;
  disclaimerLink: string;
  noArticlesAvailableTitle: string;
  button: {
    addToBasket: string;
    outOfStock: string;
    addToWishlist: string;
    specialForm: string;
  };
  colorPicker: {
    chosenColor: string;
    chooseColorFromGroup: string;
    searchByColorName: string;
    changeColor: string;
    productOption: string;
    flyout: FlyoutTranslations;
  };
  paintCalculator: {
    calculateAmount: string;
    calculateSurface: string;
    surface: string;
    layers: string;
    layersDisclaimer: string;
    calculateSurfaceDisclaimer: string;
  };
  priceNote: {
    message: string;
  };
  errorTitle: string;
  errorSubtitle: string;
  infoTitle: string;
  infoSubtitle: string;
  specialDecoTitle: string;
  specialDecoSubTitle: string;
  specialDecoSubTitleLink: string;
};

type ProductOptionClick = ComponentProps<typeof ColorPicker>['onProductOptionClick'];

type ProductPanelProps = {
  isLoggedIn?: boolean;
  openOrderForm: () => void;
  colorOptions?: BossColor[];
  hasArticles: boolean;
  // TODO: Do not use props of another component as partial props. This leads to sloppy typing of components
  activeProductOption?: ProductOption;
  addToBasket: () => void;
  addToWishlist?: () => void;
  calculatorResult: Result;
  className?: string;
  colors?: SimpleColor[] | null;
  deliveryPromises?: ReactNode;
  disclaimerLink?: Pathname;
  enableCalculator?: boolean;
  enableColorPicker?: boolean;
  inStock?: boolean;
  onCalculateSurface?: () => void;
  onColorClick: (color: SimpleColor) => void;
  onChangeGroupClick: () => void;
  onProductOptionClick?: ProductOptionClick;
  onSearch: () => void;
  price?: PriceProps;
  productOptions?: ProductOption[];
  searchValue?: string;
  selectedColor?: SimpleColor;
  setSearchValue: (val: string) => void;
  specifications?: ReactNode[];
  table: ReactNode;
  translations: Translations;
  variant?: 'primary' | 'secondary';
  recentColors?: SimpleColor[];
  favoriteColors?: SimpleColor[];
  searchColors?: SimpleColor[];
  searchLoading?: boolean;
  hasSearched?: boolean;
  stock?: { [key: string]: string };
  savedStore?: IStore | null;
  isLoading?: boolean;
  hasError?: boolean;
  onCloseAlert?: () => void;
  onCloseInfo?: () => void;
  showProductActions?: boolean;
  alternativeActions?: ReactNode;
  colorType?: 'transparant' | 'default';
  isBasketUpdateLoading?: boolean;
  isSpecialDecoTechnique?: boolean;
  noArticlesAvailable?: boolean;
  infoMessage?: {
    title: string;
    message: string;
  };
};

export type ProductPanelInstanceProps = Omit<
  ProductPanelProps,
  | 'homeDelivery'
  | 'shopDelivery'
  | 'variant'
  | 'enableCalculator'
  | 'calculatorResult'
  | 'colors'
  | 'activeColor'
  | 'onColorClick'
  | 'onSearch'
  | 'onProductOptionClick'
  | 'onCalculateSurface'
  | 'onChangeGroupClick'
  | 'setSearchValue'
  | 'enableColorPicker'
  | 'recentColors'
  | 'favoriteColors'
  | 'searchColors'
  | 'searchLoading'
> & {
  calculator?: ReactNode;
  colorPicker?: ReactNode;
  alert?: ReactNode;
  info?: ReactNode;
  specialDecoAlert?: ReactNode;
  showProductActions?: boolean;
};

const ProductPanel = ({
  activeProductOption,
  calculatorResult,
  colorOptions,
  colorType,
  colors,
  enableCalculator,
  enableColorPicker,
  favoriteColors,
  hasArticles,
  hasError,
  hasSearched,
  infoMessage,
  isLoading,
  isSpecialDecoTechnique,
  onCalculateSurface,
  onChangeGroupClick,
  onCloseAlert,
  onCloseInfo,
  onColorClick,
  onProductOptionClick,
  onSearch,
  productOptions,
  recentColors,
  savedStore,
  searchColors,
  searchLoading,
  searchValue,
  selectedColor,
  setSearchValue,
  showProductActions = true,
  stock,
  table,
  translations,
  variant = 'primary',
  noArticlesAvailable,
  ...props
}: ProductPanelProps) => {
  const { locale } = useRouter();

  const colorPicker = (
    <Presence
      id="product-panel-color-picker-presence"
      isLoading={isLoading}
      loader={enableColorPicker && <ColorPickerSkeleton variant={variant} />}
      visible={enableColorPicker}
    >
      {enableColorPicker && colors && (
        <ColorPicker
          activeProductOption={activeProductOption}
          colorOptions={colorOptions}
          colorType={colorType}
          colors={colors}
          favoriteColors={favoriteColors}
          hasSearched={hasSearched}
          onChangeGroupClick={onChangeGroupClick}
          onColorClick={onColorClick}
          onProductOptionClick={onProductOptionClick}
          onSearch={onSearch}
          productOptions={productOptions}
          recentColors={recentColors}
          searchColors={searchColors}
          searchLoading={searchLoading}
          searchValue={searchValue}
          selectedColor={selectedColor}
          setSearchValue={setSearchValue}
          translations={translations.colorPicker}
          variant={variant}
        />
      )}
    </Presence>
  );

  const calculator = (
    <Presence id="product-panel-calculator-presence" visible={!!(enableCalculator && onCalculateSurface)}>
      {enableCalculator && onCalculateSurface && (
        <div className="flex flex-col gap-4">
          <span className="font-bold">{translations.amountOfPaintNecessary}</span>
          <div className="flex gap-2">
            <Button
              className="bg-blue [&>*]:small flex items-center gap-2 text-white"
              label={translations.paintCalculator.calculateAmount}
              onClick={onCalculateSurface}
              type="primary"
            />
            {!!calculatorResult.value && (
              <div className="flex items-center gap-2">
                <span>=</span>
                <Tooltip
                  content={
                    <span className="min-w-105 caption">{translations.paintCalculator.calculateSurfaceDisclaimer}</span>
                  }
                >
                  <span className="text-paint-result">
                    <span className="h4">{calculatorResult.value}</span>
                    {` `}
                    {calculatorResult.unit}
                  </span>
                </Tooltip>
              </div>
            )}
          </div>

          {!!table && <Divider className="hidden md:block" />}
        </div>
      )}
    </Presence>
  );

  const alert = (
    <Presence id="product-panel-error-message-presence" visible={hasError}>
      <Alert iconSize="xl" onClose={onCloseAlert} title={translations.errorTitle} type="error">
        {translations.errorSubtitle}
      </Alert>
    </Presence>
  );

  const specialDecoAlert = (
    <Presence id="product-panel-deco-warning-message-presence" visible={isSpecialDecoTechnique}>
      <Alert iconSize="xl" title={translations.specialDecoTitle} type="info">
        <Link href={eventPageSlugsB2B[locale]}>
          {translations.specialDecoSubTitle}
          {translations.specialDecoSubTitleLink}
        </Link>
      </Alert>
    </Presence>
  );

  const info = (
    <Presence id="product-panel-warning-message-presence" visible={!!infoMessage}>
      <Alert iconSize="xl" onClose={onCloseInfo} title={infoMessage?.title} type="info">
        {infoMessage?.message}
      </Alert>
    </Presence>
  );

  const panelProps = {
    calculator,
    colorPicker,
    translations,
    isLoading,
    alert,
    specialDecoAlert,
    info,
    table,
    stock,
    savedStore,
    hasArticles,
    noArticlesAvailable,
    ...props,
  };

  if (variant === 'primary') {
    return <PrimaryProductPanel {...panelProps} />;
  }

  if (variant === 'secondary') {
    return <SecondaryProductPanel showProductActions={showProductActions} {...panelProps} />;
  }

  return null;
};

export default ProductPanel;
