import { faArrowRight, faBoxesPacking, faMessageExclamation, faTruckFast } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Link from '../Link';

import { Props } from './';

type InfoLabelProps = {
  label: string;
  value?: string | ReactNode;
  className?: string;
};

type WarningProps = {
  warning: string;
  translations: {
    warning: string;
    readMore: string;
  };
  link?: string;
  className: string;
};

export const InfoLabel = ({ label, value, className }: InfoLabelProps) => (
  <p className={twMerge('text-xs md:text-sm [&_*]:text-xs [&_*]:md:text-sm', className)}>
    <span className="font-semibold">{label}</span>
    {value !== null && value !== undefined ? `: ${value}` : ''}
  </p>
);

export const Overflow = ({
  amount,
  className,
  prefix = '',
  suffix = '',
}: {
  amount: number;
  className: string;
  prefix?: string;
  suffix?: string;
}) => {
  if (amount < 1) {
    return null;
  }

  return (
    <p className={className}>
      {prefix}
      {amount}
      {suffix}
    </p>
  );
};

export const StatusIcon = ({ status, className }: { className?: string; status: Props['deliveryStatus'] }) => (
  <div
    className={twMerge(
      'flex h-12 min-h-12 w-12 min-w-12 items-center justify-center rounded-full bg-white shadow-lg',
      className,
    )}
  >
    <FontAwesomeIcon className="text-generic-blue-dark" icon={status === 'onTheMove' ? faTruckFast : faBoxesPacking} />
  </div>
);

//deprecated: use Alert component instead
export const Warning = ({ warning, link, className, translations }: WarningProps) => (
  <div className={twMerge('rounded-2 bg-generic-yellow w-full p-4', className)}>
    <div className="text-generic-brown-dark mb-1 [&>*]:font-semibold">
      <FontAwesomeIcon className="mr-2" icon={faMessageExclamation} />
      <span>{translations.warning}</span>
    </div>
    <p className="text-generic-brown-dark">
      <span className="mr-4 inline-block">{warning}</span>
      <Link className="text-blue inline-flex items-center underline" href={link}>
        {translations.readMore}
        <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
      </Link>
    </p>
  </div>
);
