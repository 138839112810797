import { twMerge } from 'tailwind-merge';

import { Note, Toggle } from '../';

type Props = {
  className?: string;
  onClick: () => void;
  active: boolean;
  translations: {
    message: string;
  };
};

const PriceNote = ({ className, onClick, active, translations }: Props) => (
  <Note className={twMerge('flex items-center gap-2.5', className)}>
    <Toggle active={active} onClick={onClick} />
    <span className="text-blue">{translations.message}</span>
  </Note>
);

export default PriceNote;
