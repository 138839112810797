import { cva } from 'class-variance-authority';
import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import { ButtonProps } from '@boss/types/b2b-b2c';
import { Nullable, PromotionCtaVariantb2b, PromotionCtaVariantb2c, SimpleImage } from '@boss/types/corporate-jobs';

import Button from '../Button';
import Image from '../Image';

/**
 * Represents the properties of the JobsPromotionBannerProps
 * @property {ReactNode} children - The content to be displayed inside the banner
 * @property {ButtonProps} cta - The button or link that needs to be shown
 * @property {string} className - The overwrite className for the container
 * @property {boolean} isInSplitSection - Boolean to indicate if cta is in split section
 * @property {ImageProps} image - The image that should be shown in the banner
 * @property {'bottom' | 'right' | 'full-width-background' } buttonPosition - The position of the button, when there is no image, a button can be shown on the right
 * @property {'b2c' | 'b2b'} variant - The variant of the banner
 * @property {'beige' | 'blue' | 'green'} colorVariant - The color variant. B2B can only have green, B2C can have beige & blue
 */
export type JobsPromotionBannerProps = {
  cta: ButtonProps;
  children: ReactNode;
  className?: string;
  isInSplitSection?: boolean;
  buttonPosition?: 'bottom' | 'right';
} & {
  image?: Nullable<SimpleImage>;
  imagePosition?: 'left' | 'right' | 'full-width-background';
} & { variant: PromotionCtaVariantb2c | PromotionCtaVariantb2b; colorVariant: 'beige' | 'blue' | 'green' };

/**
 * Styling of the Banner wrapping container
 * @property {'b2b' | 'b2c'} variant - Variant styling of the banner
 * @property {'beige' | 'blue' | 'green'} colorVariant - Color variant of the banner
 */
const BannerStyles = cva(
  'relative overflow-hidden grid w-full justify-center grid-cols-3 md:grid-cols-7 gap-4 px-8 pt-8',
  {
    variants: {
      variant: {
        b2b: '',
        b2c: 'rounded-r-[2rem] rounded-b-[2rem]',
      },
      colorVariant: {
        beige: 'bg-beige',
        blue: 'bg-blue-flat',
        green: 'bg-green-sheen text-white',
      },
    },
  },
);

/**
 * Styling of the Content container
 * @property {'bottom' | 'right'} buttonPosition - Style variant for the position of the button
 * @property {boolean} hasImage - Boolean to indicate an image is given
 * @property {boolean} hasButton - Boolean to indicate a button is given
 * @property {boolean} isInSplitSection - Boolean to indicate the banner is in a split section
 */
const ContentStyles = cva('pb-2 md:pb-8 gap-4 z-10', {
  variants: {
    buttonPosition: {
      bottom: 'flex flex-col',
      right: 'flex items-end',
    },
    hasImage: {
      true: '',
      false: 'col-span-7 grid',
    },
    hasButton: {
      true: '',
    },
    isInSplitSection: {
      true: '',
    },
  },
  compoundVariants: [
    {
      hasImage: false,
      hasButton: false,
      class: 'grid-cols-4',
    },
    {
      hasImage: false,
      hasButton: true,
      buttonPosition: 'right',
      class: 'md:grid-cols-6',
    },
    {
      isInSplitSection: true,
      hasImage: true,
      class: 'col-span-4 lg:col-span-5',
    },
    {
      isInSplitSection: false,
      hasImage: true,
      class: 'col-span-4',
    },
    {
      isInSplitSection: false,
      hasImage: true,
      class: 'col-span-5',
    },
  ],
});

/**
 * Styling of the Image section
 * @property {'left' | 'right'} position - position variation of the image
 * @property {boolean} isInSplitSection - Boolean to indicate the banner is in a split section
 */
const ImageStyles = cva('col-span-3 flex items-end max-h-75 md:max-h-60', {
  variants: {
    position: {
      left: '',
      right: '',
    },
    isInSplitSection: {
      true: 'md:col-span-3 lg:col-span-2',
      false: 'md:col-span-2',
    },
  },
});

/**
 * Styling of the Anchor tag inside the button
 * @property {'right' | 'bottom'} buttonPosition - position variation of the button
 */
const AnchorStyles = cva('', {
  variants: {
    buttonPosition: {
      right: 'md:col-span-2 flex justify-end',
      bottom: 'w-fit',
    },
  },
});

/**
 * Styling of the Text
 * @property {'right' | 'left' | 'full-width-image'} imagePosition - position of the image
 */
const TextStyles = cva('col-span-4 flex flex-col gap-4', {
  variants: {
    imagePosition: {
      right: '',
      left: '',
      'full-width-background': 'text-white',
    },
  },
});

const JobsPromotionBanner: React.FC<JobsPromotionBannerProps> = Props => {
  const {
    cta,
    buttonPosition = 'bottom',
    children,
    className,
    variant,
    colorVariant /*= variant === 'b2c' ? 'beige' : 'green'*/,
    isInSplitSection = false,
  } = Props;

  /**
   * Needs separate checking, since the prop is not always given. Direct destructuring would not work
   * @type {boolean}
   */
  const hasImagePosition = 'imagePosition' in Props;
  const imagePosition = hasImagePosition ? Props.imagePosition : 'right';
  const hasImage = 'image' in Props && Props.imagePosition !== 'full-width-background';
  const hasFullWidthImage = 'image' in Props && Props.imagePosition === 'full-width-background';

  return (
    <div className={twMerge(BannerStyles({ variant, colorVariant }), className)}>
      {hasFullWidthImage && (
        <div className="absolute inset-x-0 inset-y-0">
          <Image alt={Props.image?.imageAlt ?? ''} src={Props.image?.imageUrl} />
        </div>
      )}
      {imagePosition === 'left' && hasImage && (
        <div className={ImageStyles({ position: 'left', isInSplitSection })}>
          <Image
            alt={Props.image?.imageAlt ?? ''}
            className="object-contain"
            src={Props.image?.imageUrl}
            wrapperClassName="h-full max-h-full"
          />
        </div>
      )}
      <div className={ContentStyles({ buttonPosition, hasImage: hasImage, hasButton: !!cta, isInSplitSection })}>
        <div className={TextStyles({ imagePosition })}>{children}</div>
        {cta && <Button {...cta} anchorClassName={AnchorStyles({ buttonPosition })} />}
      </div>
      {imagePosition === 'right' && hasImage && (
        <div className={ImageStyles({ position: 'right', isInSplitSection })}>
          <Image
            alt={Props.image?.imageAlt ?? ''}
            className="object-contain"
            src={Props.image?.imageUrl}
            wrapperClassName="h-full max-h-full"
          />
        </div>
      )}
    </div>
  );
};

export default JobsPromotionBanner;
