import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';

import Link from '../Link';
import Popper from '../Popper';

type Props = {
  className?: string;
  localizedSlugs: Record<string, string>;
  placement?: 'top' | 'bottom';
};

const LocaleSelector = ({ className, localizedSlugs, placement = 'bottom' }: Props) => {
  const { locale, locales } = useRouter();

  const [isOpen, setIsOpen] = useState(false);

  const getLanguage = (locale: string) => locale.split('-')[0];
  const getCountry = (locale: string) => locale.split('-')[1];

  const filteredLocales = locales.filter(
    l => getCountry(l) === getCountry(locale) && getLanguage(l) !== getLanguage(locale),
  );

  if (!locale || filteredLocales?.length < 1) {
    return null;
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpen) {
      return;
    }

    const menuItems = document.querySelectorAll('[role="menuitem"]');

    if (!menuItems?.length) {
      return;
    }

    const currentIndex = Array.from(menuItems).findIndex(item => item === document.activeElement);

    switch (e.key) {
      case 'ArrowDown':
      case 'ArrowRight':
        e.preventDefault();
        if (currentIndex < menuItems.length - 1) {
          (menuItems[currentIndex + 1] as HTMLElement).focus();
        } else {
          (menuItems[0] as HTMLElement).focus();
        }
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        e.preventDefault();
        if (currentIndex > 0) {
          (menuItems[currentIndex - 1] as HTMLElement).focus();
        } else {
          (menuItems[menuItems.length - 1] as HTMLElement).focus();
        }
        break;
      case 'Escape':
        setIsOpen(false);
        break;
    }
  };

  return (
    <Popper
      button={
        <button
          aria-expanded={isOpen}
          aria-haspopup="true"
          aria-label="locale-selector"
          className="inline-flex w-full items-center gap-x-2.5 whitespace-nowrap"
          onClick={() => setIsOpen(!isOpen)}
          onKeyDown={handleKeyDown}
        >
          <span className="small uppercase">{locale && getLanguage(locale)}</span>
          <FontAwesomeIcon icon={faAngleDown} />
        </button>
      }
      className={twMerge(className)}
      floating={
        <div className={twMerge('z-modal bg-white shadow-lg')} onKeyDown={handleKeyDown} role="menu">
          {filteredLocales.map(locale => (
            <Link
              aria-label={`locale-selector-option-${locale}`}
              className="focus:ring-primary inline-block w-full px-4 py-2 text-sm uppercase text-gray-700 transition duration-100 ease-in hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset"
              href={localizedSlugs?.[locale] || '/'}
              key={locale}
              locale={locale}
              onClick={() => setIsOpen(false)}
              role="menuitem"
              tabIndex={0}
            >
              {getLanguage(locale)}
            </Link>
          ))}
        </div>
      }
      isOpen={isOpen}
      placement={placement}
    />
  );
};

export default LocaleSelector;
