import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { useRouter } from '@boss/hooks';
import { Cta } from '@boss/types/b2b-b2c';
import { getLink } from '@boss/utils';

import MobileMenu from './MobileMenu';
import {
  Backdrop,
  Button,
  Container,
  Cta as CtaComponent,
  LocaleSelector,
  Logo,
  MainNavInstanceProps,
  Portal,
  SearchBar,
  SubNav,
} from '../..';
import { SecondaryLink, ShoppingBasket, UniqueLink } from '../MenuLinks';

const MainNavSecondary = ({
  className,
  closeMenu,
  favorite,
  handleMobileMenuClick,
  highlightedCta,
  isOpen,
  localizedSlugs,
  logo,
  extraNav,
  mainNav,
  onSearch,
  personal,
  searchLabel,
  searchValue,
  secondaryNavItems,
  setSearchValue,
  translations,
  basket,
  type = 'default',
  searchResults,
  onCloseSearchMenu,
  suggestions,
  showSearchSuggestions,
  onShowSearchSuggestions,
  barcodeScanner,
}: MainNavInstanceProps) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const handleOpenMenu = (hoverState: boolean) => setMenuIsOpen(hoverState);
  const { locale } = useRouter();

  if (type === 'simple') {
    return (
      <nav className={twMerge('shadow-m md:bg-gray-lightest relative bg-white', className)}>
        <Container className="py-4 md:py-6">
          {logo && <Logo alt={logo.imageAlt} className="h-8 md:h-12" href={logo.href} src={logo.imageUrl} />}
        </Container>
      </nav>
    );
  }

  const getUrl = (navItem: Cta) => {
    if (navItem.internalLink) {
      return getLink(navItem.internalLink, locale);
    }

    if (navItem.externalLink) {
      return navItem.externalLink;
    }
  };

  return (
    <nav className={twMerge('shadow-m relative bg-white', className)}>
      {isOpen && (
        // FIXME
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <Portal>
          <MobileMenu
            className="z-nav fixed left-0 top-0 h-full w-full md:hidden"
            closeMenu={closeMenu}
            extraNavigation={extraNav?.map((link, index) => (
              <CtaComponent
                className="text-brown text-base font-bold"
                key={index}
                {...link}
                href={getUrl(link)}
                icon={undefined}
                type="link"
              />
            ))}
            highlightedLinks={highlightedCta ? [highlightedCta] : null}
            links={mainNav}
            localizedSlugs={localizedSlugs}
            translations={translations}
          />
        </Portal>
      )}

      {extraNav?.length && (
        <div className="md:bg-gray-lightest">
          <Container className=" hidden justify-end gap-4 pb-2 pt-2 md:flex">
            {extraNav?.map((link, index) => (
              <CtaComponent key={index} {...link} href={getUrl(link)} icon={undefined} type="link" />
            ))}
          </Container>
        </div>
      )}
      <div className="md:bg-gray-lightest">
        <Container
          className={`flex flex-wrap items-center py-4 md:flex-nowrap ${
            extraNav?.length ? 'md:px-6 md:pb-6 md:pt-2' : 'md:py-6'
          }`}
        >
          <button aria-label="hamburger-button" className="mr-2.5 md:hidden" onClick={handleMobileMenuClick}>
            <FontAwesomeIcon icon={faBars} size="xl" />
          </button>
          {logo && (
            <Logo
              alt={logo.imageAlt}
              className="order-1 mr-4 flex h-8 md:h-12 lg:mr-20"
              href={logo.href}
              src={logo.imageUrl}
            />
          )}
          <div className="relative order-3 mt-4 flex w-full flex-row items-center gap-3 md:order-2 md:mt-0">
            {barcodeScanner && <div className="md:hidden">{barcodeScanner}</div>}
            <SearchBar
              className="md:max-w-105 md:mr-10"
              onCloseSearchMenu={onCloseSearchMenu}
              onSearch={onSearch}
              onShowSearchSuggestions={onShowSearchSuggestions}
              searchLabel={searchLabel}
              searchResults={searchResults}
              setSearchValue={setSearchValue}
              showSearchSuggestions={showSearchSuggestions}
              suggestions={suggestions}
              type="secondary"
              value={searchValue}
              wrapperClassname="order-last md:order-none"
            />
          </div>
          <div className="ml-4 flex grow items-center justify-end gap-4 md:order-3 md:w-full xl:ml-auto">
            <div className="flex items-center gap-4">
              {highlightedCta && (
                <Button
                  className="bg-red-light hover:bg-red-light font-raleway mr-4 hidden text-lg font-semibold hover:text-white md:flex"
                  href={highlightedCta.href}
                  icon={highlightedCta.icon}
                  iconPosition="left"
                  label={highlightedCta.label}
                  type="primary"
                />
              )}
              {highlightedCta && (
                <Button
                  className="text-red-light font-raleway md:hidden"
                  href={highlightedCta.href}
                  icon={highlightedCta.icon}
                  iconPosition="left"
                  label={''}
                  type="link"
                />
              )}

              {favorite?.onClick && (
                <UniqueLink
                  ariaLabel="favorite-link"
                  icon={favorite.icon}
                  iconClass="text-base gap-2.5"
                  label={favorite.label}
                  labelClass="hidden lg:inline"
                  onClick={favorite.onClick}
                  slug={favorite.href}
                />
              )}

              {secondaryNavItems?.map(
                (link, index) => link.href && <SecondaryLink {...link} key={`${link.id}-${index}`} slug={link.href} />,
              )}
            </div>

            <div className="flex items-center gap-4 md:ml-auto">
              {basket.onClick && <ShoppingBasket current={basket.amount} onClick={basket.onClick} type="secondary" />}
              {personal && (
                <UniqueLink
                  ariaLabel="account-page-link"
                  className="flex gap-2"
                  icon={personal.icon}
                  iconClass="text-base"
                  onClick={personal.onClick}
                  slug={personal.href}
                />
              )}
            </div>
            <LocaleSelector className="hidden md:block" localizedSlugs={localizedSlugs} />
          </div>
        </Container>
      </div>
      {mainNav && (
        <Container className="relative mt-6 hidden content-between items-end md:flex">
          <SubNav nav={mainNav} onCloseMenu={() => setMenuIsOpen(false)} onOpenMenu={handleOpenMenu} type="secondary" />
        </Container>
      )}

      <Backdrop onClick={() => setMenuIsOpen(false)} visible={menuIsOpen} />
    </nav>
  );
};

export default MainNavSecondary;
