import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import Skeleton from '../../Skeleton';

export interface Props {
  price: string;
  totalText: string;
  transportText?: string;
  className?: string;
  textSmall?: boolean;
  priceColor?: 'red' | undefined;
  boldLabel?: boolean;
  boldPrice?: boolean;
  bold?: boolean;
  italicLabel?: boolean;
  hasBorder?: boolean;
  borderColor?: 'blue' | 'gray';
  hasWhiteBackground?: boolean;
  isPriceLoading?: boolean;
  infoText?: string | null;
}

const PriceStyles = cva('', {
  variants: {
    priceColor: {
      red: 'text-red-light',
    },
  },
});

const LineStyle = cva('', {
  variants: {
    borderColor: {
      blue: 'border-blue',
      gray: 'border-gray-100',
    },
    hasBorder: {
      true: 'border-t-1 mt-6',
    },
    hasWhiteBackground: {
      true: 'bg-white mt-6 py-3 px-3',
    },
    isBold: {
      true: 'font-bold',
    },
  },
});

const TextStyle = cva('', {
  variants: {
    textSmall: {
      true: 'small',
      false: 'font-bold',
    },
    bold: {
      true: 'font-bold',
    },
    italicLabel: {
      true: 'italic',
    },
  },
});

const ShoppingCartTotalPrice = ({
  price,
  totalText,
  transportText,
  className,
  textSmall,
  priceColor,
  boldLabel,
  boldPrice,
  bold,
  italicLabel,
  hasBorder,
  borderColor = 'gray',
  hasWhiteBackground,
  isPriceLoading = false,
  infoText,
}: Props) => {
  const paddingStyle = 'pt-6';

  return (
    <div
      className={twMerge(
        paddingStyle,
        TextStyle({ textSmall }),
        LineStyle({ hasBorder, hasWhiteBackground, borderColor }),
        className,
      )}
    >
      <div className="flex justify-between">
        <p className={TextStyle({ textSmall, bold: boldLabel ?? bold, italicLabel })}>{totalText}</p>
        {isPriceLoading ? (
          <Skeleton className="rounded-brand h-5 min-w-20" skeletonClassName="bg-gray-200" />
        ) : (
          <p
            className={twMerge(
              'min-w-20 text-right',
              TextStyle({ textSmall, bold: boldPrice ?? bold }),
              PriceStyles({ priceColor }),
            )}
          >
            {price}
          </p>
        )}
      </div>

      {infoText && <p className="small">{infoText}</p>}
      {transportText && <p className="small text-right">{transportText}</p>}
    </div>
  );
};

export default ShoppingCartTotalPrice;
