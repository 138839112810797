import * as bossClient from '@boss/boss-client';
import { DeepPartial } from '@boss/types/b2b-b2c';

import { IBasket, IBasketDeliveryAddress, IBasketLine, IBasketPatchLine, IBasketUpdateB2C } from './types';

export const getBasket = async ({
  accountId,
  locale,
}: {
  accountId?: string;
  locale: string;
}): Promise<IBasket | undefined | null> => await bossClient.basketClient.getBasket({ accountId, locale });
export const getBasketById = async ({
  basketId,
  locale,
  checkProducts,
}: {
  basketId: string;
  locale: string;
  checkProducts?: boolean;
}): Promise<IBasket | undefined | null> => await bossClient.basketClient.getBasketById(basketId, locale, checkProducts);

export const addBasketLines = async (
  basketId: string,
  basketLines: DeepPartial<IBasketLine>[],
  locale: string,
): Promise<IBasket | undefined | null> => await bossClient.basketClient.addBasketLines(basketId, basketLines, locale);

export const deleteBasketLine = async ({
  basketId,
  basketLineId,
  locale,
}: {
  basketId: string;
  basketLineId: string;
  locale: string;
}): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.deleteBasketLine({ basketId, basketLineId, locale });

export const updateBasketLine = async (
  basketId: string,
  basketLine: DeepPartial<IBasketLine>,
  locale: string,
): Promise<IBasket | undefined | null> =>
  await bossClient.basketClient.updateBasketLine({ basketId, basketLine, locale });

export const updateBasketB2B = async (
  basketId: string,
  basket: DeepPartial<IBasket>,
  locale: string,
): Promise<IBasket | undefined | null> => await bossClient.basketClient.updateBasket({ basketId, basket, locale });

const mapAddress = (
  address?: DeepPartial<{
    street: string;
    streetnumber: string;
    postbox: string;
    zipcode: string;
    city: string;
    country: string;
  }>,
) =>
  address && {
    street: address.street,
    streetnumber: address.streetnumber,
    postbox: address.postbox,
    zipcode: address.zipcode,
    city: address.city,
    country: address.country,
  };

export const updateBasketB2C = async (
  basketId: string,
  basket: DeepPartial<IBasketUpdateB2C>,
  locale: string,
): Promise<IBasket | undefined | null> => {
  return await bossClient.basketClient.updateBasket({
    basketId,
    basket: {
      accountinfo: basket.accountInfo && {
        firstname: basket.accountInfo.firstName,
        lastname: basket.accountInfo.lastName,
        name: basket.accountInfo.name,
        vatnumber: basket.accountInfo.vatNumber,
      },
      contactinfo: basket.contactInfo && {
        secondaryphonenumber: basket.contactInfo.secondaryPhoneNumber,
        phonenumber: basket.contactInfo.phoneNumber,
        email: basket.contactInfo.email,
        language: basket.contactInfo.language,
        preference: basket.contactInfo.preference && {
          onlinecommunication: basket.contactInfo.preference.onlineCommunication,
        },
      },
      delivery: basket.delivery && {
        deliverymethod: basket.delivery.deliverymethod,
        storeid: basket.delivery.storeId,
        address: mapAddress(basket.delivery.address),
      },
      invoice: basket.invoice && {
        address: mapAddress(basket.invoice.address),
      },
      payment: basket.payment && {
        pending: basket.payment.pending,
        payreference: basket.payment.payReference,
      },
    },
    locale,
  });
};

export const patchBasket = async (
  basketId: string,
  patchLines: IBasketPatchLine[],
  locale: string,
): Promise<IBasket | undefined | null> => await bossClient.basketClient.patchBasket({ basketId, patchLines, locale });

export const getDeliveryDates = async ({
  storeId,
  modeOfDelivery,
  address,
  locale,
}: {
  storeId?: string;
  modeOfDelivery: number;
  address?: IBasketDeliveryAddress;
  locale: string;
}): Promise<{ dates: string[]; deliverymode: string } | undefined | null> => {
  const data = await bossClient.basketClient.getDeliveryDates({ storeId, modeOfDelivery, address, locale });

  return data ?? undefined;
};

export const addVoucherToBasket = async (
  basketId: string,
  voucher: { type: string; reference: string; webcode?: string },
  locale: string,
) => await bossClient.basketClient.addVoucherToBasket({ basketId, voucher, locale });

export const deleteVoucherFromBasket = async (basketId: string, voucherId: string, locale: string) =>
  await bossClient.basketClient.deleteVoucherFromBasket({ basketId, voucherId, locale });
