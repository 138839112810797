// Response is a global type from the Fetch API

type FetchResponse = globalThis.Response;

import getErrorFromResponse from './get-error-from-response';

/**
 * Custom error class for API errors.
 * @class
 * @extends Error
 */
export default class ApiException extends Error {
  status: number;
  url: string;
  conflictData?: unknown;

  /**
   * Create a new API error.
   * @constructor
   * @param {string} message - The error message.
   * @param {number} status - The HTTP status code.
   * @param {string} url - The URL of the request.
   * @param {any} [conflictData] - The conflict data.
   */
  constructor(message: string, status: number, url: string, conflictData?: unknown) {
    super(message);
    this.name = 'ApiException';
    this.status = status;
    this.url = url;
    this.conflictData = conflictData;
  }

  /**
   * Create an ApiException from a fetch error.
   * @param {Error} error - The fetch error.
   * @returns {ApiException} - The ApiException instance.
   */
  static async fromResponse(response: FetchResponse): Promise<ApiException> {
    throw new ApiException(await getErrorFromResponse(response), response.status, response.url);
  }
}
