import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import FormFieldWrapper, { BaseFieldProps } from '../FormFieldWrapper';
import { InputFieldStyles } from '../InputField';

type Option = {
  value: string;
  label: string;
};

type Props = BaseFieldProps & {
  light?: boolean;
  options: Option[];
  onChange: (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => void;
  selectClassName?: string;
  iconClassName?: string;
  value?: string;
  placeholder?: string | null;
  otherPlaceholder?: string;
  labelClassName?: string;
};

const Select = ({
  touched,
  disclaimer,
  error,
  label,
  value,
  name,
  options,
  onChange,
  className,
  selectClassName,
  iconClassName,
  required,
  tooltip,
  id,
  placeholder,
  variant = 'light',
  otherPlaceholder,
  labelClassName,
}: Props) => {
  const [customValue, setCustomValue] = useState('');
  const isOtherSelected = value === 'other';

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    onChange(event);
    if (event.target.value !== 'other' && isOtherSelected) {
      setCustomValue(''); // Reset the custom input if "Other" is not selected
      onChange({
        ...event,
        target: {
          ...event.target,
          name: `${name}-custom`,
          value: '',
        },
      });
    }
  };

  const handleCustomChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCustomValue(event.target.value);
    onChange(event);
  };

  return (
    <FormFieldWrapper
      className={className}
      disclaimer={disclaimer}
      error={error}
      id={id}
      label={label}
      labelClassName={labelClassName}
      name={name}
      required={required}
      tooltip={tooltip}
      touched={touched}
    >
      <div className="relative">
        <select
          aria-label={`select-${value}`}
          className={twMerge(
            'min-h-10 appearance-none bg-none',
            InputFieldStyles({
              variant,
              error: !!error,
            }),
            'pr-10',
            selectClassName,
          )}
          id={id ?? name}
          name={name}
          onChange={handleSelectChange}
          value={isOtherSelected ? 'other' : value ?? ''}
        >
          {!value && (
            <option disabled value="">
              {placeholder ?? 'Select'}
            </option>
          )}
          {options.map(option => (
            <option aria-label={`select-option-${option.value}`} key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <FontAwesomeIcon
          className={twMerge('pointer-events-none absolute right-4 top-1/2 -translate-y-1/2', iconClassName)}
          icon={faChevronDown}
        />
      </div>
      {isOtherSelected && (
        <input
          aria-label="custom-input"
          className={twMerge(
            'mt-2 min-h-10 w-full rounded-md border px-3 py-2',
            InputFieldStyles({
              variant,
              error: !!error,
            }),
          )}
          id={`${id ?? name}-custom`}
          name={`${name}-custom`}
          onChange={handleCustomChange}
          placeholder={otherPlaceholder}
          type="text"
          value={customValue}
        />
      )}
    </FormFieldWrapper>
  );
};

export default Select;
