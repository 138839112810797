import { Image, Link } from '../';
import DesktopNavigationItem from './components/DesktopNavigationItem';
import FooterNavItem from './components/FooterNavItem';
import FooterWrapper from './components/FooterWrapper';
import MobileNavigationItem from './components/MobileNavigationItem';
import SocialLinks from './components/SocialLinks';

import { FooterProps } from './index';

const JobsFooter = ({
  navigationItems,
  legalLinks,
  locale,
  logo,
  referralLink,
  brandReferralLink,
  socialLinks,
  socialsLogo,
  translations,
}: FooterProps) => (
  <FooterWrapper className="bg-footer-primary break-before before:bg-footer-primary break-after after:bg-footer-primary relative text-white">
    <nav className="flex w-full flex-col pb-8 pt-8 md:flex-row md:pb-0">
      <div className="mb-4 flex-none md:w-1/4">
        {logo && (
          <Link href="/">
            <Image alt={logo?.imageAlt ?? 'Logo'} className="h-auto w-40 object-contain" src={logo?.imageUrl} />
          </Link>
        )}
      </div>
      <div className="flex w-full flex-none flex-col justify-between md:w-1/2 md:flex-row">
        {navigationItems?.map((navItem, i) => (
          <ul className="md:mb-O mb-6 flex-none md:w-1/2" key={`${navItem.id}-${i}`}>
            <DesktopNavigationItem itemClassName="pb-0" mobileText={true} navItem={navItem} />
            <MobileNavigationItem isCollapsible={false} linkClassName="pb-0" mobileText={true} navItem={navItem} />
          </ul>
        ))}
      </div>
      {(socialsLogo || socialLinks) && (
        <div className="flex-none text-white md:w-1/4 ">
          {socialsLogo && (
            <Image
              alt={socialsLogo?.imageAlt ?? ''}
              className="mb-6 h-auto w-auto object-contain"
              src={socialsLogo?.imageUrl}
              wrapperClassName="h-auto w-auto"
            />
          )}
          {socialLinks && <SocialLinks className="gap-4 md:mb-2" links={socialLinks} locale={locale} />}
        </div>
      )}
    </nav>
    <div className="border-t-1 g-x-4 flex flex-col justify-between gap-y-3 border-solid border-white border-white/30 py-4 text-white md:flex-row">
      <span className="flex flex-col gap-3 py-4 md:flex-row md:gap-6 lg:gap-12">
        <span className="flex flex-col md:flex-col md:gap-0 xl:flex-row xl:gap-4">
          <span>{translations?.brandReferral}</span>
          <span className="inline">
            <FooterNavItem className="cursor-pointer underline" link={brandReferralLink}>
              {translations?.brandReferralLinkText}
            </FooterNavItem>
          </span>
        </span>
        <span className="flex flex-col  md:flex-col md:gap-0 xl:flex-row xl:gap-4">
          <span>{translations?.referral}</span>
          <span className="inline">
            <FooterNavItem className="cursor-pointer underline" link={referralLink}>
              {translations?.referralLinkText}
            </FooterNavItem>
          </span>
        </span>
      </span>
      <ul className="border-t-1 flex flex-col gap-y-3 border-solid border-white/30 py-4 md:flex-row md:items-center md:gap-x-3 md:border-0 md:py-0">
        {legalLinks?.map((link, index) => (
          <li className="inline" key={`${link.id}-${index}`}>
            <FooterNavItem className="cursor-pointer opacity-50 hover:opacity-100" link={link.href}>
              {link.label}
            </FooterNavItem>
          </li>
        ))}
        <FooterNavItem className="ml-0 opacity-50 md:ml-2.5">
          {translations?.brand} &nbsp;
          {new Date().getFullYear()}
        </FooterNavItem>
      </ul>
    </div>
  </FooterWrapper>
);

export default JobsFooter;
