import { cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';

import { Nullable } from '@boss/types/b2b-b2c';

import type { Props as ColorfulButtonProps } from '../ColorfulButton';
import ColorfulButton from '../ColorfulButton';
import type { Props as PromotionCtaProps } from '../PromotionCta';
import PromotionCta from '../PromotionCta';

type Props = {
  promotionCta: PromotionCtaProps;
  secondaryPromotionCta: PromotionCtaProps;
  links: ColorfulButtonProps[];
  className?: Nullable<string>;
  reverseLayout?: boolean;
};

const promotionBannerVariants = cva('grid grid-cols-1 xl:grid-cols-2', {
  variants: {
    reverseLayout: {
      true: 'xl:pt-6',
      false: '',
    },
  },
  defaultVariants: {
    reverseLayout: false,
  },
});

const primaryCtaVariants = cva('min-h-100 mb-6 xl:mb-0 xl:mr-6', {
  variants: {
    reverseLayout: {
      true: 'order-2 mb-0 mt-6 xl:order-2 xl:mr-0 xl:ml-6 xl:mt-0',
      false: 'order-1',
    },
  },
  defaultVariants: {
    reverseLayout: false,
  },
});

const secondaryCtaContainerVariants = cva('grid grid-cols-2 xl:grid-cols-1', {
  variants: {
    reverseLayout: {
      true: 'order-1 xl:order-1',
      false: 'order-2',
    },
  },
  defaultVariants: {
    reverseLayout: false,
  },
});

const PromotionBanner = ({ promotionCta, secondaryPromotionCta, links, className, reverseLayout = false }: Props) => {
  return (
    <div className={twMerge(promotionBannerVariants({ reverseLayout }), className)}>
      <div className={primaryCtaVariants({ reverseLayout })}>
        <PromotionCta {...promotionCta} />
      </div>
      <div className={secondaryCtaContainerVariants({ reverseLayout })}>
        <div className="xl:min-h-54 mr-6 xl:mb-6 xl:mr-0">
          <PromotionCta {...secondaryPromotionCta} variant="secondary" />
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-3">
          {links?.map((props, index) => (
            <div
              className={`h-26 inline xl:hidden xl:h-auto ${index !== links.length - 1 && 'mb-3'}`}
              key={`${props.label}-${index}`}
            >
              <ColorfulButton {...props} variant="secondary" />
            </div>
          ))}
          {links?.map((props, index) => (
            <div className="h-26 mb-3 hidden xl:inline xl:h-auto" key={`${props.label}-${index}`}>
              <div
                className={`flex ${index === 1 ? 'xl:items-center xl:justify-center' : ''} ${
                  index === 2 ? 'xl:items-right xl:justify-end' : ''
                }`}
              >
                <ColorfulButton {...props} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PromotionBanner;
